import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import TrialBanner from 'core/components/TrialBanner.js';
import TrialExpired from 'core/components/TrialExpired.js';
import DokobitInfoBanner from 'core/components/DokobitInfoBanner.js'
import DokobitModal from 'core/components/DokobitModal.js'
import Cookies from 'universal-cookie'

import Splash from 'core/components/Splash';
import {getDependencies} from 'core/store/actions/app';
import {setTranslationMissing} from 'core/store/actions/app';

import {languageSelector, dependencyRequestsSelector, translationMissingSelector} from 'core/store/selectors/app';
import {getDictionary} from 'core/store/actions/app';
import {user as userActions, session as sessionActions} from 'core/store/actions';
import {useNotification} from '@signicat/designi/components/messages';
import {t} from 'core/helpers/i18n';
import {hotjar} from 'react-hotjar';

const Core = (props) => {
  const {children} = props;
  const dispatch = useDispatch();
  const {isLoaded} = useSelector((state) => state.core);
  const {enqueueNotification, closeNotification} = useNotification();
  const language = useSelector(languageSelector);
  const dependencyRequests = useSelector(dependencyRequestsSelector('request'));
  const translationMissing = useSelector(translationMissingSelector);
  const languageChanging = useSelector((state) => state.user.isChangingLanguage);
  const isOnline = useSelector((state) => state.app.isOnline);
  const location = useLocation();
  const session = useSelector((state) => state.session);

  const trial = useSelector((state) => state.billing.trial);
  const billedPlans = useSelector((state) => state.billing.billedPlans);
  const user = useSelector((state) => state.session?.user);
  const [isHotjarInitted, setIsHotjarInitted] = useState(false);
  const [isDokobitModal, setIsDokobitModal] = useState(false);

  const initHotjar = () => {
    if (trial?.expires && !trial.hasEnded && window.SIGNIAPP_ENV === 'prod' && !isHotjarInitted) {
      hotjar.initialize(2645497, 6);
      setIsHotjarInitted(true);
    }
  };

  const initDokobitModal = () => {
    const cookies = new Cookies();
    const showDokobitModal = cookies.get('showDokobitModal');
    if(showDokobitModal != 'false'){
      setIsDokobitModal(true)
    }
  }


  useEffect(() => {
    if (user?.profile && language && (!user.profile.preferredLanguage || user.profile.preferredLanguage !== language)) {
      const tempProfile = {...user.profile, preferredLanguage: language};
      dispatch(userActions.save({...user, profile: tempProfile}));
      dispatch(sessionActions.updateCurrentUser({...user, profile: tempProfile}));
    }
  }, [language, user]);

  useEffect(() => {
    dispatch(getDependencies());
    window.addEventListener('offline', (e) => {
      dispatch({type: 'SET_ONLINE', isOnline: false});
    });
    window.addEventListener('online', (e) => {
      dispatch({type: 'SET_ONLINE', isOnline: true});
    });
    initHotjar();
    initDokobitModal();
  }, []);

  useEffect(() => {
    initHotjar();
  }, [trial]);

  useEffect(() => {
    if (!isOnline) {
      closeNotification();
      enqueueNotification(t('user_is_offline'), {
        variant: 'outlined',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 9000,
      });
    }
  }, [isOnline]);

  useEffect(() => {
    if (isLoaded) {
      dispatch(getDictionary(language));
    }
  }, [language]);

  useEffect(() => {
    if (translationMissing) {
      //Closing previous notification
      closeNotification();
      enqueueNotification(t('missing_translation_notification'), {
        variant: 'outlined',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 9000,
      });
    }
  }, [translationMissing]);

  useEffect(() => {
    if (languageChanging) {
      closeNotification();
    } else {
      dispatch(setTranslationMissing(false));
    }
  }, [languageChanging]);

  useEffect(() => {
    dispatch(setTranslationMissing(false));
  }, [location]);

  return isLoaded && dependencyRequests.length === 0 ? (
    <Fragment>
      {/* {bars.map((b) => ( */}
      {/*   <TrialBanner key={b.id}> */}
      {/*     {b.message} */}
      {/*   </TrialBanner> */}
      {/* ))} */}
      {user != null && <DokobitInfoBanner></DokobitInfoBanner>}
      {trial?.isTrial && !trial?.hasEnded && (!billedPlans || billedPlans.length == 0) && session.user ? (
        <TrialBanner></TrialBanner>
      ) : null}
      {user !=null && isDokobitModal && 
      <DokobitModal
        close={() => setIsDokobitModal(false)}
      >
      </DokobitModal>
      }
      {trial?.hasEnded && (!billedPlans || billedPlans.length == 0) && !location.pathname.includes('/upgrade') ? (
        <TrialExpired expires={trial.expires} />
      ) : null}
      {children}
    </Fragment>
  ) : (
    <Splash />
  );
};

export default Core;
