import BundledModules from 'core/Modules.js';
import bundledPackageVersions from 'core/package-versions.js';
import init from 'core/init.js';

export {default as Header} from 'core/components/Header.js';
export {default as Main} from 'core/components/Main.js';
export {default as Route} from 'core/components/Route.js';
export {default as Feature} from 'core/components/Feature.js';

export const getMenu = () => {};

if (! window.SIGNIAPP_IS_FRONTEND) {
  init(BundledModules, bundledPackageVersions);
}
