import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import path from 'path';

import {sort} from '@signiapp/helpers';
import {Icon} from '@signicat/designi/components/icons';
import {List, ListItem} from '@signicat/designi/components/lists';
import {Tooltip} from '@signicat/designi/components/overlays';
import {Table, Thead, Tbody, Th, Tr, Td} from '@signicat/designi/components/tables';
import {Flex} from '@signicat/designi/components/layout';

import {t} from 'core/helpers/i18n';
import {app as appSelectors} from 'core/store/selectors';
import {currentUserSelector, accessLevelSelector} from 'core/store/selectors/session';
import {ROUTES} from 'core/constants';
import {CustomPagination} from '@signicat/designi/components/pagination';

const GroupRow = (props) => {
  const history = useHistory();

  const {group} = props;
  const groupMembers = useSelector(appSelectors.groupMembersSelector(group.id));
  const groupAdmins = useSelector(appSelectors.groupAdminsUsersSelector(group.id));

  const handleGroupClick = (id) => (e) => {
    navigateToGroupDetails(id);
  };

  const navigateToGroupDetails = (id) => {
    history.push(path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'groups', id));
  };

  const handleKeyEvent = (id) => (e) => {
    if (e.which === 32 || e.which === 13) {
      e.preventDefault();
      navigateToGroupDetails(id)
    }
  };

  return (
    <Tr
      key={group.id}
      tabIndex="0"
      role="button"
      onClick={handleGroupClick(group.id)}
      onKeyPress={handleKeyEvent(group.id)}
      aria-label={t('uam_group_show_details', {groupName: group.data.displayName})}
    >
      <Td>
        {group.data.displayName || group.id}
      </Td>
      <Td>
        <Tooltip
          anchor={<span tabIndex="0">{groupMembers.length} {t('uam_word_groupmembers')}</span>}
          arrow
        >
          {groupMembers.length
            ? (
              <List>
                {groupMembers.map((groupMember) => (
                  <ListItem key={groupMember.id}>
                    {groupMember.data.profile.name}
                  </ListItem>
                ))}
              </List>
            )
            : t('uam_group_no_members')
          }
        </Tooltip>
      </Td>
      <Td>
        <Tooltip
          anchor={<span tabIndex="0">{groupAdmins.length} {t('uam_word_administrators')}</span>}
          arrow
        >
          {groupAdmins.length
            ? (
              <List>
                {groupAdmins.map((groupAdmin) => (
                  <ListItem key={groupAdmin.id}>
                    {groupAdmin.data.profile.name}
                  </ListItem>
                ))}
              </List>
            )
            : t('uam_group_no_admins')
          }
        </Tooltip>
      </Td>
      <Td>
        <Icon name="forward" size="sm" />
      </Td>
    </Tr>
  )
};

const Groups = (props) => {
  const {searchTerm} = props;
  const accessLevel = useSelector(accessLevelSelector);
  const isPortalAdmin = accessLevel === 'Admin';
  const currentUser = useSelector(currentUserSelector);
  const groups = isPortalAdmin
    ? useSelector(appSelectors.createdGroupsSelector)
    : useSelector(appSelectors.groupsByAdminSelector(currentUser.userId));

  const filteredGroups = searchTerm && searchTerm.length ? groups.filter((group) => group.data.displayName.toLowerCase().includes(searchTerm.toLowerCase())) : groups;

  const sortedGroups = sort(filteredGroups, {
    valueSelector: (filteredGroup) => filteredGroup.data.displayName,
    type: 'string',
  });

  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(sortedGroups.length / pageSize);

  const pagingStartIndex = currentPage * pageSize - pageSize;
  const pagingEndingIndex = currentPage * pageSize;

  const paginated = sortedGroups.slice(pagingStartIndex, pagingEndingIndex);

  return (
    <Flex direction="column" width="100%">
      <Table css="margin-bottom: 24px">
        <Thead>
          <Tr>
            <Th>{t('group_name')}</Th>
            <Th>{t('group_members')}</Th>
            <Th>{t('group_admins')}</Th>
            <Th style={{width: '40px'}}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginated.map((group) => (
            <GroupRow key={group.id} group={group} />
          ))}
        </Tbody>
      </Table>
      <CustomPagination
        totalItems={sortedGroups.length}
        currentPageItems={paginated.length}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        renderShowingString={(currentPageCount, totalCount) => (
          t('access_management_group_list_paging', {currentPageCount, totalCount})
        )}
      />
    </Flex>
  );
};

export default Groups;
