import React, {Component} from 'react';
import {t} from 'core/helpers/i18n';
import {Column, Flex, Row} from '@signicat/designi/components/layout';
import {Heading} from '@signicat/designi/components/text';
import {Message} from '@signicat/designi/components/messages';
import {Button} from '@signicat/designi/components/buttons';

const reloadPage = () => {
  window.location.reload();
};

class LoginMethodErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {hasError: false};
  }

  static getDerivedStateFromError(err) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Row>
          <Column>
            <Message variant="error" icon="error">
              <Flex flex="1 0 auto">
                {t('login_method_error_boundary_message')}
              </Flex>
              <Flex flex="0 0 auto">
                <Button color="negative" size="sm" onClick={reloadPage}>
                  {t('core_button_refresh_page')}
                </Button>
              </Flex>
            </Message>
          </Column>
        </Row>
      );
    }

    return this.props.children;
  }
}

export default LoginMethodErrorBoundary;
