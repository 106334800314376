import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';
import path from 'path';
import {Flex} from '@signicat/designi/components/layout';
import {CURRENCY_COUNTRIES} from 'core/constants';

import {
  getBillingInfo,
  getBillingStatus,
  getPaymentMethods,
} from 'core/store/actions/app';

import {planByIdSelector} from 'core/store/selectors/billing';
import Plans from './Plans.js';
import EIDSelection from './EIDSelection.js';
import Confirm from './Confirm.js';
import Details from './Details.js';
import Contact from './Contact.js';

const Upgrade = styled((props) => {
  const selectedPlan = new URLSearchParams(location.search).get('plan');
  const plan = useSelector(planByIdSelector(selectedPlan));
  const billingInfo = useSelector(state => state.app.billingInfo)
  const plans = useSelector(state => state.billing.plans)
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const selectedEids = useSelector(state => state.upgrade.selectedEids);

  useEffect(() => {
    const acceptedCurrencies = plans && plans.data && plans.data.length > 0 ? plans.data[0].view.currencies : ["eur", "nok", "sek"]
    if (billingInfo && billingInfo.address && billingInfo.address.country) {
      const currencyCountry = CURRENCY_COUNTRIES.find(c => c.countryName.toLowerCase() == billingInfo.address.country.toLowerCase())
      if (currencyCountry && acceptedCurrencies.includes(currencyCountry.currencyCode.toLowerCase())) {
        dispatch({type: "SET_CURRENCY", currency: currencyCountry.currencyCode})
      }
    }
  }, [billingInfo])

  useEffect(() => {
    dispatch(getBillingInfo());
    dispatch(getBillingStatus());
    dispatch(getPaymentMethods());
  }, []);

  const billedPlans = useSelector(state => state.billing.billedPlans);
  const isSelfServiceEligible = useSelector(state => state.billing.isSelfServiceEligible);
  if (isSelfServiceEligible && (!selectedEids || selectedEids.length == 0)) {
    //If the user refreshed on confirm or details page, return him to plans page
    if (['confirm', 'details'].includes(match.params.step)) {
      return <Redirect to={'plans'} />
    }
    //If the selected plan cannot be found, return to plans
    if (!plan && match.params.step !== 'plans') {
      return <Redirect to={'plans'} />
    }
  }
  return (
    (!billedPlans || billedPlans.length == 0) &&
    <Flex className={props.className}>
      <Switch location={location}>
        {isSelfServiceEligible &&
          <Route exact path={path.join(match.path, 'plans')}>
            <Plans />
          </Route>
        }
        <Route exact path={path.join(match.path, 'eidSelection')}>
          <EIDSelection />
        </Route>
        <Route exact path={path.join(match.path, 'confirm')}>
          <Confirm />
        </Route>
        <Route exact path={path.join(match.path, 'details')}>
          <Details />
        </Route>
        <Route exact path={path.join(match.path, 'contact')}>
          <Contact />
        </Route>
      </Switch>
    </Flex>
  );
})`
flex-direction: column;
max-width: 960px;
align-self: center;
`;

export default Upgrade;
