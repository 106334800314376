import {session} from '@signiapp/helpers';
import {req} from '@signiapp/helpers/api';

import store from 'core/store';

const getCurrentUser = async () => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/user`);
};

const getCurrentAccess = async () => {
  try {
    const {accessToken} = session.auth;
    const payloadEncoded = accessToken.split('.')[1];
    const payload = JSON.parse(atob(payloadEncoded));
    const {tenant} = payload;

    // Handle access tokens without tenant
    if (!tenant) {
      return [];
    }

    // Get access details from accessToken
    const access = tenant instanceof Object ? [tenant] : tenant;

    return access.map((t) => ({
      resourceType: t.t,
      resourceId: t.i,
      accessLevel: t.a,
      permissions: t.p ? (t.p instanceof Array ? t.p : t.p.split(' ')) : [],
      groups: t.g ? (t.g instanceof Array ? t.g : t.g.split(' ')) : [],
    }));
  } catch (err) {
    console.error(err);

    return [];
  }
};

const saveUser = async (user) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;
  const {userId, profile} = user;

  return req(`${baseUrl}/users/${userId}/profile`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profile),
  });
};

const updateUserProfile = async (userId, profile) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/users/${userId}/profile`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(profile),
  });
};

const deleteUser = async (userId) => {
  const state = store.getState();
  const {core} = state;

  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/users/${userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const removeUser = async (userId) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, appId: resourceId} = core.app.config.accessApi;
  const resourceType = 'App';
  const entityType = 'User';
  const entityId = userId;

  return req(
    `${baseUrl}/records/${resourceType}/${resourceId}/${entityType}/${entityId}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const getUsers = async () => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, appId: resourceId} = core.app.config.accessApi;

  const filter = [
    {
      propertyPath: 'ResourceAccess.ResourceType',
      operator: 'EQ',
      value: 'App',
    },
    {
      propertyPath: 'ResourceAccess.ResourceId',
      operator: 'EQ',
      value: resourceId,
    },
  ];

  // Limit per page
  const limit = 100;
  let offset = 0;

  return req(`${baseUrl}/users?filter=${encodeURIComponent(JSON.stringify(filter))}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      const getRemainingPages = async (firstPage) => {
        let allPages = {data: [...firstPage.data]};
        let prevPage = {...firstPage};
        let currPage;

        do {
          offset = offset + (prevPage.data?.length || 0);
          currPage = await req(`${baseUrl}/users?filter=${encodeURIComponent(JSON.stringify(filter))}&limit=${limit}&offset=${offset}`);
          allPages.data = [...allPages.data, ...currPage.data];
          prevPage = {...currPage};
        } while (currPage.data?.length === limit);

        return allPages;
      };

      if (res.data.length < limit) {
        return res;
      }

      // Note that getRemainingPages also includes the fist result set
      return getRemainingPages(res);
    });
};

export const getInvitations = async () => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, appId: resourceId} = core.app.config.accessApi;

  const filter = [
    {
      propertyPath: 'GrantedAccess.ResourceType',
      operator: 'EQ',
      value: 'App',
    },
    {
      propertyPath: 'GrantedAccess.ResourceId',
      operator: 'EQ',
      value: resourceId,
    },
  ];

  const limit = 100;
  let offset = 0;

  return req(`${baseUrl}/invitations?filter=${encodeURIComponent(JSON.stringify(filter))}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      const getRemainingPages = async (firstPage) => {
        let allPages = {data: [...firstPage.data]};
        let prevPage = {...firstPage};
        let currPage;

        do {
          offset = offset + (prevPage.data?.length || 0);
          currPage = await req(`${baseUrl}/invitations?filter=${encodeURIComponent(JSON.stringify(filter))}&limit=${limit}&offset=${offset}`);
          allPages.data = [...allPages.data, ...currPage.data];
          prevPage = {...currPage};
        } while (currPage.data?.length === limit);

        return allPages;
      };

      if (res.data.length < limit) {
        return res;
      }

      return getRemainingPages(res);
    });
};

export const revokeInvitation = async (invitationId) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  try {
    return req(`${baseUrl}/invitations/${encodeURIComponent(invitationId)}/revoke`, {method: 'POST'});
  } catch (err) {
    console.error('Failed to revoke invitation');
  }
};

const getApp= async () => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, appId: resourceId} = core.app.config.accessApi;

  return req(`${baseUrl}/apps/${resourceId}`);
};

const createGroup = async (group) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, appId: resourceId} = core.app.config.accessApi;

  return req(`${baseUrl}/apps/${resourceId}/security/groups`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(group),
  });
};

const saveGroup = async (group) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, appId: resourceId} = core.app.config.accessApi;

  return req(`${baseUrl}/apps/${resourceId}/security/groups/${group.group}`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(group),
  });
};

export const deleteGroup = (groupId) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, appId: resourceId} = core.app.config.accessApi;

  return req(`${baseUrl}/apps/${resourceId}/security/groups/${groupId}`, {
    method: 'DELETE',
  });
};

const sendInvitation = (invitation) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/invitations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(invitation),
  })
    .catch((err) => {
      if (err.body?.error === 'app_max_users_reached') {
        throw new Error('APP_MAX_USERS_REACHED');
      }

      throw err;
    });
};

const getInvitationByToken = (invitationToken) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;
  return req(`${baseUrl}/invitationtokens/${invitationToken}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const registerUser = (user) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  }).then((result) => {
    if (result.status >= 400) {
      throw new Error(`${result.status}`);
    }

    return true;
  });
};

const acceptInvitation = (invitationToken) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;
  const {acceptToken} = state.invitation.invitationToken;

  return req(
    `${baseUrl}/invitations/${invitationToken}/accept?a=${acceptToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).catch((err) => {
    if (err.body?.error === 'already_exists') {
      throw new Error('USER_ALREADY_HAS_ACCESS');
    } else if (err.body?.error === 'app_max_users_reached') {
      throw new Error('APP_MAX_USERS_REACHED');
    } else if (err.body?.error === 'invalid_idp') {
      throw new Error('INVALID_IDP');
    }
  });
};

const declineInvitation = async (invitationToken) => {
  const state = store.getState();
  const {core} = state;

  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/invitations/${invitationToken}/decline`, {
    method: 'POST',
  }).then((result) => {
    if (result.status >= 400) {
      throw new Error(`${result.status}`);
    }
    return true;
  });
};

const updatePermissionRecord = async (resourceType, resourceId, entityType, entityId, changes) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/records/${resourceType}/${resourceId}/${entityType}/${entityId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(changes),
  });
};

const addGroupToPermissionRecord = async (resourceType, resourceId, entityType, entityId, group) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/records/${resourceType}/${resourceId}/${entityType}/${entityId}/groups`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({group: group}),
  });
};

const removeGroupFromPermissionRecord = async (resourceType, resourceId, entityType, entityId, group) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/records/${resourceType}/${resourceId}/${entityType}/${entityId}/groups`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({group: group}),
  });
};

const removeLoginMethod = async (userId, identityProvider, subjectId) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/users/${userId}/logins/${identityProvider}/${subjectId}`, {
    method: 'DELETE',
  });
};

const getUserLoginMethods = async (userId) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/users/${userId}/logins`, {
    method: 'GET',
  });
};

const getRegistrationToken = async () => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/user/registerLogin`, {
    method: 'GET',
  });
};

const useRegistrationToken = async (regToken) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl} = core.app.config.accessApi;

  return req(`${baseUrl}/user/registerLogin?token=${regToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};


export default {
  getCurrentUser,
  getCurrentAccess,
  saveUser,
  getUsers,
  sendInvitation,
  getInvitationByToken,
  registerUser,
  deleteUser,
  acceptInvitation,
  declineInvitation,
  removeUser,
  addGroupToPermissionRecord,
  removeGroupFromPermissionRecord,
  updatePermissionRecord,
  getApp,
  createGroup,
  saveGroup,
  deleteGroup,
  updateUserProfile,
  getInvitations,
  revokeInvitation,
  removeLoginMethod,
  getUserLoginMethods,
  getRegistrationToken,
  useRegistrationToken,
};
