import React, {useState} from 'react';
import styled from 'styled-components';
import {Flex} from '@signicat/designi/components/layout';
import {TextField} from '@signicat/designi/components/inputs';
import {media} from '@signicat/designi/helpers/responsive';
import {t} from 'core/helpers/i18n';

const SearchFlex = styled(Flex)`

`;

const StyledFlex = styled(Flex)`

  ${media.css.mobile.and('down')`
    flex-direction: column;
    & > ${SearchFlex} {
      margin-top: 16px;
      justify-content: stretch;
      align-items: stretch;
      flex-direction: column;
    }
  `}
`;

const Filters = (props) => {
  const {handleSearchTermChange, searchTerm} = props;

  const handleSearchInputChange = (e) => {
    handleSearchTermChange(e.target.value);
  };

  return (
    <StyledFlex>
      <SearchFlex>
        <TextField
          type="text"
          placeholder={t('core_access_management_search_label')}
          value={searchTerm}
          onChange={handleSearchInputChange}
          icon={'search'}
        >
        </TextField>
      </SearchFlex>
    </StyledFlex>
  );
};

export default Filters;
