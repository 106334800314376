import React, {useState} from 'react';
import styled from 'styled-components';

import {Button} from '@signicat/designi/components/buttons';
import {Flex} from '@signicat/designi/components/layout';
import {media} from '@signicat/designi/helpers/responsive';

import {t} from 'core/helpers/i18n';

const SearchFlex = styled(Flex)`
`;

const StyledFlex = styled(Flex)`
  margin-top: 60px;
  margin-bottom: 40px;
  ${media.css.mobile.and('down')`
    flex-direction: column;
    & > ${SearchFlex} {
      margin-top: 16px;
      justify-content: stretch;
      align-items: stretch;
      flex-direction: column;
    }
  `}
`;

const FilterToggle = styled(Button)`
  padding: 0;
  margin-right: 24px;
  font-size: 18px;
  color: #565656;
  ${(props) => {
        return props.active ? `
      color: #2a0062;
      position: relative;
      &:after {
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        content: '';
        height: 2px;
        background: #2a0062;
      }
    ` : null
    }}
`;

const predefinedFilters = [
    {
        id: 'all',
        label: 'filters_label_all'
    },
    {
        id: 'norway',
        label: 'filters_label_norway'
    }
];

const Filters = (props) => {
    const {onChange} = props;
    const [activePredefinedFilter, setActivePredefinedFilter] = useState('all');
    const handleChange = (id) => {
        setActivePredefinedFilter(id);
        onChange(id)
    }
    return (
        <StyledFlex>
            <Flex flexGrow="1" alignItems="center">
                {predefinedFilters.map((filter) => (
                    <FilterToggle
                        key={filter.id}
                        onClick={() => handleChange(filter.id)}
                        transparent
                        active={filter.id === activePredefinedFilter}
                    >
                        {t(filter.label)}
                    </FilterToggle>
                ))}
            </Flex>
        </StyledFlex>
    );
};

export default Filters;