const defaultState = {
  user: null,
  loggedIn: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'OIDC_LOGIN_COMPLETE':
      return {
        ...state,
        user: '',
        loggedIn: true,
      };
    case 'OIDC_LOGOUT_COMPLETE':
      return {
        ...state,
        user: null,
        loggedIn: false,
      };
    case 'RECEIVE_USER':
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};
