import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import {Modal} from '@signicat/designi/components/overlays';
import {Flex} from '@signicat/designi/components/layout';
import {Empty} from '@signicat/designi/components/containers';
import {Button} from '@signicat/designi/components/buttons';
import {Heading, Paragraph} from '@signicat/designi/components/text';

const Container = styled(Flex)`
  // max-width: 400px;
`;

const ModalActions = styled(Flex)`
`;

const ModalButton = styled(Button)`
  flex: 1 0 0;
`;

const ModalHeading = styled(Heading)`
  text-align: center;
  color: #000;
`;

const ModalMessage = styled(Paragraph)`
  text-align: left;
  margin-bottom: 24px;
`;

const Backdrop = styled(Flex)`
  z-index: 90;
  position: absolute;
  backdrop-filter: blur(12px);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
`;

const TrialExpired = styled((props) => {
  const {className} = props;
  const isSelfServiceEligible = useSelector(state => state.billing.isSelfServiceEligible);
  return (
    <div className={className}>
      <Modal open hideBackdrop>
        <ModalHeading>Free trial expired</ModalHeading>
        <ModalMessage>
          To keep up with the happy signing work you have to upgrade. If you need help tailor a service more suited for your company&apos;s needs please reach out to one of our people at the sales department or your account will be deleted shortly.
        </ModalMessage>
        <ModalActions>
          <ModalButton variant="transparent" color="primary">
            More information
          </ModalButton>
          <ModalButton color="primary" to={isSelfServiceEligible ? "/upgrade/plans" : "upgrade/contact"} forwardedAs={Link}>
            Upgrade
          </ModalButton>
        </ModalActions>
        {/* <Empty */}
        {/*   emoji="⌛️" */}
        {/*   heading="Free trial expired" */}
        {/*   message="To keep up with the happy signing work you have to upgrade. If you need help tailor a service more suited for your company's needs please reach out to one of our people at the sales department or your account will be deleted shortly." */}
        {/* /> */}
      </Modal>
      <Backdrop />
    </div>
  );
})`
`;

export default TrialExpired;
