import React, {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Spinner} from '@signicat/designi/components/loaders';
import {Empty} from '@signicat/designi/components/containers';

import {session as sessionActions} from 'core/store/actions';
import {t} from 'core/helpers/i18n';

const Landing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const [loginAborted, setLoginAborted] = useState(false);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const invitation = useSelector((state) => state.invitation.invitationToken);
  const session = useSelector((state) => state.session);

  const handleAcceptClick = () => {
    if (session.isAuthenticated) {
      history.push(`${match.url}/accept`);
    } else {
      setLoginInProgress(true);

      dispatch(sessionActions.login({redirectUri: `${match.url}/accept`}))
        .then(() => {
          setLoginInProgress(false);
          history.push(`${match.url}/accept`);
        })
        .catch((err) => {
          setLoginInProgress(false);

          switch (err.message) {
            case 'OIDC_POPUP_CLOSED':
              setLoginAborted(true);
              break;
            default:
              console.error(err);
              break;
          }
        });
    }
  };

  const handleDeclineClick = () => {
    history.push(`${match.url}/decline`);
  };

  if (loginInProgress === true) {
    return (
      <Spinner fullscreen />
    );
  }

  if (loginAborted) {
    return (
      <Empty
        emoji="😳"
        heading={t('session_login_popup_failed_heading')}
        message={t('session_login_popup_failed_message')}
        action={t('session_login_popup_failed_action')}
        handleActionClick={handleAcceptClick}
      />
    );
  }

  return (
    <Empty
      emoji="📨"
      heading={t('invitation_landing_heading', {appName: invitation.resourceDisplayName})}
      message={t('invitation_landing_message', {appName: invitation.resourceDisplayName})}
      handleActionClick={handleAcceptClick}
      action={t('invitation_landing_action_accept')}
      handleSecondaryActionClick={handleDeclineClick}
      secondaryAction={t('invitation_landing_action_decline')}
    />
  );
};

export default Landing;
