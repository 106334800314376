const defaultState = {
    selectedEids: [],
    flowInstanceId: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_EIDS':
            return {...state, selectedEids: action.eids};
        case 'SET_FLOWINSTANCEID':
            return {...state, flowInstanceId: action.flowInstanceId};
        default:
            return state;
    }
};
