/* eslint-disable */
import {lazy} from 'react';

import {
  getMenu as contactGetMenu,
  Settings as contactSettings,
  meta as contactMeta,
  config as contactConfig,
} from '@signiapp/contact';
import {
  getMenu as signatureGetMenu,
  Settings as signatureSettings,
  meta as signatureMeta,
  config as signatureConfig,
} from '@signiapp/signature';

export default {
  'contact': {
    id: 'contact',
    getMenu: contactGetMenu,
    Settings: contactSettings,
    meta: contactMeta,
    config: {
      hideHeader: false,
      hideFooter: false,
      hideMainWrap: false,
      ...contactConfig || {},
    },
    module: lazy(() => import('@signiapp/contact')),
  },
  'signature': {
    id: 'signature',
    getMenu: signatureGetMenu,
    Settings: signatureSettings,
    meta: signatureMeta,
    config: {
      hideHeader: false,
      hideFooter: false,
      hideMainWrap: false,
      ...signatureConfig || {},
    },
    module: lazy(() => import('@signiapp/signature')),
  },
};
