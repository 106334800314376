import {useHistory} from 'react-router-dom';

import {ROUTES} from 'core/constants';

// eslint-disable-next-line
export default () => {
  const history = useHistory();

  history.replace(ROUTES.USER_ACCESS_MANAGEMENT);

  return null;
};
