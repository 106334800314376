import {light} from '@signicat/designi/themes';

const defaultState = {
  isReady: false,
  language: 'en',
  isActive: true,
  isLoaded: false,
  isLoading: false,
  theme: light,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_APP':
      return {
        ...state,
        app: action.app,
      };
    case 'SET_WINDOW_IS_ACTIVE':
      return {
        ...state,
        isActive: action.isActive,
      };
    case 'SET_MODULES':
      return {
        ...state,
        modules: action.modules,
      };
    case 'GET_DEPENDENCIES_REQUEST':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'GET_DEPENDENCIES_SUCCESS':
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        error: null,
      };
    case 'GET_DEPENDENCIES_FAILURE':
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        error: {
          message: 'Unable to required dependencies.',
        },
      };
    default:
      return state;
  }
};
