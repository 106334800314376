import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {Button} from '@signicat/designi/components/buttons';
import {Segment} from '@signicat/designi/components/containers';
import {Grid} from '@signicat/designi/components/layout';
import {HelpText} from '@signicat/designi/components/text';
import {Label} from '@signicat/designi/components/forms';
import {Select} from '@signicat/designi/components/inputs';
import {Icon} from '@signicat/designi/components/icons';

import {saveUserLanguage} from 'core/store/actions/user';
import {t} from 'core/helpers/i18n';
import {LANGUAGES} from 'core/constants';
import {appLanguageSelector} from 'core/store/selectors/app';
import {userLanguageSelector} from 'core/store/selectors/user';

const Language = () => {
  const dispatch = useDispatch();
  const language = useSelector(userLanguageSelector);
  const appLanguage = useSelector(appLanguageSelector);

  const [selectedLanguage, setSelectedLanguage] = useState(language || 'app_default');

  const languageOptions = [
    {
      value: 'app_default',
      text: t('lang_app_default', {language: t(`lang_${appLanguage}`)}),
    },
    ...LANGUAGES.PORTAL_GUI.map((languageCode) => ({
      value: languageCode,
      text: t(`lang_${languageCode}`),
    })).sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? 0 : -1)),
  ];

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleSaveSettingsClick = () => {
    dispatch(saveUserLanguage(selectedLanguage === 'app_default' ? null : selectedLanguage));
  };

  return (
    <Segment divided outlined direction="column" marginBottom="32px">
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <Grid item style={{flex: '0 0 auto', alignItems: 'flex-start'}}>
              <Icon name="language" style={{paddingLeft: '4px', paddingTop: '12px'}} />
            </Grid>
            <Grid item style={{flex: '1 0 0'}}>
              <Grid container direction="column" justifyContent="center">
                <Label>{t('user_settings_language_input_label')}</Label>
                <HelpText style={{marginTop: '2px'}}>{t('user_settings_language_input_helptext')}</HelpText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={9}>
              <Select
                id="profile_language_selector"
                testSelector="language"
                options={languageOptions}
                value={selectedLanguage}
                onChange={handleLanguageChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Button onClick={handleSaveSettingsClick} color="primary" style={{flexGrow: 1}}>
                {t('button_text_save')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Segment>
  );
};

export default Language;
