import {createSelector} from 'reselect';

import {groupsSelector} from './app.js';

export const sessionSelector = (state) => state.session;

export const currentUserSelector = createSelector(
  sessionSelector,
  (session) => ({
    ...session.user,
    resourceAccess: session.access,
  })
);

export const currentAccess = (state) => {
  return state?.session?.access;
};

export const accessLevelSelector = (state) => {
  return state?.session?.access?.accessLevel;
};

export const isGroupAdminSelector = createSelector(
  currentUserSelector,
  groupsSelector,
  // eslint-disable-next-line no-console
  (user, groups) => groups.reduce((acc, group) => {
    return acc || group.data.admins && Boolean(
      group.data.admins
        .filter((admin) => admin.type === 'User')
        .map((admin) => admin.id)
        .includes(user.userId)
    );
  }, false)
);

export default {
  currentUserSelector,
  currentAccess,
  accessLevelSelector,
};
