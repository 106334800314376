import dotProp from 'dot-prop-immutable';

const defaultState = {
  isLoading: false,
  isSaving: false,
  isChangingLanguage: false,
  userId: null,
  error: null,
  settings: {
    i18n: {
      language: null,
    },
  },
  loginMethods: [],
  errorForLoginMethods: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'PROFILE_SAVE_REQUEST':
      return {
        ...state,
        isSaving: true,
        error: null,
      };
    case 'PROFILE_SAVE_SUCCESS':
      return {
        ...state,
        isSaving: false,
        error: null,
      };
    case 'PROFILE_SAVE_FAILURE':
      return {
        ...state,
        isSaving: false,
        error: action.error,
      };
    case 'USER_SET_SETTINGS':
      return {
        ...state,
        settings: {...action.settings},
      };
    case 'USER_SET_CHANGING_LANGUAGE':
      return {
        ...state,
        isChangingLanguage: action.isChangingLanguage,
      }
    case 'USER_SET_LANGUAGE':
      return dotProp.set(state, 'settings.i18n.language', action.language);

    case 'USER_INITIALIZE_SETTINGS_FAILURE':
      return {
        ...state,
        errorForLoginMethods: action.errorForLoginMethods,
      };

    case 'USER_INITIALIZE_SETTINGS_SUCCESS':
      return {
        ...state,
        userId: action.userId,
        loginMethods: action.loginMethods,
      };

    case 'GET_USER_LOGIN_METHODS_SUCCESS':
      return {
        ...state,
        loginMethods: action.loginMethods,
      };

    default:
      return state;
  }
};
