import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Link, Switch} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import {differenceInCalendarDays} from 'date-fns';
import {CSSTransition} from 'react-transition-group';
import {Flex} from '@signicat/designi/components/layout';

import {t} from 'core/helpers/i18n';
import {Button} from '@signicat/designi/components/buttons';
import {appSelector} from 'core/store/selectors/app';

const UpgradeButton = styled(Button)`
  background-color: #fff;
  border-color: #fff;
  color: ${(props) => (
    props.theme.styles.colors.primary
  )};
`;


const GlobalStyle = createGlobalStyle`
  body {
    transition: margin 500ms;
    margin-top: 48px;
  }
  #signiapp{
    min-height: calc(100vh - 48px);
  }
`;

const StyledTrialBanner = styled(Flex)`
  padding: 12px 24px;
  position: fixed;
  z-index: 1200;
  top: -48px;
  left: 0;
  right: 0;
  min-height: 48px;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (
    props.theme.styles.colors.primary
  )};
  color: ${(props) => (
    props.theme.styles.colors.backgroundAlternate
  )};

  &.trial-banner-enter {
    top: -48px;
  }

  &.trial-banner-enter-active {
    top: 0px;
    transition: top 400ms;
  }

  &.trial-banner-enter-done {
    top: 0px;
  }

  &.trial-banner-leave {
    top: -48px;
  }

  &.trial-banner-leave-active {
    transition: top 400ms;
  }
`;

const TrialBanner = (props) => {
  const {children} = props;
  const [visible, setVisible] = useState(false);

  const app = useSelector(appSelector);
  const {trial} = app;
  const daysLeft = trial?.isTrial
    ? Math.max(0, differenceInCalendarDays(new Date(trial.expires), new Date()))
    : null;
  const isSelfServiceEligible = useSelector(state => state.billing.isSelfServiceEligible);
  useEffect(() => {
    setVisible(true);
    return () => {
      setVisible(false);
    };
  });

  return (
    <CSSTransition
      in={visible}
      classNames="trial-banner"
      timeout={400}
    >
      <StyledTrialBanner>
        <GlobalStyle />
        {children ? children : (
          <Fragment>
            <Flex>
              {t('portal_app_trial_bar_message', {daysLeft: daysLeft})}
            </Flex>
            <Flex style={{marginLeft: '12px'}}>
              <UpgradeButton
                size="xs"
                to={isSelfServiceEligible ? "/upgrade/plans" : "/upgrade/contact"}
                forwardedAs={Link}
              >
                {t('portal_app_trial_bar_cta_button_text')}
              </UpgradeButton>
            </Flex>
          </Fragment>
        )}
      </StyledTrialBanner>
    </CSSTransition>
  );
};

export default TrialBanner;
