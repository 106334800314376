import ROUTES from 'core/constants/routes.js';
import URLS from 'core/constants/urls.js';

export const OIDC = {
  LOGIN_REDIRECT_URI: `${URLS.SIGNIAPP_URL}${ROUTES.LOGIN_REDIRECT}`,
  RENEW_URI: `${URLS.SIGNIAPP_URL}${ROUTES.AUTH_RENEW}`,
  LOGOUT_REDIRECT_URI: `${URLS.SIGNIAPP_URL}${ROUTES.LOGOUT_REDIRECT}`,
  LOGIN_POPUP_URI: `${URLS.SIGNIAPP_URL}${ROUTES.LOGIN_POPUP}`,
};

export const SENTRY = {
  DSN: 'https://c608b12b931e497f9feb9594e5450579@o202030.ingest.sentry.io/2295465',
};

export default {
  OIDC,
};
