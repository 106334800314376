import CustomErrorInstance from 'custom-error-instance';

import SigniappError from './SigniappError.js';

const SessionError = CustomErrorInstance('SessionError', SigniappError, {
  message: null,
  code: 'SGN:5.400', // NOTE: Just making up some error codes
});

export default SessionError;
