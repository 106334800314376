import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Flex} from '@signicat/designi/components/layout';
import {Empty} from '@signicat/designi/components/containers';
import {media} from '@signicat/designi/helpers/responsive';
import {useHistory, useParams} from 'react-router-dom';

import ROUTES from 'core/constants/routes';
import {plansSelector} from 'core/store/selectors/billing';
import {t} from 'core/helpers/i18n';
import Plan from './components/Plan.js';
import UpgradeFlowHeading from './components/Heading.js';

const PlansContainer = styled(Flex)`
  ${media.css.mobile.and('down')`
    flex-direction: column;

    > ${Plan} {
      margin-top: 12px;
      margin-bottom: 12px;
      min-height: initial!important;
    }

    > ${Plan}:first-child {
      margin-top: 0;
    }

    > ${Plan}:last-child {
      margin-bottom: 0;
    }
  `}

  ${media.css.tablet.and('up')`
    flex-direction: row;

    > ${Plan} {
      margin-right: 12px;
      margin-left: 12px;
    }

    > ${Plan}:first-child {
      margin-left: 0;
    }

    > ${Plan}:last-child {
      margin-right: 0;
    }
  `}
`;

const Plans = (props) => {
  const history = useHistory();
  const {moduleId} = useParams();
  const plans = useSelector(plansSelector());

  const {...rest} = props;

  const handleActionClick = (plan) => (e) => {
    if (plan.view.requireSales) {
      history.push(`${ROUTES.UPGRADE.CONTACT}?plan=${plan.id}`);
    } else {
      history.push(`${ROUTES.UPGRADE.EIDSELECTION}?plan=${plan.id}`);
    }
  };

  const currency = useSelector((state) => state.app.currency);

  return (
    <Fragment>
      <UpgradeFlowHeading>{t('upgrade_plans_heading')}</UpgradeFlowHeading>
      <PlansContainer {...rest}>
        {plans?.length
          ? plans.map((plan) => (
              <Plan
                key={plan.id}
                planId={plan.id}
                price={plan.view.prices[currency.toLowerCase()]}
                priceTerms={plan.priceTerms}
                currency={currency}
                features={plan.view.features}
                actionText={t('upgrade_buy_plan', {plan: t(`upgrade_plans_${plan.id}_name`)})}
                highlighted={plan.view.highlighted}
                onActionClick={handleActionClick(plan)}
                featuresHeading={plan.featuresHeading}
                featuresList={plan.view.features}
                featuresPricing={plan.view.pricingSamples}
                requireSales={plan.view.requireSales}
                isInDetailsView={false}
              />
            ))
          : ' ' || (
              <Empty
                emoji="⚠️"
                heading={t('upgrade_no_plans_available_heading')}
                message={t('upgrade_no_plans_available_message')}
              />
            )}
      </PlansContainer>
    </Fragment>
  );
};

export default Plans;
