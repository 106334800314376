import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Flex} from '@signicat/designi/components/layout';
import {Button} from '@signicat/designi/components/buttons';
import {List, ListItem} from '@signicat/designi/components/lists';
// import {Text} from '@signicat/designi/components/text';

import Features from './Features.js';

import {planByIdSelector} from 'core/store/selectors/billing';

import {t} from 'core/helpers/i18n';

export const Text = styled('span')`
  font-weight: ${(props) => (props.light ? '200' : props.bold ? 500 : 'inherit')};
`;

export const Content = styled(Flex)`
  flex-direction: column;
  padding: 0 36px;
  padding-top: 56px;
  padding-bottom: 12px;
  width: 100%;
`;

export const PriceHeader = styled(Flex)``;

export const PriceContainer = styled(Flex)`
  align-items: baseline;
`;

export const PriceAmount = styled(Flex)`
  font-weight: 600;
  font-size: 42px;
`;

export const PriceCurrency = styled(Flex)`
  font-weight: 600;
  font-size: 32px;
  margin-left: 8px;
  text-transform: uppercase;
`;

export const PriceContactSales = styled(Flex)`
  color: #3d4e5e;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  text-align: center;
`;

export const PriceTerms = styled(Flex)`
  text-align: center;
  font-size: 16px;
  color: ${(props) => (props.isInDetailsView ? 'white' : props.theme.styles.colors.bodySecondaryTextColor)};
`;

export const Price = styled((props) => {
  const {amount, currency, requireSales, planId, isInDetailsView, ...rest} = props;

  const plan = useSelector(planByIdSelector(planId));

  return (
    <Flex {...rest}>
      <PriceHeader marginBottom="8px">
        {requireSales ? (
          <PriceContactSales>{t('upgrade_contactsales_heading')}</PriceContactSales>
        ) : (
          <PriceContainer isInDetailsView={isInDetailsView}>
            <PriceAmount>{amount}</PriceAmount>
            <PriceCurrency>{t(`currency_${currency.toLowerCase()}`)}</PriceCurrency>
          </PriceContainer>
        )}
      </PriceHeader>
      <PriceTerms isInDetailsView={isInDetailsView}>{t(`upgrade_plan_${planId}_price_terms`)}</PriceTerms>
    </Flex>
  );
})`
  margin-bottom: 28px;
  align-items: center;
  min-height: 100px;
  flex-direction: column;
  color: ${(props) => (props.isInDetailsView ? 'white' : '#3d4e5e')};
`;

const Footer = styled(Flex)`
  padding: ${(props) => (props.isInDetailsView ? '36px' : '20px')} 28px;
  width: 100%;
  color: ${(props) => (props.isInDetailsView ? 'white' : '#3d4e5e')};
  background-color: ${(props) => (props.isInDetailsView ? '#3d4e5e' : props.theme.styles.colors.backgroundAlternate)};
  flex-direction: column;
  ${(props) => (!props.isInDetailsView ? 'border-top: 2px solid ' + props.theme.styles.colors.primaryFadedColor : '')};
`;

const Plan = styled((props) => {
  const {
    actionText,
    onActionClick,
    highlighted,
    currency,
    priceTerms,
    featuresList,
    featuresPricing,
    requireSales,
    isInDetailsView,
    planId,
    ...rest
  } = props;

  const plan = useSelector(planByIdSelector(planId));

  return (
    <Flex {...rest}>
      <Content>
        <Price
          planId={planId}
          amount={plan.view.prices[currency.toLowerCase()]}
          currency={currency}
          terms={priceTerms}
          requireSales={requireSales}
          isInDetailsView={isInDetailsView}
        />
        {!isInDetailsView ? (
          <Button
            primary
            size="md"
            onClick={onActionClick}
            css="margin-left:0;margin-right:0;margin-top:8px;margin-bottom: 46px;"
          >
            {' '}
            {actionText}
          </Button>
        ) : null}

        <Features planId={planId} list={featuresList} isInDetailsView={isInDetailsView} />
      </Content>
      {featuresPricing?.length ? (
        <Footer isInDetailsView={isInDetailsView}>
          {featuresPricing
            .filter((addon) => !addon.key.includes('_contract'))
            .map((addon) => (
              <List key={addon.key}>
                <ListItem css="display: flex; justify-content: space-between;">
                  <Text css="font-size:17px;font-weight:300;margin-right: 8px">
                    {t(`upgrade_plan_featureprice_${addon.key}`)}
                  </Text>
                  <Text bold css="text-transform: uppercase; text-align: center; white-space: nowrap;">
                    {addon.prices[currency.toLowerCase()]} {currency.toUpperCase()}
                  </Text>
                </ListItem>
              </List>
            ))}
        </Footer>
      ) : null}
    </Flex>
  );
})`
  flex: 1 0 0;
  justify-content: space-between;
  min-width: 300px;
  min-height: 680px;
  border: ${(props) =>
    props.isInDetailsView
      ? 'none'
      : '2px solid ' +
        (props.highlighted ? props.theme.styles.colors.primaryColor : props.theme.styles.colors.primaryFadedColor)};
  background-color: ${(props) => (props.isInDetailsView ? '#606f7d' : 'transparent')};
  border-radius: ${(props) => (props.isInDetailsView ? '0' : '4px')};
  flex-direction: column;
  align-items: center;
  margin-right: ${(props) => (props.isInDetailsView ? '0' : '12px')};
  margin-left: ${(props) => (props.isInDetailsView ? '0' : '12px')};

  &:first-child: {
    margin-left: 0;
  }

  &:last-child: {
    margin-right: 0;
  }
`;

export default Plan;
