import CustomErrorInstance from 'custom-error-instance';

import SigniappError from './SigniappError.js';

const NetworkError = CustomErrorInstance('NetworkError', SigniappError, {
  message: 'A NetworkError occurred in Signiapp.',
  code: 'SGN:6.500',
  url: null,
});

export default NetworkError;
