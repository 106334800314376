import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {useHistory, useRouteMatch} from 'react-router-dom';
import path from 'path';

import {Button} from '@signicat/designi/components/buttons';
import {Div, Flex} from '@signicat/designi/components/layout';
import {Heading, Paragraph, HelpText} from '@signicat/designi/components/text';
import {Segment} from '@signicat/designi/components/containers';
import {TextField} from '@signicat/designi/components/inputs';

import {access} from 'core/services';
import {session as sessionActions} from 'core/store/actions';
import {t} from 'core/helpers/i18n';

const FormLine = styled(Flex)`
  padding: 15px 0;
  flex-direction: column;
`;

const Registration = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    companyRole: '',
    email: '',
    mobile: '',
  });
  const match = useRouteMatch();

  const handleUserDetailsChange = (field) => (e) => {
    setUserDetails({...userDetails, [field]: e.target.value});
  };

  const handleRegisterButtonClick = () => {
    access
      .registerUser(userDetails)
      .then(() => dispatch(sessionActions.silentRenew()))
      .then(() => {
        history.push(path.resolve(match.url, '..', 'accept'));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Div>
      <Segment outlined>
        <Heading level={3}>{t('invitation_user_registration_heading')}</Heading>
        <Paragraph>
          {t('invitation_user_registration_message')}
        </Paragraph>
        <FormLine>
          <TextField
            value={userDetails.firstName}
            onChange={handleUserDetailsChange('firstName')}
            label={t('profile_field_firstname_label')}
          />
          <TextField
            label={t('profile_field_lastname_label')}
            value={userDetails.lastName}
            onChange={handleUserDetailsChange('lastName')}
          />
        </FormLine>
        <FormLine>
          <TextField
            value={userDetails.companyName}
            onChange={handleUserDetailsChange('companyName')}
            label={t('profile_field_company_label')}
          />
          <TextField
            label={t('profile_field_role_label')}
            value={userDetails.companyRole}
            onChange={handleUserDetailsChange('CompanyRole')}
          />
        </FormLine>
        <FormLine>
          <TextField
            label={t('profile_field_email_label')}
            type="email"
            value={userDetails.email}
            onChange={handleUserDetailsChange('email')}
          />
        </FormLine>
        <FormLine>
          <TextField
            label={t('profile_field_mobilenumber_label')}
            type="tel"
            value={userDetails.mobile}
            onChange={handleUserDetailsChange('mobile')}
          />
          <HelpText>{t('profile_field_mobilenumber_helptext')}</HelpText>
        </FormLine>
        <FormLine>
          <Button primary onClick={handleRegisterButtonClick}>
            {t('invitation_user_registration_submit_button_text')}
          </Button>
        </FormLine>
      </Segment>
    </Div>
  );
};

export default Registration;
