import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {AccessDenied} from '@signiapp/helpers/errors';
import {Empty} from '@signicat/designi/components/containers';
import {ROUTES} from 'core/constants';
// import {Route} from 'core/components';
import Route from 'core/components/Route.js';

const PrivateRoute = (props) => {
  const {session} = useSelector((state) => state);

  if (session.isLoading) {
    return (
      <div>Loading...</div>
    );
  }

  if (session.user === null) {
    return (
      <Route
        render={({location}) => (
          <Redirect to={{pathname: ROUTES.LOGIN, state: {from: location}}} />
        )}
      />
    );
  }

  return (
    <ErrorBoundary>
      <Route {...props} />
    </ErrorBoundary>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {hasError: false};
  }

  static getDerivedStateFromError(err) {
    return {hasError: true};
  }

  componentDidCatch(err, errorInfo) {
    if (err instanceof AccessDenied) {
      // Is handled here
    } else {
      throw err;
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Empty
          emoji="🔒"
          heading="Access denied"
          message="You don't have access to this page. If you believe this is a mistake, let us know."
        />
      );
    }

    return this.props.children;
  }
}

export default PrivateRoute;
