import React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Flex} from '@signicat/designi/components/layout';
import {media} from '@signicat/designi/helpers/responsive';

const Container = styled(Flex)`
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: ${(props) => (window.location.pathname.includes("login") && props.backdrop ? "url(" + props.backdrop + ");" : "initial;")}
  background-repeat: no-repeat;
  background-size: cover;
`
const MainContainer = styled(Flex)`
  position: relative;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  background: #fff;
  padding: 24px 16px;
  width: 100%;
  max-width: 1140px;
  ${media.css.tablet.and('up')`
    padding: 36px 24px;
  `}
`;

const Main = (props) => {
  const {children} = props;
  const backdrop = useSelector(state => state.app.backdrop)
  return (
    <Container backdrop={backdrop}>
      <MainContainer forwardedAs="main">
        {children}
      </MainContainer>
    </Container>
  );
};

export default Main;
