import store from 'core/store';

const MODULE_ID = 'core';

const getTranslations = async (moduleId, lang) => {
  const state = store.getState();
  const {core} = state;
  const {baseUrl, accountId} = core.app.config.textApi;

  return fetch(`${baseUrl}/dictionaries?translationKeyGroupName=signiapp-module-${moduleId}&language=${lang}&accountId=${accountId}`)
    .then((result) => result.json())
};

export const getDictionary = (language) => new Promise((resolve) => {
  getTranslations(MODULE_ID, language).then((dictionary) => {
    resolve(dictionary);
  });
});

export default {
  getDictionary,
};
