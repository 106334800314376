// eslint-disable-next-line no-unused-vars
import React, {Component} from 'react';
import path from 'path';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  SigniappError,
  LoginRequired,
} from '@signiapp/helpers/errors';

import {ROUTES} from 'core/constants';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {hasError: false};
  }

  static getDerivedStateFromError(err) {
    return {hasError: true};
  }

  // Will handle errors thrown in React components
  componentDidCatch(err) {
    this.handleException(err);
  }

  componentDidMount() {
    // Will handle errors thrown outside React components
    window.addEventListener('error', this.handleError);
    // Will handle unhandled promise rejections
    window.addEventListener('unhandledrejection', this.handleUnhandledRejection);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.handleError);
    window.removeEventListener('unhandledrejection', this.handleUnhandledRejection);
  }

  handleError = (e) => {
    if (e.error.hasBeenCaught !== undefined){
      return false;
    } else {
      e.error.hasBeenCaught = true;
    }

    const err = e.error;

    this.handleException(err);

    return true;
  };

  handleUnhandledRejection = (e) => {
    // console.error('ErrorHandler:handleUnhandledRejection', e);
    const err = e.reason;

    this.handleException(err);
  };

  handleException = (err) => {
    const {location, history} = this.props;

    if (err instanceof SigniappError) {
      switch (err.constructor) {
        case LoginRequired:
          this.props.logout();
          history.push(`${ROUTES.LOGIN}?redirectUri=${err.redirectUri || path.join(ROUTES.BASE, location.pathname)}`);
          break;
        default:
          // console.error(err);
          throw err;
          break;
      }
    } else {
      switch (err.message) {
        case 'OIDC_LOGIN_REQUIRED':
        case 'LOGIN_REQUIRED':
          this.props.logout();
          history.push(ROUTES.LOGIN);
          // location.href = ROUTES.LOGIN;
          break;
        default:
          // console.error(err);
          throw err;
          break;
      }
    }
  };

  render() {
    return this.props.children;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addError: (err) => dispatch({
    type: 'ADD_ERROR',
    error: err,
  }),
  logout: () => dispatch({
    type: 'SESSION_LOGOUT_SUCCESS',
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorHandler));
