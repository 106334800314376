import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';

import {UserManager} from '@signiapp/helpers/session';

import App from 'core/App.js';
import store from 'core/store';
import {debug} from 'core/helpers';
import {SENTRY} from 'core/constants/config';
import {ROUTES} from 'core/constants';

// NOTE: response_mode: 'query' is only required for redirect, not popup
const userManager = new UserManager({response_mode: 'query'});

const init = async (Modules, packageVersions) => {
  // First handle session related requests (renew, logout, login)
  // TODO: Move to separate file
  const renewPathRegEx = new RegExp(`${ROUTES.AUTH_RENEW}$`);
  const logoutPathRegEx = new RegExp(`${ROUTES.LOGOUT_REDIRECT}$`);
  const loginPathRegEx = new RegExp(`${ROUTES.LOGIN_POPUP}`);
  const signinRedirectRegEx = new RegExp(/\?code=/);

  // Handle session renew
  if (window.location.pathname.match(renewPathRegEx)) {
    await userManager.signinSilentCallback();

    return;
  } else if (window.location.pathname.match(loginPathRegEx)) {
    await userManager.signinPopupCallback();
  }

  else if (window.location.pathname.match(logoutPathRegEx)) {
    try {
      const user = await userManager.signoutRedirectCallback()

      if (user.state?.redirectUri) {
        // User state will be the redirect set in state
        window.location.replace(user.state.redirectUri);
      } else {
        const re = new RegExp(`${ROUTES.LOGOUT_REDIRECT}(.+)?`);
        const redirectBaseUri = window.location.href.replace(re, '');

        window.location.replace(`${redirectBaseUri}${ROUTES.POST_LOGOUT}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      return;
    }
  } else if (window.location.search.match(signinRedirectRegEx)) {
    try {
      const user = await userManager.signinRedirectCallback()

      if (user.state?.redirectUri) {
        // User state will be the redirect set in state
        window.location.replace(user.state.redirectUri);
      } else {
        window.location.replace(ROUTES.BASE);
      }
    } catch (err) {
      console.error(err);
    } finally {
      return;
    }
  }

  // Create and add mount node to document
  const mountElement = document.createElement('div');
  mountElement.id = 'signiapp';
  const mountNode = document.body.appendChild(mountElement);

  // Not supporting IE (including 11)
  const unsupportedBrowserRegex = /msie|(trident.*rv:11)/i;

  // Just render a string of text if using unsupported browser
  if (unsupportedBrowserRegex.test(window.navigator.userAgent)) {
    mountNode.innerText = 'Internet Explorer is not supported, as it does not guarantee full support for all features. To enjoy the best possible user experience, please use a recent version of a modern web browser such as Chrome, Edge, Firefox, Opera, or Safari.';
  } else {
    // Log package versions for Signiapp packages
    debug.logPackageVersions(packageVersions);

    // Don't init Sentry for local development
    // if (window.SIGNIAPP_IS_LOCAL === false) {
    //   Sentry.init({
    //     dsn: SENTRY.DSN,
    //     release: packageVersions.signixfrontend || null,
    //     environment: window.SIGNIAPP_ENV,
    //     ignoreErrors: [
    //       'LoginRequired',
    //       'favicon_signicat-3.webp',
    //     ],
    //   });

    //   const appMatches = window.SIGNIAPP_API_BASE_URL.match(/\/(\w+)$/);

    //   Sentry.setTag('app', appMatches ? appMatches[1] : null);
    //   Sentry.setContext('packages', packageVersions);
    // }

    // Render App
    const appEl = React.createElement(
      Provider,
      {store: store},
      React.createElement(App, {Modules: Modules})
    );

    ReactDOM.render(appEl, mountNode);
  }
};

export default init;
