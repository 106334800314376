import dotProp from 'dot-prop-immutable';

import {api} from '@signiapp/helpers';

const BASE_PATH = '/core';
const APP_SETTINGS_VERSION = 1;
const USER_SETTINGS_VERSION = 1;

const req = (url, options) => {
  return api.req(`${BASE_PATH}${url}`, options);
};

export const getPublic = () => req('/app/public');

export const getProtected = () => req('/app/protected');

export const getAppSettings = () => req(`/appData/settings-v${APP_SETTINGS_VERSION}`).then((response) => JSON.parse(response.value));

export const saveAppSettings = (settings) => req(`/appData/settings-v${APP_SETTINGS_VERSION}`, {
  method: 'PUT',
  body: JSON.stringify({value: JSON.stringify(settings)}),
  headers: {'Content-Type': 'application/json'},
});

export const getUserSettings = () => req(`/userData/settings-v${USER_SETTINGS_VERSION}`).then((response) => JSON.parse(response.value));

export const saveUserSettings = (settings) => req(`/userData/settings-v${USER_SETTINGS_VERSION}`, {
  method: 'PUT',
  body: JSON.stringify({value: JSON.stringify(settings)}),
  headers: {'Content-Type': 'application/json'},
});

export const getGeolocation = () => req('/ip');

export const getBillingStatus = () => req('/billing/status');

export const getBillingInfo = () => req('/billing/info');

export const getPaymentMethods = () => req('/billing/payment-methods');

export const patchBillingInfo = (data) => {
  return req(
    '/billing/info',
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
  );
}

export const initPaymentMethodSetup = () => (
  req('/billing/payment-method-setups', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      successUrl: successUrl,
      cancelUrl: cancelUrl,
    }),
  })
);

export const getPlans = () => req('/plans');
export const getFeatures = () => req('/features');

export const confirmPaymentMethodSetup = (setupId) => req(
  `/billing/payment-method-setups/${setupId}/confirm`,
  {method: 'POST'}
);

export const subscribeToPlan = (planId) => req(
  `/plans/${planId}/subscribe`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({dto: {features: []}})
  }
);

export const initOnboarding = (planId, eids, portalId, country) => req(
  `/onboarding/initialize/${planId}`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({domain: window.location.origin, eids, PortalId: portalId, Country: country})
})

export const getOnboardingFlowStatus = (flowInstanceId) => req(
  `/onboarding/instances?flowInstanceId=${flowInstanceId}`, {
  method: 'GET'
})

export const getOnboardingSignatureUrl = (flowInstanceId) => req(
  `/onboarding/instances/${flowInstanceId}/signurl`, {
  method: 'GET'
})

export const sendOnboardingInvoice = (flowInstanceId) => req(
  `/onboarding/instances/${flowInstanceId}/sendinvoice`, {
  method: 'GET'
})
export const getSettings = async (setKey) => {
  return await req(`/settings/${setKey}`);
};

export const updateSettings = async (setKey, settings) => {
  return req(
    `/settings/${setKey}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(settings)
    }
  );
};

export const getAvailableLoginIdpMethods = async () => {
  return req('/metadata/login-idps')
};

export default {
  getPublic,
  getProtected,
  getAppSettings,
  saveAppSettings,
  getUserSettings,
  saveUserSettings,
  getGeolocation,
  getBillingStatus,
  getBillingInfo,
  getPaymentMethods,
  initPaymentMethodSetup,
  getPlans,
  getFeatures,
  confirmPaymentMethodSetup,
  subscribeToPlan,
  patchBillingInfo,
  initOnboarding,
  getOnboardingFlowStatus,
  getOnboardingSignatureUrl,
  sendOnboardingInvoice,
  getSettings,
  updateSettings,
  getAvailableLoginIdpMethods,
};
