import React, {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Div, Grid} from '@signicat/designi/components/layout';
import {Segment} from '@signicat/designi/components/containers';
import {Label} from '@signicat/designi/components/forms';
import {HelpText} from '@signicat/designi/components/text';
import {Icon} from '@signicat/designi/components/icons';
import {Button} from '@signicat/designi/components/buttons';
import {useNotification} from '@signicat/designi/components/messages';
import {t} from 'core/helpers/i18n';
import styled from 'styled-components';
import {updateSettings, getSettingTheme} from '../../../store/actions/app';

const Content = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 600px) {
        flex-wrap: wrap;
    }
`

const LeftContent = styled('div')`
    display: flex;
    justify-content: space-between;

`

const RightContent = styled('div')`
    display: flex;
    align-items: center;
    @media only screen and (max-width: 600px) {
        width: 100%;
        margin: 10px 0; 
        flex-wrap: wrap;
        justify-content: center;
        & > *{
            margin: 8px 0;
        }
        & > *:not(img) {
            width: 100%;
        }
    }
`

const BackdropPreview = styled('img')`
    max-height: 60px;
    margin-right: 8px;
`

const MaxSizeLabel = styled('span')`
    margin-right: 8px;
`

const FileInput = styled('input')`
    display: none;
`

const Backdrop = () => {
    const dispatch = useDispatch();
    const {enqueueNotification, closeNotification} = useNotification();
    const backdrop = useSelector(state => state.app.backdrop)
    const fileInput = useRef(null);

    const showNotification = (variant, message) => {
        closeNotification()
        enqueueNotification(t(message), {
            variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
            autoHideDuration: 5000
        })
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            const modBy4 = encoded.length % 4
            if (modBy4 > 0) {
                encoded += '='.repeat(4 - modBy4);
            }
            resolve(encoded);
        }
        reader.onerror = error => reject(error);
    });

    const handleUpdateClick = () => {
        fileInput.current.click();
    }

    const handleFileChange = async (file) => {
        if (!file) return;
        if (file.size > 5000000) {
            showNotification('error', "core_module_settings_backdrop_toobig_message");
            return
        }
        try {
            const base64 = await toBase64(file)
            const payload = {
                "portalBackdropUpload": {
                    base64,
                    "fileName": file.name,
                    "mimeType": file.type
                }
            }
            dispatch(updateSettings("theme-v1", payload));
            // dispatch(getSettingTheme())
            showNotification('success', "core_module_settings_backdrop_success_message");
        }
        catch (err) {
            console.log(err)
            showNotification('error', "core_module_settings_backdrop_error_message");
        }
    }

    const handleDeleteClick = () => {
        const payload = {
            "portalBackdropUpload": {
                "base64": "",
            }
        }
        try {
            dispatch(updateSettings("theme-v1", payload));
            // dispatch(getSettingTheme())
            showNotification('success', "core_module_settings_backdrop_success_message");
        }
        catch (err) {
            console.log(err)
            showNotification('error', "core_module_settings_backdrop_error_message");
        }
    }

    return (
        <Div>
            <Segment
                divided
                outlined
                direction="column"
                marginBottom="32px"
            >
                <Content>
                    <LeftContent>
                        <Grid item style={{flex: '0 0 auto', alignItems: 'center', justifyContent: 'center', width: '45px', paddingRight: "12px"}}>
                            <Icon name="folder" />
                        </Grid>
                        <Grid item style={{flex: '1 0 0'}}>
                            <Grid container direction="column" justifyContent="center">
                                <Label>{t('backdrop_label')}</Label>
                                <HelpText style={{marginTop: '2px'}}>
                                    {t('backdrop_help_text')}
                                </HelpText>
                            </Grid>
                        </Grid>
                    </LeftContent>
                    <RightContent>
                        {/* {backdrop && <BackdropPreview src={backdrop} alt="Portal Backdrop" />} */}
                        <MaxSizeLabel>{t('core_module_settings_backdrop_maxsize')}</MaxSizeLabel>
                        <Button
                            onClick={handleUpdateClick}
                            color="primary"
                        >
                            {t('button_text_update')}
                        </Button>
                        <FileInput type="file"
                            accept="image/png, image/jpeg"
                            ref={fileInput}
                            onChange={e => {
                                handleFileChange(e.target.files[0]);
                            }}
                        ></FileInput>
                        <Button
                            onClick={handleDeleteClick}
                            color="negative"
                            variant="outlined"
                            disabled={!backdrop}
                        >
                            {t('button_text_delete')}
                        </Button>
                    </RightContent>
                </Content>
            </Segment>
        </Div>
    )
}

export default Backdrop;