import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import path from 'path';

import {sort} from '@signiapp/helpers';
import {Icon} from '@signicat/designi/components/icons';
import {List, ListItem} from '@signicat/designi/components/lists';
import {Tooltip} from '@signicat/designi/components/overlays';
import {Table, Thead, Tbody, Th, Tr, Td} from '@signicat/designi/components/tables';
import {CustomPagination} from '@signicat/designi/components/pagination';

import {t} from 'core/helpers/i18n';
import {usersSelector, groupsByUserSelector} from 'core/store/selectors/app';
import {ROUTES} from 'core/constants';

const UserRow = (props) => {
  /* Hooks */
  const history = useHistory();

  /* Variables from props */
  const {user} = props;

  /* Selectors */
  const groups = useSelector(groupsByUserSelector(user.id));

  /* Handlers */
  const navigateToUserDetails = (id) => {
    history.push(path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'users', id));
  };

  const handleUserClick = (id) => (e) => {
    navigateToUserDetails(id);
  };

  const handleKeyEvent = (id) => (e) => {
    if (e.which === 32 || e.which === 13) {
      e.preventDefault();
      navigateToUserDetails(id)
    }
  };

  return (
    <Tr
      tabIndex="0"
      role="button"
      onClick={handleUserClick(user.id)}
      onKeyPress={handleKeyEvent(user.id)}
      aria-label={`Show details for user ${user.data.profile.name}`}
    >
      <Td>
        {user.data.profile.firstName}
      </Td>
      <Td>
        {user.data.profile.lastName}
      </Td>
      <Td>
        {t(`access_level_${user.data.resourceAccess.accessLevel}`.toLowerCase())}
      </Td>
      <Td>
        <Tooltip
          anchor={<span tabIndex="0">{groups.length} {t('uam_word_groups')}</span>}
          arrow
        >
          {groups.length
            ? (
              <List>
                {groups.map((group) => (
                  <ListItem key={group.id}>
                    {group.data.displayName}
                  </ListItem>
                ))}
              </List>
            )
            : t('uam_user_no_groups')
          }
        </Tooltip>
      </Td>
      <Td>
        <Icon name="forward" size="sm" />
      </Td>
    </Tr>
  );
};

const Users = (props) => {
  const {searchTerm} = props;

  const users = useSelector(usersSelector);

  const filteredUsers = searchTerm && searchTerm.length ? users.filter((user) => user.data.profile.name.toLowerCase().includes(searchTerm.toLowerCase())) : users;

  const sortedUsers = sort(filteredUsers, {
    type: 'index',
    list: ['Admin', 'User'],
    valueSelector: (user) => user.data.resourceAccess.accessLevel,
  });

  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(sortedUsers.length / pageSize);

  const pagingStartIndex = currentPage * pageSize - pageSize;
  const pagingEndingIndex = currentPage * pageSize;

  const paginated = sortedUsers.slice(pagingStartIndex, pagingEndingIndex);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <React.Fragment>
      <Table css="margin-bottom: 24px">
        <Thead>
          <Tr>
            <Th>{t('first_name')}</Th>
            <Th>{t('last_name')}</Th>
            <Th>{t('access_level')}</Th>
            <Th>{t('groups')}</Th>
            <Th style={{width: '40px'}}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginated.map((user) => (
            <UserRow key={user.id} user={user} />
          ))}
        </Tbody>
      </Table>
      <CustomPagination
        totalItems={sortedUsers.length}
        currentPageItems={paginated.length}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        renderShowingString={(currentPageCount, totalCount) => (
          t('access_management_user_list_paging', {currentPageCount, totalCount})
        )}
      />
    </React.Fragment>
  );
};

export default Users;
