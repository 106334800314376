import path from 'path';

import URLS from 'core/constants/urls';

export default {
  BASE: new URL(URLS.SIGNIAPP_URL)?.pathname || '/',
  ROOT: '/',
  MODULES: '/',
  LOGIN: '/login',
  LOGIN_POPUP: '/login/popup',
  LOGIN_REDIRECT: '', // Leave empty to match allowed URL which does not include trailing slash
  AUTH_RENEW: '/auth/renew',
  LOGOUT: '/logout',
  LOGOUT_REDIRECT: '/logout/redirect',
  POST_LOGOUT: '/logout',
  USER_PROFILE: '/profile',
  USER_SETTINGS: '/settings',
  APP_SETTINGS: '/settings',
  INVITATION_HANDLING: '/i/:invitationToken',
  USER_ACCESS_MANAGEMENT: '/settings/uam',
  UPGRADE: {
    MATCH: path.join('/upgrade/:step?'),
    PLANS: 'plans',
    CONFIRM: 'confirm',
    DETAILS: 'details',
    CONTACT: 'contact',
    EIDSELECTION: 'eidSelection',
  },
};
