import React, {Fragment} from 'react';
import {Switch} from 'react-router-dom'

import Root from 'core/routes/Root.js';
import Settings from 'core/routes/settings/Settings.js';
import Invitation from 'core/routes/invitation/Invitation.js';
import Profile from 'core/routes/Profile.js';
import Account from 'core/routes/Account.js';
import Login from 'core/routes/Login.js';
import Logout from 'core/routes/Logout.js';
import UserAccessManagement from 'core/routes/app/uam/UserAccessManagement';
import Route from 'core/components/Route.js';
import PrivateRoute from 'core/components/PrivateRoute.js';
import {ROUTES} from 'core/constants';
import Error404 from 'core/routes/Error404.js';
import {Upgrade} from 'core/routes';
const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={ROUTES.ROOT}>
          <Root />
        </Route>
        <Route exact path={ROUTES.LOGIN}>
          <Login />
        </Route>
        <Route exact path={ROUTES.LOGOUT}>
          <Logout />
        </Route>
        <Route path={ROUTES.UPGRADE.MATCH}>
          <Upgrade />
        </Route>
        <Route path={ROUTES.INVITATION_HANDLING}>
          <Invitation />
        </Route>
        <PrivateRoute exact path={ROUTES.USER_PROFILE}>
          <Profile />
        </PrivateRoute>
        <PrivateRoute exact path={ROUTES.USER_SETTINGS}>
          <Settings />
        </PrivateRoute>
        <PrivateRoute exact path={ROUTES.APP_SETTINGS}>
          <Account />
        </PrivateRoute>
        <PrivateRoute path={ROUTES.USER_ACCESS_MANAGEMENT}>
          <UserAccessManagement />
        </PrivateRoute>
        <Route>
          <Error404 />
        </Route>
      </Switch>
    </Fragment>
  )
};

export default Routes;
