import dotProp from 'dot-prop-immutable';

const defaultState = {
  requests: [],
  settings: {
    i18n: {
      language: 'en',
    },
    microsoftOpenId: {
      error: null,
      settingsSet: {}
    }
  },
  errors: [],
  translationMissing: false,
  features: [],
  invitations: {
    isLoaded: false,
    isLoading: true,
  },
  protected: {},
  revokedInvitations: {},
  isOnline: true,
  logo: null,
  backdrop: null,
  billingInfo: null,
  currency: 'NOK'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'DEPENDENCY_API_REQUEST':
      return {
        ...state,
        requests: [
          ...state.requests,
          {
            id: action.requestId,
            state: 'request',
          },
        ],
      };
    case 'DEPENDENCY_API_SUCCESS':
      return {
        ...state,
        requests: [
          ...state.requests.map((r) => ({
            ...r,
            state: r.id === action.requestId ? 'success' : r.state,
          })),
        ],
      };
    case 'DEPENDENCY_API_FAILURE':
      return {
        ...state,
        requests: [
          ...state.requests.map((r) => ({
            ...r,
            state: r.id === action.requestId ? 'failure' : r.state,
          })),
        ],
      };
    case 'ADD_ERROR':
      return {
        ...state,
        errors: [
          ...state.errors,
        ],
      };
    case 'SET_GEOLOCATION':
      return dotProp.set(state, 'geolocation', action.geolocation);
    case 'SET_DICTIONARY':
      return dotProp.set(
        state,
        `dictionaries.${action.language}`,
        action.dictionary
      );

    case 'GET_INVITATIONS_SUCCESS':
      return {
        ...state,
        invitations: {
          isLoaded: true,
          isLoading: false,
          items: action.invitations,
        },
      };

    case 'GET_INVITATIONS_FAILURE':
      return {
        ...state,
        invitations: {
          isLoaded: true,
          isLoading: false,
          error: {
            message: 'error_message_app_get_invitations_failed',
          },
        },
      };

    case 'INVITATION_REVOKE_FAILURE':
      return {
        ...state,
        revokedInvitations: {
          ...state.revokedInvitations,
          [action.invitationId]: action.body,
        },
      };

    case 'INVITATION_REVOKE_SUCCESS':
      return {
        ...state,
        revokedInvitations: {
          ...state.revokedInvitations,
          [action.invitationId]: action.body,
        },
      };

    case 'INVITATION_REVOKE_HANDLED':
      return {
        ...state,
        revokedInvitations: {
          ...state.revokedInvitations,
          [action.invitationId]: action.body,
        },
      };

    case 'GET_USERS_REQUEST':
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };

    case 'GET_USERS_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          isLoaded: true,
          isLoading: false,
          items: action.users.data.reduce((acc, curr) => {
            return Object.assign(acc, {
              [curr.userId]: {
                data: {...curr},
                id: curr.userId,
              },
            });
          }, {}),
        },
      };
    case 'GET_USERS_FAILURE':
      return {
        ...state,
        users: {
          isLoaded: true,
          isLoading: false,
          error: {
            message: 'error_message_app_get_users_failed',
          },
        },
      };
    case 'UPDATE_THEME':
      return {
        ...state,
        logo: action.theme.PortalLogo,
        backdrop: action.theme.PortalBackdrop
      }
    case 'SET_APP':
      return {
        ...state,
        modules: action.app.modules,
        trial: action.app.trial,
        settings: {
          ...state.settings,
          i18n: {
            language: action.app.language ? action.app.language : "en"
          }
        },
        logo: action.app.theme.PortalLogo,
        backdrop: action.app.theme.PortalBackdrop
      };
    case 'SET_APP_PROTECTED':
      return {
        ...state,
        protected: action.protected
      };
    case 'APP_SET_SETTINGS':
      return {
        ...state,
        settings: {...action.settings},
      };
    case 'APP_SET_LANGUAGE':
      return dotProp.set(state, 'settings.i18n.language', action.language ? action.language : "en");
    case 'ADD_USER_TO_GROUP':
      return dotProp.merge(
        state,
        `users.items.${action.userId}.data.resourceAccess.groups`,
        [action.groupId]
      );
    case 'ADD_USER_TO_GROUP_REQUEST':
      return {
        ...state,
        users: {
          ...state.users,
          items: {
            ...state.users.items,
            [action.userId]: {
              ...state.users.items[action.userId],
              isSaving: true,
            },
          },
        },
      };
    case 'ADD_USER_TO_GROUP_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          items: {
            ...state.users.items,
            [action.userId]: {
              ...state.users.items[action.userId],
              isSaving: false,
            },
          },
        },
      };
    case 'REMOVE_USER_FROM_GROUP_SUCCESS':
      return dotProp.set(
        state,
        `users.items.${action.userId}.data.resourceAccess.groups`,
        state.users.items[action.userId].data.resourceAccess.groups.filter(
          (group) => (group !== action.groupId)
        )
      );
    case 'GET_APP_REQUEST':
      return dotProp.set(
        state,
        'state.groups',
        {
          ...state.groups,
          isLoaded: false,
          isLoading: true,
        }
      );
    case 'GET_APP_SUCCESS':
      return {
        ...state,
        id: action.app.appId,
        profile: {
          ...action.app.profile,
          maxUsers: action.maxUsers || null,
        },
        groups: {
          ...state.groups,
          isLoading: false,
          isLoaded: true,
          items: action.app.security.groups.reduce((acc, curr) => {
            return Object.assign(acc, {
              [curr.group]: {
                id: curr.group,
                isCreated: true,
                isDeleted: false,
                data: {...curr},
              }
            });
          }, {}),
        },
        security: {
          accessLevels: action.app.security.accessLevels
        }
      };
    case 'ADD_GROUP':
      return {
        ...state,
        groups: {
          ...state.groups,
          items: {
            ...state.groups.items,
            [action.group.id]: {...action.group},
          },
        },
      };
    case 'SET_GROUP_NAME':
      return dotProp.set(
        state,
        `groups.items.${action.groupId}.data.displayName`,
        action.name
      );
    case 'SET_GROUP_ADMINS':
      return dotProp.set(
        state,
        `groups.items.${action.groupId}.data.admins`,
        action.admins.map((item) => ({type: 'User', id: item}))
      );
    case 'GROUP_EDIT_INIT':
      return dotProp.set(
        state,
        `groups.items.${action.groupId}.form`,
        state.groups.items[action.groupId].data
      );
    case 'GROUP_CREATE_SUCCESS':
      return {
        ...state,
        groups: {
          ...state.groups,
          items: {
            ...state.groups.items,
            [action.id]: {
              ...state.groups.items[action.id],
              isCreated: true,
              data: {
                ...state.groups.items[action.id].data,
                ...action.group,
              },
            },
          },
        },
      };
    case 'GROUP_CREATE_UNMOUNT':
      const group = Object.values(state.groups.items).find(
        (group) => (group.isCreating)
      );

      if (group.isCreated) {
        return dotProp.set(
          state, `groups.items.${group.id}.isCreating`, false
        );
      } else {
        return dotProp.delete(
          state, `groups.items.${group.id}`
        );
      }
    case 'GROUP_DELETE_SUCCESS':
      return dotProp.set(
        state,
        `groups.items.${action.groupId}.isDeleted`,
        true
      );
    case 'USER_EDIT_INIT':
      return dotProp.set(
        state,
        `users.items.${action.userId}.form`,
        state.users.items[action.userId].data
      );
    case 'USER_EDIT_CANCEL':
      return dotProp.delete(state, `users.items.${action.userId}.form`);
    case 'USER_SET_ACCESS_LEVEL':
      return dotProp.set(
        state,
        `users.items.${action.userId}.form.resourceAccess.accessLevel`,
        action.accessLevel
      );
    case 'USER_FORM_SET_FIELD':
      return dotProp.set(
        state,
        `users.items.${action.userId}.form.${action.field}`,
        action.value
      );
    case 'USER_SAVE_SUCCESS':
      return dotProp.set(
        state,
        `users.items.${action.userId}`,
        {
          ...state.users.items[action.userId],
          data: {
            ...state.users.items[action.userId].data,
            resourceAccess: {...action.permissions},
            profile: {...action.profile},
          },
          form: null,
        }
      );
    case 'USER_DELETE_SUCCESS':
      return dotProp.set(
        state,
        `users.items.${action.userId}.isDeleted`,
        true
      );
    case 'UPDATE_TRANSLATION_MISSING':
      return {
        ...state,
        translationMissing: action.translationMissing
      };
    case 'SET_ONLINE':
      return {
        ...state,
        isOnline: action.isOnline
      };
    case 'GET_SETTINGS_SET_SUCCESS':
      return {
        ...state,
        settings: {
          ...state.settings,
          microsoftOpenId: {
            error: null,
            settingsSet: action.settingsSet
          }
        }
      };
    case 'GET_SETTINGS_SET_FAILURE':
      return {
        ...state,
        settings: {
          ...state.settings.i18n,
          microsoftOpenId: {
            error: action.error,
            ...state.settings.microsoftOpenId.settingsSet
          }
        }
      };

    case 'GET_ADVANCED_CORE_SETTINGS_SET_SUCCESS':
      return {
        ...state,
        settings: {
          ...state.settings,
          coreAdvanced: {
            error: null,
            settingsSet: action.settingsSet
          }
        }
      };
    case 'GET_ADVANCED_CORE_SETTINGS_SET_FAILURE':
      return {
        ...state,
        settings: {
          ...state.settings.i18n,
          coreAdvanced: {
            error: action.error,
            ...state.settings.coreAdvanced.settingsSet
          }
        }
      };

    case 'GET_BILLING_INFO':
      return {
        ...state,
        billingInfo: action.billingInfo
      }

    case 'SET_CURRENCY':
      return {...state, currency: action.currency}

    default:
      return state;
  }
};
