import React, {Fragment, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import path from 'path';

import {Button} from '@signicat/designi/components/buttons';
import {Icon} from '@signicat/designi/components/icons';
import {Flex, Header as DesigniHeader} from '@signicat/designi/components/layout';
import {Heading} from '@signicat/designi/components/text';
import {Popover} from '@signicat/designi/components/overlays';
import {Signicat} from '@signicat/designi/assets/logos';
import {media} from '@signicat/designi/helpers/responsive';

import ModulesContext from 'core/ModulesContext.js';
import {ROUTES} from 'core/constants';
import {accessLevelSelector, isGroupAdminSelector} from 'core/store/selectors/session';
import {session as sessionActions} from 'core/store/actions';
import {languageSelector} from 'core/store/selectors/app';

import {t} from 'core/helpers/i18n';

const StyledLogoImg = styled('img')`
  height: 40px;
  max-width: 128px;
`;

const StyledHeader = styled(DesigniHeader)``;

// TODO: Use color variable
const MenuGroup = styled('div')`
  border-bottom: 2px solid #565656;
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const MenuItem = styled('div')`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const UserName = styled('div')`
  ${media.css.mobile.and('down')`
    display: none;
  `}

  margin-left: 12px;
`;

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const {access} = session;
  const {hideLogo, hideUser, hideMenu, renderStart, renderCenter, renderEnd} = props;
  const isGroupAdmin = useSelector(isGroupAdminSelector);
  const accessLevel = useSelector(accessLevelSelector);
  const language = useSelector(languageSelector);
  const logo = useSelector((state) => state.app.logo);
  const isPortalAdmin = accessLevel === 'Admin';
  const Modules = useContext(ModulesContext);

  const [moduleMenus, setModuleMenus] = useState([]);

  const configureModuleMenus = async () => {
    let moduleMenuItems = [];
    for (const module of Object.values(Modules)) {
      const moduleMenu = await module.getMenu(access, language);
      const items = moduleMenu.map((item) => ({
        id: item.pathSegment,
        href: path.join(ROUTES.MODULES, module.meta.pathSegment, item.pathSegment),
        text: item.text,
      }));
      const moduleMenuItem = {
        id: `module-${module.id}`,
        moduleId: module.id,
        type: 'group',
        items,
      };
      moduleMenuItems.push(moduleMenuItem);
    }
    setModuleMenus(moduleMenuItems);
  };

  useEffect(() => {
    let isMounted = true;
    if (access && isMounted) {
      configureModuleMenus();
    }
    return () => {
      isMounted = false;
    };
  }, [access]);

  const menuItems = [
    ...moduleMenus.sort((a, b) => (a.moduleId == 'signature' ? -1 : b.moduleId == 'signature' ? 1 : 0)),
    session && session.isAuthorized
      ? {
          id: 'settings',
          href: ROUTES.APP_SETTINGS,
          text: t('menu_item_app_settings'),
        }
      : null,
    (session && session.isAuthorized && isPortalAdmin) || isGroupAdmin
      ? {
          id: 'user_access_management',
          href: ROUTES.USER_ACCESS_MANAGEMENT,
          text: t('menu_item_user_access_management'),
        }
      : null,
    session && session.isAuthenticated
      ? {
          id: 'logout',
          href: '/logout',
          text: t('menu_item_logout'),
          action: () => dispatch(sessionActions.logout()),
        }
      : {id: 'login', href: ROUTES.LOGIN, text: t('menu_item_login')},
  ].filter((item) => Boolean(item) && (item.type === 'group' ? item.items && item.items.length > 0 : true));

  const handleLogoError = (e) => {
    e.target.src = 'https://static.signicat.app/img/Signicat_logo_positive_RGB.svg';
  };

  return (
    <StyledHeader>
      <Flex alignItems="center">
        <Flex flex="1 1 0px" textAlign="left" alignItems="center" justifyContent="flex-start">
          {hideLogo ? null : (
            <Link to="/">
              <StyledLogoImg
                onError={handleLogoError}
                src={logo || 'https://static.signicat.app/img/Signicat_logo_positive_RGB.svg'}
              />
            </Link>
          )}
          {typeof renderStart === 'function' ? renderStart() : null}
        </Flex>
        <Flex flex="1 1 0px" textAlign="center" alignItems="center" justifyContent="center">
          {typeof renderCenter === 'function' ? renderCenter() : null}
        </Flex>
        <Flex flex="1 1 0px" textAlign="right" alignItems="center" justifyContent="flex-end">
          {typeof renderEnd === 'function' ? renderEnd() : null}
          {hideUser === false && session && session.user?.profile ? (
            <div>
              <Button
                size="sm"
                variant="transparent"
                color="secondary"
                onClick={() => {
                  history.push('/settings');
                }}
              >
                <Icon name="user" />
                <UserName>{session.user.profile.name}</UserName>
              </Button>
            </div>
          ) : null}
          {hideMenu === true ? null : (
            <Popover
              anchor={
                <Button size="sm" variant="transparent" color="secondary">
                  <Icon name="menu" />
                </Button>
              }
            >
              <Fragment>
                {menuItems.map((item) => {
                  if (item.type === 'group') {
                    return (
                      <MenuGroup key={`menu-group-${item.id}`}>
                        {item.items.map((i) => (
                          <MenuItem key={`menu-item-${i.id}`} onClick={item.action}>
                            <Link to={i.href}>{i.text}</Link>
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    );
                  }

                  return (
                    <MenuItem key={item.id} onClick={item.action}>
                      <Link to={item.href}>{item.text}</Link>
                    </MenuItem>
                  );
                })}
              </Fragment>
            </Popover>
          )}
        </Flex>
      </Flex>
    </StyledHeader>
  );
};

Header.propTypes = {
  hideLogo: PropTypes.bool,
  hideMenu: PropTypes.bool,
  hideUser: PropTypes.bool,
  renderStart: PropTypes.func,
  renderCenter: PropTypes.func,
  renderEnd: PropTypes.func,
};

Header.defaultProps = {
  hideLogo: false,
  hideMenu: false,
  hideUser: false,
};

export default Header;
