import React from 'react';
import styled from 'styled-components';
import {useLocation, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Flex} from '@signicat/designi/components/layout';
import {Button} from '@signicat/designi/components/buttons';
import {HelpText} from '@signicat/designi/components/text';
import {Anchor} from '@signicat/designi/components/text';
import {session as sessionHelper} from '@signiapp/helpers';

import {t} from 'core/helpers/i18n';
import {session as sessionActions} from 'core/store/actions';
import {URLS, ROUTES} from 'core/constants';
import DokobitBanner from 'core/components/DokobitBanner.js';

const LoginContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const BottomContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledHelpTextContainer = styled('div')`
  max-width: 320px;
  color: #717171;
  text-align: center;
  border-top: 2px solid #f6f6f6;
  margin-top: 36px;
  padding-top: 16px;
`;

const StyledHelpText = styled(HelpText)`
  font-weight: 400;
  font-size: 16px;
`;

const StyledLogo = styled('img')`
  max-height: 48px;
`;

const LogoText = styled('span')`
  color: #2a0062;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
`;

// eslint-disable-next-line
export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search.slice(1));
  const isURL = (str) => {
    const a = document.createElement('a');
    a.href = str;
    return a.host && a.host != window.location.host;
  };
  const redirectUri = params.get('redirectUri');
  const logo = useSelector((state) => state.app.logo);
  const language = useSelector((state) => state.app.settings.i18n.language);
  const loginOptions = {ui_locales: language || 'en', redirectUri: isURL(redirectUri) ? null : redirectUri};

  if (sessionHelper.isAuthenticated) {
    history.replace(ROUTES.ROOT);
  }

  return (
    <LoginContainer>
      <Flex alignSelf="center" alignItems="center" justifyContent="center" direction="column" style={{flexGrow: 1}}>
        <DokobitBanner></DokobitBanner>
        <Button
          primary
          size="lg"
          type="button"
          onClick={() => {
            dispatch(sessionActions.login(loginOptions));
          }}
          style={{fontSize: '20px'}}
        >
          {t('button_text_login')}
        </Button>
        <StyledHelpTextContainer>
          <StyledHelpText>{t('login_helptext_flow')}</StyledHelpText>
          <StyledHelpText>
            {t('login_helptext_issues', {
              supportEmail: URLS.SUPPORT_EMAIL,
            })}
          </StyledHelpText>
        </StyledHelpTextContainer>
      </Flex>
      {logo && (
        <BottomContainer>
          <LogoText>{t('login_poweredby')}</LogoText>
          <StyledLogo src="https://static.signicat.app/img/Signicat_logo_positive_RGB.svg" />
        </BottomContainer>
      )}
    </LoginContainer>
  );
};
