import {access as accessService} from 'core/services';
import {getApp, getUsers} from 'core/store/actions/app';

import sessionActions from './session.js';

const getInvitationByToken = (invitationToken) => (dispatch) => {
  dispatch({type: 'INVITATION_GET_TOKEN_REQUEST'});

  accessService
    .getInvitationByToken(invitationToken)
    .then((invitation) => {
      dispatch({
        type: 'INVITATION_GET_TOKEN_SUCCESS',
        invitation: invitation,
      });
      dispatch({
        type: 'USER_SET_LANGUAGE',
        language: invitation.invitationLanguage
      })
    })
    .catch((err) => {
      dispatch({
        type: 'INVITATION_GET_TOKEN_FAILURE',
        invitation: null,
      });
    });
};

const acceptInvitation = (invitationToken) => async (dispatch, getState) => {
  const state = getState();
  const {session} = state;
  const {accessToken} = session.tokens;
  const payloadEncoded = accessToken.split('.')[1];
  const payload = JSON.parse(atob(payloadEncoded));

  if (typeof payload.user_id !== 'string') {
    throw new Error('ID_HAS_NO_USER');
  }

  return accessService
    .acceptInvitation(invitationToken)
    .then(() => dispatch(sessionActions.silentRenew()))
    .then(() => dispatch(sessionActions.checkUserAndAccess()))
    // TODO: Implement some sort of session refresh action?
    .then(() => Promise.all([
      dispatch(getApp()),
      dispatch(getUsers()),
    ]))
    .then(() => dispatch({type: 'SESSION_LOAD_SUCCESS'}))
    .catch((err) => {
      console.error(err);

      switch (err.message) {
        case 'ID_HAS_NO_USER':
        case 'INVALID_IDP':
        case 'LOGIN_ABORTED':
        case 'SILENT_RENEW_ERROR':
        case 'USER_ALREADY_HAS_ACCESS':
        case 'APP_MAX_USERS_REACHED':
          throw err;
        default:
          console.error(err);
          break;
      }
    });
};

const declineInvitation = (invitationToken) => (dispatch) => (
  accessService.declineInvitation(invitationToken)
);

export default {
  getInvitationByToken,
  acceptInvitation,
  declineInvitation,
};
