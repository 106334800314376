/* eslint-disable no-underscore-dangle */

class EventEmitter {
  constructor() {
    this._events = {};
  }

  on(name, listener) {
    this._events[name] = [...(this._events[name] || []), listener];
  }

  off(name, listenerToRemove) {
    if (! this._events[name]) {
      console.warn(`Tried to remove an event listener ${name} that was never added.`)
    } else {
      const filterListeners = (listener) => listener !== listenerToRemove;

      this._events[name] = this._events[name].filter(filterListeners);
    }
  }

  emit(name, data) {
    if (this._events[name]) {
      const fireCallbacks = (callback) => {
        callback(data);
      };

      this._events[name].forEach(fireCallbacks);
    }
  }
}

export default EventEmitter;
