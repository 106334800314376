import {Table, Tbody, Td, Th, Thead, Tr} from '@signicat/designi/components/tables';
import React, {useState, useEffect} from 'react';
import {Column, Flex, Row} from '@signicat/designi/components/layout';
import {CustomPagination} from '@signicat/designi/components/pagination';
import {useSelector, useDispatch} from 'react-redux';
import {app as appSelectors} from 'core/store/selectors';
import {getInvitations, revokeInvitation, revokeInvitationHandled} from 'core/store/actions/app';
import {sort} from '@signiapp/helpers';
import {Spinner} from '@signicat/designi/components/loaders';
import {t} from 'core/helpers/i18n';
import {format} from 'date-fns';
import {Message, useNotification} from '@signicat/designi/components/messages';
import {Heading} from '@signicat/designi/components/text';
import {Button} from '@signicat/designi/components/buttons';
import {Tooltip} from '@signicat/designi/components/overlays';
import {useConfirmation} from '@signicat/designi/components/overlays/ConfirmationService';

const getStatus = (invitation) => {
  if (invitation.accepted)
    return 'accepted';
  if (invitation.revoked)
    return 'revoked';
  if (invitation.declined)
    return 'declined';
  if (invitation.expires && new Date(invitation.expires) < new Date())
    return 'expired';

  return 'pending';
};

const reloadPage = () => {
  window.location.reload();
};

const Invitations = (props) => {
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const {enqueueNotification} = useNotification();

  useEffect(() => {
    dispatch(getInvitations())
  }, []);
  
  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const {searchTerm} = props;
  const invitations = useSelector(appSelectors.invitationsSelector);
  const revokedInvitations = useSelector(appSelectors.revokedInvitationsSelector);

  const revokeInvitationById = async (invitationId) => {
    await confirm({
      title: t('uam_user_delete_prompt_title'),
      message: t('uam_user_revoke_invitation_prompt'),
      variant: 'confirm',
      buttonConfirm: t('button_text_yes'),
      buttonCancel: t('button_text_no'),
    });

    dispatch(revokeInvitation(invitationId));
  };

  const InvitationRow = (props) => {

    const {invitation} = props;

    let status = getStatus(invitation);

    const attemptedRevoke = Object.values(revokedInvitations).find((key) => key.id === invitation.invitationId);

    if (attemptedRevoke && attemptedRevoke.status === 'success') {
      status = 'revoked';
    }

    return (
      <Tr
        key={invitation.invitationId}
        tabIndex="0"
      >
        <Td>
          {invitation.receiverEmail || ''}
        </Td>
        <Td>
          {invitation.receiverMobile || ''}
        </Td>
        <Td>
          {t(`access_level_${invitation.grantedAccess.accessLevel}`.toLowerCase())}
        </Td>
        <Td>
          {t(`invitation_status_${status}`.toLowerCase())}
        </Td>
        <Td>
          {status === 'pending' ? (
            <Button size={'xs'} color={'negative'} onClick={() => revokeInvitationById(invitation.invitationId)}>
              {t('access_management_revoke_invitation')}
            </Button>
          ) : (
          /*<Tooltip
                  anchor={<span tabIndex="0">{t('core_tooltip_invitation_revoke')}</span>}
                  arrow
                >*/
            <Button size={'xs'} color={'negative'} disabled={true}>
              {t('access_management_revoke_invitation')}
            </Button>)}
        </Td>
        <Td>
          {format(new Date(invitation.created), 'dd.MM.yyyy HH:mm')}
        </Td>
      </Tr>
    );
  };
  
  if (invitations.isLoading) {
    return <Spinner fullscreen />;
  }
  
  if (invitations.error?.message) {
    return (
      <Row>
        <Column>
          <Row style={{marginBottom: '24px'}}>
            <Column vertical="center">
              <Heading
                level={3}
                style={{marginBottom: 0}}
                marginBottom="0"
              >
                {t('uam_title')}
              </Heading>
            </Column>
          </Row>
          <Message variant="error" icon="error">
            <Flex flex="1 0 auto">
              {t(invitations.error.message)}
            </Flex>
            <Flex flex="0 0 auto">
              <Button color="negative" size="sm" onClick={reloadPage}>
                {t('core_button_refresh_page')}
              </Button>
            </Flex>
          </Message>
        </Column>
      </Row>
    );
  }

  const filteredInvitations = searchTerm && searchTerm.length ? invitations.items.data.filter((invitation) => `${invitation.receiverEmail} ${invitation.receiverMobile} ${t(`access_level_${invitation.grantedAccess.accessLevel.toLowerCase()}`)} ${t(`invitation_status_${getStatus(invitation)}`.toLowerCase())}`.toLowerCase().includes(searchTerm.toLowerCase())) : invitations.items.data;

  const sortedInvitations = sort(filteredInvitations, {
    valueSelector: (filteredInvitation) => filteredInvitation.created,
    type: 'date',
    direction: 'desc',
  });

  const totalPages = Math.ceil(sortedInvitations.length / pageSize);

  const pagingStartIndex = currentPage * pageSize - pageSize;
  const pagingEndingIndex = currentPage * pageSize;

  const paginated = sortedInvitations.slice(pagingStartIndex, pagingEndingIndex);

  return (
    <Flex direction="column" width="100%">
      <Table css="margin-bottom: 24px">
        <Thead>
          <Tr>
            <Th>{t('invitation_column_heading_email')}</Th>
            <Th>{t('invitation_column_heading_mobile_number')}</Th>
            <Th>{t('invitation_column_heading_access_level')}</Th>
            <Th>{t('invitation_column_heading_status')}</Th>
            <Th>{t('invitation_column_heading_actions')}</Th>
            <Th>{t('invitation_column_heading_created')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginated.map((invitation) => (
            <InvitationRow key={invitation.invitationId} invitation={invitation} />
          ))}
        </Tbody>
      </Table>
      <CustomPagination
        totalItems={sortedInvitations.length}
        currentPageItems={paginated.length}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        renderShowingString={(currentPageCount, totalCount) => (
          t('access_management_invitation_list_paging', {currentPageCount, totalCount})
        )}
      />
    </Flex>
  );
  
};

export default Invitations;
