import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import path from 'path';

import {Segment} from '@signicat/designi/components/containers';
import {TextField, Autocomplete} from '@signicat/designi/components/inputs';
import {Form, Field} from '@signicat/designi/components/forms';
import {Button} from '@signicat/designi/components/buttons';
import {
  Flex,
  Grid,
  Row,
  Column,
  Div,
} from '@signicat/designi/components/layout';
import {Heading, Paragraph} from '@signicat/designi/components/text';
import {Empty} from '@signicat/designi/components/containers';

import {app} from 'core/store/actions';
import {appSelectors} from 'core/store/selectors';
import {groupValidator} from 'core/store/validators/app';
import {ROUTES} from 'core/constants';
import {t} from 'core/helpers/i18n';

import {Titlebar} from './UserAccessManagement.js';

const GroupCreate = (props) => {
  const {featureEnabled} = props;
  if (!featureEnabled) return null;
  const dispatch = useDispatch();
  const history = useHistory();
  const initedGroups = useSelector(appSelectors.initedGroupsSelector);
  const [validateAll, setValidateAll] = useState(false);
  useEffect(() => {
    if (!initedGroups?.length) {
      dispatch(app.initGroup());
    }

    return () => {
      dispatch({type: 'GROUP_CREATE_UNMOUNT'});
    };
  }, []);

  useEffect(() => {
    if (!initedGroups?.length) dispatch(app.initGroup());
  }, [initedGroups]);

  const users = useSelector(appSelectors.usersSelector);

  if (!initedGroups || initedGroups.length === 0) {
    return null;
  }

  const group = initedGroups[0];
  const validation = groupValidator(group.data);

  const handleNameChange = (e) => {
    dispatch({
      type: 'SET_GROUP_NAME',
      groupId: group.id,
      name: e.target.value,
    });
  };

  const handleAdminsChange = (e, value) => {
    dispatch({
      type: 'SET_GROUP_ADMINS',
      groupId: group.id,
      admins: value,
    });
  };

  const adminsOptions = users.map((user) => ({
    value: user.id,
    text: user.data.profile.name,
  }));

  const adminsValue = (group.data.admins || [])
    .filter((item) => item.type === 'User')
    .map((item) => item.id);

  const handleCreateClick = () => {
    if (Object.entries(validation).length > 0) {
      setValidateAll(true);
      return;
    }

    dispatch(app.createGroup(group.id));
  };

  const handleCancelClick = () => {
    history.push(ROUTES.USER_ACCESS_MANAGEMENT);
  };

  const isComplete = group.isCreating && group.isCreated;

  if (isComplete) {
    return (
      <Empty
        emoji="🎉"
        heading={t('uam_group_create_success_heading')}
        actionComponent={
          <Button
            color="primary"
            forwardedAs={Link}
            to={path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'groups', group.id)}
          >
            {t('uam_group_create_success_action')}
          </Button>
        }
      />
    );
  }

  return (
    <Div>
      <Titlebar>
        <Flex>
          <Button
            variant="transparent"
            color="secondary"
            icon="back"
            forwardedAs={Link}
            to={ROUTES.USER_ACCESS_MANAGEMENT}
          >
            {t('uam_link_overview')}
          </Button>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Heading level={4} style={{paddingBottom: 0, marginBottom: 0}}>
            {t('uam_group_create_heading')}
          </Heading>
        </Flex>
        <Flex></Flex>
      </Titlebar>
      <Segment outlined space="xl">
        <Form validation={validation} state={{validateAll: validateAll}}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Paragraph style={{marginBottom: '24px'}}>
                    {t('uam_group_create_form_helptext')}
                  </Paragraph>
                </Grid>
                <Grid item>
                  <Field
                    helpText={t('uam_group_create_field_name_helptext')}
                    name="name"
                  >
                    <TextField
                      label={t('uam_group_create_field_name_label')}
                      onChange={handleNameChange}
                      value={group.data.displayName || ''}
                    />
                  </Field>
                </Grid>
                <Grid item>
                  <Field
                    helpText={t('uam_group_create_field_groupadmins_helptext')}
                  >
                    <Autocomplete
                      multiple
                      options={adminsOptions}
                      value={adminsValue}
                      label={t('uam_group_create_field_groupadmins_label')}
                      onChange={handleAdminsChange}
                      noOptionsText={t('uam_group_addadmin_input_no_options')}
                    />
                  </Field>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={handleCancelClick}
                      >
                        {t('button_text_cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button color="primary" onClick={handleCreateClick}>
                        {t('uam_group_create_action_submit_buttontext')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Segment>
    </Div>
  );
};

export default GroupCreate;
