// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./mabry-regular-pro.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./mabry-medium-pro.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./mabry-italic-pro.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./mabry-bold-pro.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Mabry Pro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff');\n  font-style: normal;\n  font-weight: 400;\n}\n@font-face {\n  font-family: 'Mabry Pro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff');\n  font-style: normal;\n  font-weight: 500;\n}\n@font-face {\n  font-family: 'Mabry Pro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff');\n  font-style: italic;\n  font-weight: 400;\n}\n@font-face {\n  font-family: 'Mabry Pro';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff');\n  font-style: normal;\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/fonts/index.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,2DAAmD;EACnD,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,2DAAkD;EAClD,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,2DAAkD;EAClD,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,2DAAgD;EAChD,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'Mabry Pro';\n  src: url('./mabry-regular-pro.woff') format('woff');\n  font-style: normal;\n  font-weight: 400;\n}\n@font-face {\n  font-family: 'Mabry Pro';\n  src: url('./mabry-medium-pro.woff') format('woff');\n  font-style: normal;\n  font-weight: 500;\n}\n@font-face {\n  font-family: 'Mabry Pro';\n  src: url('./mabry-italic-pro.woff') format('woff');\n  font-style: italic;\n  font-weight: 400;\n}\n@font-face {\n  font-family: 'Mabry Pro';\n  src: url('./mabry-bold-pro.woff') format('woff');\n  font-style: normal;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
