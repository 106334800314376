import CustomErrorInstance from 'custom-error-instance';

import SigniappError from './SigniappError.js';

const ApiError = CustomErrorInstance('ApiError', SigniappError, {
  message: 'An ApiError occurred in Signiapp.',
  code: 'SGN:2.500',
  statusCode: 500,
  body: null,
});

export default ApiError;
