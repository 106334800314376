import React, {Fragment, useEffect, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {CSSTransition} from 'react-transition-group';
import {Flex} from '@signicat/designi/components/layout';
import {t} from 'core/helpers/i18n';

const GlobalStyle = createGlobalStyle`
  body {
    transition: margin 500ms;
    margin-top: 48px;
  }
  #signiapp{
    min-height: calc(100vh - 48px);
  }
`;

const StyledDokobitInfoBanner = styled(Flex)`
  padding: 12px 24px;
  position: fixed;
  z-index: 1200;
  top: -48px;
  left: 0;
  right: 0;
  min-height: 48px;
  flex-direction: column;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: #2A0062;
  color: #fff;

  &.trial-banner-enter {
    top: -48px;
  }

  &.trial-banner-enter-active {
    top: 0px;
    transition: top 400ms;
  }

  &.trial-banner-enter-done {
    top: 0px;
  }

  &.trial-banner-leave {
    top: -48px;
  }

  &.trial-banner-leave-active {
    transition: top 400ms;
  }
`;

const DokobitInfoBanner = (props) => {
  const {children} = props;
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
    return () => {
      setVisible(false);
    };
  });

  return (
    <CSSTransition
      in={visible}
      classNames="trial-banner"
      timeout={400}
    >
      <StyledDokobitInfoBanner>
        <GlobalStyle />
        {children ? children : (
          <Fragment>
            <Flex>
              <p>
                {t('dokobit_info_banner_text') + ' '}
                <a style={{color: '#fff'}} href='https://www.dokobit.com/features?gad=1&gclid=EAIaIQobChMIhY7rysmc_wIVFKnVCh29vQwTEAAYASADEgJmzPD_BwE' target='_blank' rel="noreferrer">{t('dokobit_portal')}</a>
              </p>
            </Flex>
          </Fragment>
        )}
      </StyledDokobitInfoBanner>
    </CSSTransition>
  );
};

export default DokobitInfoBanner;
