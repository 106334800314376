import React, {Fragment, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import {Flex} from '@signicat/designi/components/layout';
import {media} from '@signicat/designi/helpers/responsive';
import {Button} from '@signicat/designi/components/buttons';
import {useHistory} from 'react-router-dom';
import {planByIdSelector} from 'core/store/selectors/billing';
import EID from './components/EID.js';
import RegionFilters from './components/RegionFilters.js';
import {ROUTES} from 'core/constants';
import {t} from 'core/helpers/i18n';
import UpgradeFlowHeading from './components/Heading.js';

const EIDSelectionContainer = styled(Flex)`
  ${media.css.mobile.and('down')`
    flex-direction: column;
    > ${EID} {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  `}

  ${media.css.tablet.and('up')`
    flex-direction: column;
    > ${EID} {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  `}
`;

const EIDSelection = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const selectedPlan = new URLSearchParams(location.search).get('plan');
    const plan = useSelector(planByIdSelector(selectedPlan));
    const multiselect = plan.id === 'advanced'
    const [selectedEIDs, setSelectedEIDs] = useState([])
    const allEids = useSelector((state) => state.billing.features).filter(eid => eid.moduleId === "core" && eid.tags && eid.tags[0] === "eid" && !eid.tags.find(t => t === "hidden"));
    const [eids, setEids] = useState(allEids)
    const handleOnChange = (e) => {
        if (multiselect) {
            if (selectedEIDs.includes(e)) {
                setSelectedEIDs(selectedEIDs.filter(id => id !== e))
            }
            else if (selectedEIDs.length < 4) {
                setSelectedEIDs([...selectedEIDs, e])
            }
        } else {
            setSelectedEIDs([e])
        }
    };
    const onFilterChange = (e) => {
        if (e === 'all') {
            setEids(allEids)
        }
        else {
            setEids(allEids.filter(eid => {
                const regionsTag = eid.tags.find(t => t.includes("regions:"))
                return regionsTag.includes(e)
            }))
        }
    }
    const handleNextClick = () => { 
        const eids = allEids.filter(eid => selectedEIDs.includes(eid.id))
        dispatch({type: "SET_EIDS", eids})
        history.push(`${ROUTES.UPGRADE.CONFIRM}?plan=${plan.id}`);
    };
    const getEIDOrder = eid => {
        const orderTag = eid.tags.find(t => t.includes("order:"))
        return orderTag
            ? Number(orderTag.split(":")[1])
            : null
    }
    useEffect(() => {
        setSelectedEIDs(selectedEIDs.filter(id => eids.map(eid => eid.id).includes(id)))
    }, [eids])
    return (
        <Fragment>
            <UpgradeFlowHeading>
                {t('upgrade_eidselection_header')}
            </UpgradeFlowHeading>
            <RegionFilters onChange={onFilterChange} />
            <EIDSelectionContainer>{eids.sort((a, b) => (getEIDOrder(a) > getEIDOrder(b)) ? 1 : -1).map((eid) =>
                <EID key={eid.id}
                    eid={eid}
                    highlighted={'primary'}
                    multiselect={multiselect}
                    selectedEIDs={selectedEIDs}
                    onChange={handleOnChange} />
            )}
            </EIDSelectionContainer>
            <Button
                primary
                type="button"
                onClick={handleNextClick}
                style={{width: '250px', marginTop: "30px", alignSelf: "flex-end"}}
                disabled={selectedEIDs.length === 0}
            >
                {t("next_button")}
            </Button>
        </Fragment>
    );
};

export default EIDSelection;