import {useDispatch, useSelector} from 'react-redux';
import React from 'react';
import {Button} from '@signicat/designi/components/buttons';
import {t} from 'core/helpers/i18n';
import {session as sessionActions} from 'core/store/actions';
import {getLoginRegistrationToken, useRegistrationLoginToken} from 'core/store/actions/user';
import {useNotification} from '@signicat/designi/components/messages';
import {getUserLoginMethods} from '../../../store/actions/user';
import {languageSelector} from 'core/store/selectors/app';

const AddLoginMethod = (props) => {
  const dispatch = useDispatch();
  const {enqueueNotification} = useNotification();
  const language = useSelector(languageSelector);

  const alreadyRegisteredErrorNotification = t('login_method_add_already_registered_notification');
  const errorMessageNotification = t('login_method_add_error_notification');

  const AddUserLoginMethod = async () => {
    const regToken = await dispatch(getLoginRegistrationToken());

    dispatch(sessionActions.addOpenIdLoginMethod(language))
      .then(() => {
        dispatch(useRegistrationLoginToken(regToken))
          .then(async () => {
            await dispatch(sessionActions.renewAfterAddedLoginMethod());
            dispatch(getUserLoginMethods(props.userId));
          })
          .catch((err) => {
            if (err.body?.error && err.body.error === 'already_registered') {
              enqueueNotification(alreadyRegisteredErrorNotification, {
                variant: 'error',
              });
            } else {
              enqueueNotification(errorMessageNotification, {
                variant: 'error',
              });
            }
          });
      }).catch((err) => {
        if (err.message !== 'POPUP_CLOSED'){
          console.error(err);
        }
      });
  };

  return (
    <Button
      onClick={() => AddUserLoginMethod()}
      icon={'add'}
      color="primary"
      style={{flexGrow: 1}}
    >
      {t('login_method_button_add')}
    </Button>
  );
};

export default AddLoginMethod;
