const defaultState = {
  users: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'USERS_GET_REQUEST':
      return state;
    case 'USERS_GET_SUCCESS':
      return {
        ...state,
        users: action.users,
      };

    case 'USER_PERMISSION_UPDATE_SUCCESS':
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map((user) => (
            user.userId === action.record.entityId
              ? ({...user, resourceAccess: {...action.record}})
              : user
          )),
        },
      };
    default:
      return state;
  }
};
