import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Switch, Route, useParams} from 'react-router-dom';

import {Message} from '@signicat/designi/components/messages';
import {Spinner} from '@signicat/designi/components/loaders';

import {invitation as invitationActions} from 'core/store/actions';
import {t} from 'core/helpers/i18n';

import Landing from './landing/Landing.js';
import Accept from './accept/Accept.js';
import Decline from './decline/Decline.js';
import Registration from './registration/Registration.js';

const Invitation = () => {
  const dispatch = useDispatch();
  const {invitationToken} = useParams();

  const invitation = useSelector((state) => state.invitation);

  useEffect(() => {
    dispatch(invitationActions.getInvitationByToken(invitationToken));
  }, [invitationToken]);

  return (
    <div>
      {invitation.isRequested === true && invitation.isLoading === false ? (
        (() => {
          if (invitation.error) {
            return (
              <Message>
                {t('invitation_message_invalid_url')}
              </Message>
            );
          }

          if (!invitation.invitationToken?.acceptToken) {
            return (
              <Message>{t('invitation_message_missing_accept_token')}</Message>
            );
          }
          
          if (invitation.invitationToken.handled) {
            return (
              <Message>{t('invitation_message_already_handled')}</Message>
            );
          }

          if (invitation.invitationToken.expired) {
            return (
              <Message>{t('invitation_message_expired')}</Message>
            );
          }

          if (invitation.invitationToken.revoked) {
            return <Message>{t('invitation_message_revoked')}</Message>;
          }

          return (
            <Switch>
              <Route exact path="/i/:invitationToken">
                <Landing />
              </Route>
              <Route exact path="/i/:invitationToken/accept">
                <Accept />
              </Route>
              <Route exact path="/i/:invitationToken/decline">
                <Decline />
              </Route>
              <Route exact path="/i/:invitationToken/register">
                <Registration />
              </Route>
            </Switch>
          );
        })()
      ) : (
        <Spinner fullscreen />
      )}
    </div>
  );
};

export default Invitation;
