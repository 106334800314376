import React, {Fragment, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import path from 'path';

import {Global as GlobalStyle, FocusOutlines} from '@signicat/designi/components/styles';
import {ThemeProvider} from '@signicat/designi/components/themes';
// import {MessagesProvider} from '@signicat/designi/components/messages';
import {Empty} from '@signicat/designi/components/containers';
import {ConfirmationServiceProvider} from '@signicat/designi/components/overlays/ConfirmationService';
import {NotificationProvider} from '@signicat/designi/components/messages';

import SigniappGlobalStyle from 'core/GlobalStyle.js';
import ModulesContext from 'core/ModulesContext.js';
import Main from 'core/components/Main.js';
import Route from 'core/components/Route.js';
import CoreRoutes from 'core/components/Routes.js';
import AsyncModule from 'core/components/AsyncModule.js';
import ErrorHandler from 'core/components/ErrorHandler.js';
import ErrorBoundary from 'core/components/ErrorBoundary.js';
import GoogleAnalytics from 'core/components/GoogleAnalytics';
import {ROUTES} from 'core/constants';
import Core from 'core/Core.js';
import Session from 'core/Session.js';
import {coreSelector} from 'core/store/selectors/core';
import 'core/fonts/index.css';
// import {appSelector} from 'core/store/selectors/app';

const App = (props) => {
  const {Modules} = props;
  const dispatch = useDispatch();
  const core = useSelector(coreSelector);
  const trial = useSelector((state) => state.billing.trial);
  // const app = useSelector(appSelector);
  const {modules, theme} = core;

  useEffect(() => {
    dispatch({
      type: 'SET_MODULES',
      modules: Object.values(Modules),
    });
  }, []);

  if (!modules) {
    return null;
  }

  // TODO: Might want to merge with main render
  if (core.isLoaded && core.error) {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SigniappGlobalStyle />
          <GlobalStyle />
          <FocusOutlines />
          <NotificationProvider>
            <Main>
              {/* Can not be translated, as request to Text API may have failed*/}
              <Empty
                emoji="🐌"
                heading="Error"
                message="Unable to connect to required APIs."
                action="Try again"
                handleActionClick={() => {
                  window.document.location.reload();
                }}
              />
            </Main>
          </NotificationProvider>
        </Fragment>
      </ThemeProvider>
    );
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        {/* <MessagesProvider> */}
        <ModulesContext.Provider value={Modules}>
          <SigniappGlobalStyle />
          <GlobalStyle />
          <FocusOutlines />
          <ConfirmationServiceProvider>
            <NotificationProvider>
              <Router basename={ROUTES.BASE}>
                {trial?.expires && !trial.hasEnded && window.SIGNIAPP_ENV === 'prod' && <GoogleAnalytics />}
                <Switch>
                  <Route>
                    <ErrorHandler>
                      {/* Add routes that does not depend on core module being loaded */}
                      <Core>
                        <Switch>
                          {/* Add routes that depends on core, but not checking session */}
                          <Route>
                            <Session>
                              {/* Add routes that depends on session being checked */}
                              <Switch>
                                {modules.map((module) => (
                                  <Route
                                    key={module.id}
                                    path={path.join(ROUTES.MODULES, module.meta.pathSegment)}
                                    component={(p) => <AsyncModule modules={Modules} id={module.id} {...p} />}
                                    requireAuthentication={module.config.requireAuthentication || false}
                                    requireAuthorization={module.config.requireAuthorization || false}
                                    showHeader={!module.config.hideHeader}
                                    showFooter={!module.config.hideFooter}
                                    showMainWrap={!module.config.hideMainWrap}
                                  />
                                ))}
                                <Route showHeader showMainWrap>
                                  <CoreRoutes />
                                </Route>
                              </Switch>
                            </Session>
                          </Route>
                        </Switch>
                      </Core>
                    </ErrorHandler>
                  </Route>
                </Switch>
              </Router>
            </NotificationProvider>
          </ConfirmationServiceProvider>
        </ModulesContext.Provider>
        {/* </MessagesProvider> */}
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
