import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import dotProp from 'dot-prop-immutable';
import styled from 'styled-components';

import {TextField, Autocomplete} from '@signicat/designi/components/inputs';
import {Form, Field} from '@signicat/designi/components/forms';
import {Button} from '@signicat/designi/components/buttons';
import {Segment} from '@signicat/designi/components/containers';
import {Paragraph} from '@signicat/designi/components/text';
import {Message} from '@signicat/designi/components/messages';
import {Grid} from '@signicat/designi/components/layout';
import {sort} from '@signiapp/helpers';

import {COUNTRIES} from 'core/constants';
import {t} from 'core/helpers/i18n';

import UpgradeFlowHeading from './components/Heading.js';
import {confirmValidator} from 'core/store/validators/app';
import {getBillingInfo, patchBillingInfo} from 'core/store/actions/app';
import {ROUTES} from 'core/constants';

export const StyledField = styled(Field)`
  padding:8px 4px;
`;

const Confirm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedPlan = new URLSearchParams(location.search).get('plan');
    const [validateAll, setValidateAll] = useState(false);
    const [formData, setFormData] = useState({entityName: null, address: {}});

    const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
    const [formSubmitError, setFormSubmitError] = useState(false);

    const handleFormChange = (field) => (e) => setFormData(dotProp.set(formData, field, e.target.value));

    const handleCountryChange = (e, value) => {
        setFormData(dotProp.set(formData, 'country', value));
    };

    const countryOptions = COUNTRIES.map((c) => ({value: c.code, text: c.name})).sort((a, b) => a.text.localeCompare(b.text))

    const validation = confirmValidator(formData);
    const handleSubmitClick = async () => {
        setFormSubmitError(false);
        if (Object.entries(validation).length > 0) {
            setValidateAll(true);
            return;
        }
        try {
            const data = {
                entityName: formData.entityName,
                entityRegistrationId: formData.entityRegistrationId,
                address: {
                    street: formData.street,
                    postalCode: formData.postalCode,
                    city: formData.city,
                    country: countryOptions.find(c => c.value == formData.country).text
                }
            }
            await dispatch(patchBillingInfo(data));
            await dispatch(getBillingInfo())
            setFormSubmitSuccess(true)
            history.push(`${ROUTES.UPGRADE.DETAILS}/?plan=${selectedPlan}`);
        } catch (err) {
            setFormSubmitError(true);
        }
    };
    const getInfo = async () => {
        const result = await dispatch(getBillingInfo())
        if (result.address && result.address.country && result.address.country.length > 2) {
            result.address.country = countryOptions.find(c => c.text.toLowerCase() == result.address.country.toLowerCase()).value
        }
        let editedResult = Object.assign({}, result)
        for (let key of Object.keys(result.address)) {
            editedResult[key] = result.address[key]
        }
        setFormData(editedResult)
    }
    useEffect(() => {
        try {
            getInfo()
        }
        catch (err) {
            setFormSubmitError(true);
        }
    }, [])

    return (
        <Fragment>
            <UpgradeFlowHeading>
                {t('is_contact_information_correct')}
            </UpgradeFlowHeading>
            <Segment
                outlined
                css="max-width: 640px; padding: 36px"
            >
                <Paragraph style={{fontWeight: '500', marginBottom: '36px'}}>
                    {t('edit_company_details')}
                </Paragraph>
                {formSubmitError
                    ? (
                        <Message variant="error" style={{marginBottom: '24px'}}>
                            {t('upgrade_contactsales_error_unknown')}
                        </Message>
                    )
                    : null}
                <Form validation={validation} state={{validateAll: validateAll}}>
                    <Grid container align-items="center" direction="row">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <StyledField name="entityName">
                                    <TextField
                                        fullWidth
                                        name="entityName"
                                        label={t('upgrade_contactinformation_field_company_label')}
                                        value={formData.entityName || ''}
                                        onChange={handleFormChange('entityName')}
                                    />
                                </StyledField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <StyledField name="street">
                                    <TextField
                                        fullWidth
                                        name="street"
                                        label={t('upgrade_contactinformation_field_street_label')}
                                        value={formData.street || ''}
                                        onChange={handleFormChange('street')}
                                    />
                                </StyledField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <StyledField name="postalCode">
                                    <TextField
                                        fullWidth
                                        name="postalCode"
                                        label={t('upgrade_contactinformation_field_postalcode_label')}
                                        value={formData.postalCode || ''}
                                        onChange={handleFormChange('postalCode')}
                                    />
                                </StyledField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <StyledField name="city">
                                    <TextField
                                        fullWidth
                                        name="city"
                                        label={t('upgrade_contactinformation_field_city_label')}
                                        value={formData.city || ''}
                                        onChange={handleFormChange('city')}
                                    />
                                </StyledField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <StyledField name="country">
                                    <Autocomplete
                                        label={t('upgrade_contactinformation_field_country_label')}
                                        name="country"
                                        fullWidth
                                        options={countryOptions}
                                        value={formData.country}
                                        onChange={handleCountryChange}
                                    />
                                </StyledField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <StyledField name="entityRegistrationId">
                                    <TextField
                                        name="entityRegistrationId"
                                        label={t('upgrade_contactinformation_field_vatnumber_label')}
                                        value={formData.entityRegistrationId || ''}
                                        onChange={handleFormChange('entityRegistrationId')}
                                    />
                                </StyledField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <Button color="primary" onClick={handleSubmitClick}>
                                    {t('upgrade_contactinformation_button_updateandsave')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </Segment>
        </Fragment>
    )
};

export default Confirm;
