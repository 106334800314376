const defaultState = {
  invitationToken: null,
  isLoading: false,
  isRequested: false,
  error: null,
};

const invitationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'INVITATION_GET_TOKEN_REQUEST':
      return {
        ...state,
        isLoading: true,
        isRequested: true,
        error: false,
      };
    case 'INVITATION_GET_TOKEN_SUCCESS':
      return {
        ...state,
        invitationToken: {
          ...action.invitation,
        },
        isLoading: false,
        isRequested: true,
        error: false,
      };
    case 'INVITATION_GET_TOKEN_FAILURE':
      return {
        ...state,
        isLoading: false,
        isRequested: true,
        error: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default invitationReducer;
