import React, {Fragment, useContext} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {Heading} from '@signicat/designi/components/text';
import {Tabs, Tab} from '@signicat/designi/components/tabs';
import {Flex} from '@signicat/designi/components/layout';

import {ConditionalWrapper} from 'core/components';
import {accessLevelSelector} from 'core/store/selectors/session';
import AppLogo from './app/Logo.js';
import AppBackdrop from './app/Backdrop.js';
import AppLanguage from './app/Language.js';
import AppMicrosoft from './app/Microsoft';
import UserLanguage from './user/Language.js';
import LoginMethods from './user/LoginMethods.js';
import Profile from '../Profile.js';
import {t} from 'core/helpers/i18n';
import ModulesContext from 'core/ModulesContext.js';
import SettingsErrorBoundary from '../../components/SettingsErrorBoundary';
import LoginMethodErrorBoundary from '../../components/LoginMethodErrorBoundary';

const TabLabel = styled(Flex)`
  flex-direction: column;
`;

const Settings = () => {
  const accessLevel = useSelector(accessLevelSelector);
  const isPortalAdmin = accessLevel === 'Admin';

  const Modules = useContext(ModulesContext);

  return (
    <Fragment>
      <Heading level={2}>{t('settings_heading')}</Heading>
      <ConditionalWrapper
        condition={isPortalAdmin}
        wrapper={(c) => (
          <Tabs value={0}>
            <Tab
              label={
                <TabLabel id="settings_tab_personal">
                  <Flex>{t('settings_tab_label_user_without_settings')}</Flex>
                </TabLabel>
              }
            >
              {c}
            </Tab>
            <Tab
              label={
                <TabLabel id="settings_tab_portal">
                  <Flex>{t('settings_tab_label_app_without_settings')}</Flex>
                </TabLabel>
              }
            >
              <AppLanguage />
              <AppLogo />
              <AppBackdrop />
              <SettingsErrorBoundary>
                <AppMicrosoft />
              </SettingsErrorBoundary>
            </Tab>
            {Object.values(Modules)
              .map((module) =>
                typeof module.Settings === 'function' ? (
                  <Tab
                    key={module.id}
                    label={
                      <TabLabel id={`settings_tab_${module.id}`}>
                        <Flex>{t(`module_name_${module.id}`)}</Flex>
                      </TabLabel>
                    }
                  >
                    <SettingsErrorBoundary>
                      <module.Settings />
                    </SettingsErrorBoundary>
                  </Tab>
                ) : null
              )
              .filter((tab) => tab !== null)}
          </Tabs>
        )}
      >
        {/* User settings here */}
        <Profile />
        <UserLanguage />
        <LoginMethodErrorBoundary>
          <LoginMethods />
        </LoginMethodErrorBoundary>
      </ConditionalWrapper>
    </Fragment>
  );
};

export default Settings;
