import uuid from 'uuid/v4';
import dotProp from 'dot-prop-immutable';
import {session} from '@signiapp/helpers';

import {OIDC} from 'core/constants/config.js';
import {access, core, i18n, external} from 'core/services';
import {app as appSelectors} from 'core/store/selectors';

export const setTranslationMissing = (translationMissing) => async (dispatch) => {
  dispatch({
    type: 'UPDATE_TRANSLATION_MISSING',
    translationMissing,
  });
};

export const getGeolocation = () => async (dispatch) => {
  const cacheKey = 'signicat.portal.core.geolocation';
  const cachedItem = sessionStorage.getItem(cacheKey);
  let cachedGeolocation = cachedItem && cachedItem !== 'undefined' ? JSON.parse(cachedItem) : null;
  let geolocation = null;
  try {
    geolocation =
      typeof cachedGeolocation === 'object' && !!cachedGeolocation ? cachedGeolocation : await core.getGeolocation();
    sessionStorage.setItem('signicat.portal.core.geolocation', JSON.stringify(geolocation));
  } catch {
    geolocation = {
      countryCode: navigator.language.replace(/(.+)-/, ''),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  }
  dispatch({
    type: 'SET_GEOLOCATION',
    geolocation: {
      country: geolocation.countryCode,
      timezone: geolocation.timezone,
      ipAddress: geolocation.query || null,
    },
  });

  return geolocation;
};

export const getDictionary = (language) => async (dispatch, getState) => {
  const requestId = uuid();

  dispatch({
    type: 'DEPENDENCY_API_REQUEST',
    requestId: requestId,
  });

  try {
    const lang = language ? language : 'en';
    const dictionary = await i18n.getDictionary(lang);

    dispatch({
      type: 'DEPENDENCY_API_SUCCESS',
      requestId: requestId,
    });

    dispatch({
      type: 'SET_DICTIONARY',
      language: lang,
      dictionary: dictionary,
    });
  } catch (err) {
    dispatch({
      type: 'DEPENDENCY_API_FAILURE',
      requestId: requestId,
    });
  }
};

export const getAppPublic = () => async (dispatch) => {
  const appPublic = await core.getPublic();
  // NOTE: Initialize userManager
  session.initUserManager({
    authority: appPublic.config.login.authority,
    clientId: appPublic.config.login.clientId,
    scope: appPublic.config.login.scope,
    redirectUri: OIDC.LOGIN_REDIRECT_URI,
    logoutRedirectUri: OIDC.LOGOUT_REDIRECT_URI,
    silentRedirectUri: OIDC.RENEW_URI,
    popupLoginUri: OIDC.LOGIN_POPUP_URI,
  });

  await dispatch({type: 'GET_THEMES_SUCCESS', themes: appPublic.themes});
  return dispatch({type: 'SET_APP', app: appPublic});
};

export const getAppProtected = () => async (dispatch) => {
  const appProtected = await core.getProtected();
  return dispatch({type: 'SET_APP_PROTECTED', protected: appProtected});
};

export const getDependencies = () => async (dispatch, getState) => {
  dispatch({type: 'GET_DEPENDENCIES_REQUEST'});
  const state = getState();
  return dispatch(getAppPublic())
    .then(({app}) => {
      return Promise.all([dispatch(getDictionary(app.language)), dispatch(getGeolocation())]);
    })
    .then(() => dispatch({type: 'GET_DEPENDENCIES_SUCCESS'}))
    .catch((err) => {
      dispatch({type: 'GET_DEPENDENCIES_FAILURE'});

      console.error(err);
    });
};

export const getThemes = () => async (dispatch) => {
  const themes = await core.getThemes();
  await dispatch({type: 'GET_THEMES_SUCCESS', themes: themes});

  return themes;
};

export const createTheme = (theme) => async (dispatch) => {
  await core.postAppTheme(theme);

  return;
};

export const saveTheme = (id, theme) => async (dispatch) => {};

export const getUsers = () => async (dispatch) => {
  try {
    const users = await access.getUsers();
    dispatch({
      type: 'GET_USERS_SUCCESS',
      users: users,
    });
  } catch (err) {
    console.error(err);
    dispatch({type: 'GET_USERS_FAILURE'});
  }
};

export const getSettingsSet = (setKey) => async (dispatch) => {
  try {
    const settingsSet = await core.getSettings(setKey);

    dispatch({
      type: 'GET_SETTINGS_SET_SUCCESS',
      settingsSet: settingsSet,
    });
  } catch (err) {
    console.error(err);

    dispatch({
      type: 'GET_SETTINGS_SET_FAILURE',
      error: err,
    });
  }
};

export const getSettingTheme = () => async (dispatch) => {
  const theme = await core.getSettings('theme-v1');
  dispatch({type: 'UPDATE_THEME', theme});
};

export const updateSettings = (setKey, settings) => async (dispatch) => {
  try {
    await core.updateSettings(setKey, settings);

    dispatch({type: 'UPDATE_SETTINGS_SUCCESS'});
  } catch (err) {
    console.error(err);

    dispatch({type: 'UPDATE_SETTINGS_FAILURE'});
  }
};

export const getInvitations = () => async (dispatch) => {
  try {
    const invitations = await access.getInvitations();
    dispatch({
      type: 'GET_INVITATIONS_SUCCESS',
      invitations: invitations,
    });
  } catch (err) {
    console.error(err);
    dispatch({type: 'GET_INVITATIONS_FAILURE'});
  }
};

export const revokeInvitation = (invitationId) => async (dispatch) => {
  try {
    await access.revokeInvitation(invitationId);

    dispatch({
      type: 'INVITATION_REVOKE_SUCCESS',
      invitationId: invitationId,
      body: {
        id: invitationId,
        status: 'success',
        wasHandled: false,
      },
    });
  } catch (err) {
    dispatch({
      type: 'INVITATION_REVOKE_FAILURE',
      invitationId: invitationId,
      body: {
        id: invitationId,
        status: 'failed',
        wasHandled: false,
      },
    });
  }
};

export const revokeInvitationHandled = (invitation) => async (dispatch) => {
  dispatch({
    type: 'INVITATION_REVOKE_HANDLED',
    invitationId: invitation.invitationId,
    body: {
      id: invitation.invitationId,
      status: invitation.status,
      wasHandled: true,
    },
  });
};

export const saveUserPermissions = (userId) => async (dispatch, getState) => {
  const user = appSelectors.userByIdSelector(userId)(getState());
  const {resourceType, resourceId, entityType, entityId} = user.data.resourceAccess;

  const changes = {
    accessLevel: user.form.resourceAccess.accessLevel,
  };

  return access.updatePermissionRecord(resourceType, resourceId, entityType, entityId, changes);
};

export const saveUserProfile = (userId) => async (dispatch, getState) => {
  const user = appSelectors.userByIdSelector(userId)(getState());

  const profile = {
    firstName: user.form.profile.firstName,
    lastName: user.form.profile.lastName,
  };

  return access.updateUserProfile(userId, profile);
};

export const saveUser = (userId) => async (dispatch, getState) => {
  return Promise.all([dispatch(saveUserPermissions(userId)), dispatch(saveUserProfile(userId))]).then(
    ([permissions, profile]) =>
      dispatch({
        type: 'USER_SAVE_SUCCESS',
        userId: userId,
        permissions: permissions,
        profile: profile,
      })
  );
};

export const deleteUser = (userId) => async (dispatch) => {
  await access.removeUser(userId);

  dispatch({
    type: 'USER_DELETE_SUCCESS',
    userId: userId,
  });
};

export const addUserToGroup = (userId, groupId) => async (dispatch, getState) => {
  dispatch({
    type: 'ADD_USER_TO_GROUP',
    userId: userId,
    groupId: groupId,
  });

  const {app} = getState();
  const user = app.users.items[userId];
  const {resourceType, resourceId, entityType, entityId} = user.data.resourceAccess;

  dispatch({
    type: 'ADD_USER_TO_GROUP_REQUEST',
    userId: userId,
    groupId: groupId,
  });

  try {
    // const result = await access.updatePermissionRecord(resourceType, resourceId, entityType, entityId, changes);
    const result = await access.addGroupToPermissionRecord(resourceType, resourceId, entityType, entityId, groupId);

    dispatch({
      type: 'ADD_USER_TO_GROUP_SUCCESS',
      userId: userId,
      groupId: groupId,
    });

    return result;
  } catch (err) {
    dispatch({
      type: 'ADD_USER_TO_GROUP_FAILURE',
      userId: userId,
      groupId: groupId,
    });

    throw err;
  }
};

export const removeUserFromGroup = (userId, groupId) => async (dispatch, getState) => {
  const {app} = getState();
  const user = app.users.items[userId];
  const {resourceType, resourceId, entityType, entityId} = user.data.resourceAccess;

  // const changes = {
  //   groups: groups.filter((group) => (group !== groupId)),
  // };
  //
  // console.log('removeuserfromgroup:arguments', userId, groupId);
  // console.log('removeuserfromgroup:changes', changes);

  dispatch({
    type: 'REMOVE_USER_FROM_GROUP_REQUEST',
    userId: userId,
    groupId: groupId,
  });

  try {
    const result = await access.removeGroupFromPermissionRecord(
      resourceType,
      resourceId,
      entityType,
      entityId,
      groupId
    );

    dispatch({
      type: 'REMOVE_USER_FROM_GROUP_SUCCESS',
      userId: userId,
      groupId: groupId,
    });

    return result;
  } catch (err) {
    dispatch({
      type: 'REMOVE_USER_FROM_GROUP_FAILURE',
      userId: userId,
      groupId: groupId,
    });

    throw err;
  }
};

export const createGroup = (id) => async (dispatch, getState) => {
  const group = appSelectors.groupByIdSelector(id)(getState());
  const result = await access.createGroup(group.data);

  // Add the new group
  await Promise.all(
    (group.data.admins || [])
      .filter((admin) => admin.type === 'User')
      .map((admin) => dispatch(addUserToGroup(admin.id, id)))
  );

  dispatch({type: 'GROUP_CREATE_SUCCESS', id: id, group: result});

  return result;
};

export const saveGroup = (id) => async (dispatch, getState) => {
  const group = appSelectors.groupByIdSelector(id)(getState());
  const result = await access.saveGroup(group.data);

  return result;
};

export const deleteGroup = (groupId) => async (dispatch) => {
  const result = await access.deleteGroup(groupId);

  dispatch({type: 'GROUP_DELETE_SUCCESS', groupId: groupId});

  return result;
};

export const getApp = () => async (dispatch) => {
  dispatch({type: 'GET_APP_REQUEST'});
  const app = await access.getApp();
  dispatch({type: 'GET_APP_SUCCESS', app: app});
};

export const initGroup = () => async (dispatch) => {
  const id = uuid();
  const group = {
    id: id,
    isCreating: true,
    isCreated: false,
    data: {group: id},
  };

  dispatch({type: 'ADD_GROUP', group: group});
};

export const getAppSettings = () => async (dispatch, getState) => {
  return core
    .getAppSettings()
    .catch((err) => {
      // App has not yet set any settings, no worries
      // Or could it be something else now if the endpoint is fixed
    })
    .then((settings) =>
      settings
        ? dispatch({
            type: 'APP_SET_SETTINGS',
            settings: settings,
          })
        : null
    );
};

export const saveAppLanguage = (language) => async (dispatch, getState) => {
  const {
    app: {settings},
  } = getState();
  const updatedSettings = dotProp.set(settings, 'i18n.language', language);

  return core
    .saveAppSettings(updatedSettings)
    .then(() => dispatch(getDictionary(language)))
    .then(() =>
      dispatch({
        type: 'APP_SET_LANGUAGE',
        language: language,
      })
    );
};

export const sendContactSalesInquiry = (data) => async () => {
  const PORTAL_ID = 5310879;
  const FORM_ID = '0bcfe1f0-2f74-406c-8666-67e9a7054ae5';

  const fixedFields = [
    {
      name: 'offline_lead_source',
      value: 'Sign portal trial upgrade',
    },
    {
      name: 'leadsource',
      value: 'Test Account',
    },
  ];

  const formFields = [
    {
      name: 'company',
      value: data.company,
    },
    {
      name: 'country',
      value: data.country,
    },
    {
      name: 'email',
      value: data.email,
    },
    {
      name: 'firstname',
      value: data.firstName,
    },
    {
      name: 'lastname',
      value: data.lastName,
    },
    {
      name: 'phone',
      value: data.mobileNumber,
    },
    {
      name: 'message',
      value: data.message,
    },
  ];

  return external.postHubspotForm(PORTAL_ID, FORM_ID, {fields: [...formFields, ...fixedFields]});
};

export const getBillingStatus = () => async (dispatch) => {
  const billingStatus = await core.getBillingStatus();
  dispatch({
    type: 'BILLING_GET_STATUS_SUCCESS',
    billingStatus: billingStatus,
  });
  return billingStatus;
};

export const getBillingInfo = () => async (dispatch) => {
  const billingInfo = await core.getBillingInfo();
  dispatch({
    type: 'GET_BILLING_INFO',
    billingInfo,
  });
  return billingInfo;
};

export const patchBillingInfo = (data) => async () => {
  await core.patchBillingInfo(data);
};

export const getPaymentMethods = () => async (dispatch, getState) => {
  const paymentMethods = await core.getPaymentMethods();

  dispatch({
    type: 'BILLING_GET_PAYMENT_METHODS_SUCCESS',
    paymentMethods: paymentMethods,
  });

  return paymentMethods;
};

export const initPaymentMethodSetup = (options) => async (dispatch, getState) => {
  const paymentMethodSetup = await core.initPaymentMethodSetup(options);

  return paymentMethodSetup;
};

export const getPlans = () => async (dispatch) => {
  const plans = await core.getPlans();
  dispatch({
    type: 'BILLING_GET_PLANS_SUCCESS',
    plans: plans.filter((plan) => ['basic', 'advanced', 'enterprise'].includes(plan.id)),
  });
};

export const checkMicrosoftClientExists = (discoveryEndpoint) => async (dispatch) => {
  return await external.microsoftClientExists(discoveryEndpoint);
};

export const getAvailableLoginIdps = () => async (dispatch) => {
  return await core.getAvailableLoginIdpMethods();
};

export const getCoreAdvancedSettings = () => async (dispatch) => {
  try {
    const settingsSet = await core.getSettings('general_settings');

    dispatch({
      type: 'GET_ADVANCED_CORE_SETTINGS_SET_SUCCESS',
      settingsSet: settingsSet,
    });
  } catch (err) {
    console.error(err);

    dispatch({
      type: 'GET_ADVANCED_CORE_SETTINGS_SET_FAILURE',
      error: err,
    });
  }
};

export default {
  initGroup,
  getDictionary,
  getAppPublic,
  getAppProtected,
  getDependencies,
  getThemes,
  getSettingTheme,
  createTheme,
  saveTheme,
  getUsers,
  saveUserProfile,
  saveUserPermissions,
  saveUser,
  deleteUser,
  createGroup,
  deleteGroup,
  getApp,
  addUserToGroup,
  removeUserFromGroup,
  getAppSettings,
  saveAppLanguage,
  getInvitations,
  getPaymentMethods,
  initPaymentMethodSetup,
  revokeInvitation,
  revokeInvitationHandled,
  getSettingsSet,
  updateSettings,
  checkMicrosoftClientExists,
  getAvailableLoginIdps,
  getCoreAdvancedSettings,
};
