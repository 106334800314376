import {createSelector} from 'reselect';

export const userSelector = (state) => (
  state.user
);

export const userLanguageSelector = createSelector(
  userSelector,
  (user) => (
    user.settings?.i18n?.language || null
  )
);

export const userCountrySelector = (state) => (
  state.app.geolocation.country
);

export default {
  userCountrySelector,
  userLanguageSelector,
};
