import React from 'react';
// import {useLocation} from 'react-router-dom';

// eslint-disable-next-line
export default () => {
  // const location = useLocation();
  // console.warn('404', location);

  return (
    <div>404</div>
  );
};
