import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Splash from 'core/components/Splash';
import {checkSession, checkUserAndAccess, logout} from 'core/store/actions/session';
import {getApp, getAppProtected, getUsers, getAppSettings, getBillingStatus} from 'core/store/actions/app';
import {getPlans, getFeatures} from 'core/store/actions/billing'
import {getUserSettings} from 'core/store/actions/user';
import {login} from 'core/store/actions/session';
import {session as sessionHelper} from '@signiapp/helpers';
import {useConfirmation} from '@signicat/designi/components/overlays/ConfirmationService';
import {LoginRequired} from '@signiapp/helpers/errors';

import {Empty} from '@signicat/designi/components/containers';

import {t} from 'core/helpers/i18n';
import {initializeUserSettings} from './store/actions/user';

const Session = (props) => {
  const {children} = props;
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const confirm = useConfirmation();

  const handleSessionExpired = async () => {
    if (session?.isAuthenticated) {
      await confirm({
        title: t('session_expired_heading'),
        message: t('session_expired_message'),
        variant: 'info',
        buttonConfirm: t('button_text_ok'),
      });

      throw LoginRequired();
    }
  };

  useEffect(() => {
    dispatch({type: 'SESSION_LOAD_REQUEST'});

    dispatch(checkSession())
      .then((tokens) => {
        if (tokens) {
          return dispatch(checkUserAndAccess())
            .then(([user, access]) => {
              user && access && Promise.all(access?.accessLevel === "Admin" ? [
                dispatch(getApp()),
                dispatch(getAppProtected()),
                dispatch(getUsers()),
                dispatch(getUserSettings()),
                dispatch(getAppSettings()),
                dispatch(getBillingStatus()),
                dispatch(getPlans()),
                dispatch(getFeatures()),
                dispatch(initializeUserSettings(user?.userId, user?.externalLogins)),
              ]
                : [
                  dispatch(getApp()),
                  dispatch(getAppProtected()),
                  dispatch(getUsers()),
                  dispatch(getUserSettings()),
                  dispatch(getAppSettings()),
                  dispatch(initializeUserSettings(user?.userId, user?.externalLogins)),
                ]
              )
            });
        }
        return null;
      })
      .then(() => {
        dispatch({type: 'SESSION_LOAD_SUCCESS'});
      })
      .catch((err) => {
        dispatch({type: 'SESSION_LOAD_FAILURE', error: err});

        console.error(err);
      });
  }, []);

  useEffect(() => {
    sessionHelper.on('accesstokenexpired', handleSessionExpired);

    return () => {
      sessionHelper.off('accesstokenexpired', handleSessionExpired);
    };
  }, [session]);

  if (session.isLoaded === true && session.error) {
    if (session.error === 'OIDC_NETWORK_ERROR') {
      return (
        <Empty
          emoji="🔌"
          heading={t('oidc_network_error_heading')}
          message={t('oidc_network_error_message')}
          action={t('button_text_retry')}
          handleActionClick={() => {
            dispatch(login());
          }}
        />
      );
    }

    return (
      <Empty
        emoji="👾"
        heading={t('session_error_heading')}
        message={t('session_error_message')}
        action={t('logout')}
        handleActionClick={() => {
          dispatch(logout());
        }}
      />
    );
  }

  return (
    session.isLoaded ? children : <Splash />
  );
};
export default Session;
