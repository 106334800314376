import CustomErrorInstance from 'custom-error-instance';

import SigniappError from './SigniappError.js';

const LoginRequired = CustomErrorInstance('LoginRequired', SigniappError, {
  message: 'Login required',
  code: 'SGN:3.400', // NOTE: Just making up some error codes
  redirectUri: null,
});

export default LoginRequired;
