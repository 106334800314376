import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Empty} from '@signicat/designi/components/containers';
import {Button} from '@signicat/designi/components/buttons';

import {logout} from 'core/store/actions/session';

import {t} from 'core/helpers/i18n';

const Logout = () => {
  const dispatch = useDispatch();
  const {isAuthenticated, logoutInProgress} = useSelector((state) => (state.session));
  const language = useSelector(state => state.app.settings.i18n.language)
  const logoutOptions = {ui_locales: language || "en"};

  if (logoutInProgress) {
    return (
      <div>{t('logout_redirect_in_progress_message')}</div>
    );
  }

  if (logoutInProgress === false && isAuthenticated === false) {
    return (
      <Empty emoji="👋" heading={t('logout_success_heading')} message={t('logout_success_message')} />
    );
  }

  return (
    <div><Button onClick={() => (dispatch(logout(logoutOptions)))}>Log out</Button></div>
  );
};

export default Logout;
