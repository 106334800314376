import React, {useEffect, useState} from 'react';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import path from 'path';

import {Message} from '@signicat/designi/components/messages';
import {Spinner} from '@signicat/designi/components/loaders';
import {Empty} from '@signicat/designi/components/containers';

import {invitation as invitationActions} from 'core/store/actions';
import {t} from 'core/helpers/i18n';

const Accept = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const invitation = useSelector((state) => state.invitation.invitationToken);

  const [isDone, setIsDone] = useState(false);
  const [failure, setFailure] = useState();
  const {invitationToken} = useParams();

  useEffect(() => {
    dispatch(invitationActions.acceptInvitation(invitationToken))
      .then(() => {
        setIsDone(true);
      })
      .catch((err) => {
        switch (err.message) {
          case 'USER_ALREADY_HAS_ACCESS':
          case 'APP_MAX_USERS_REACHED':
          case 'INVALID_IDP':
            setFailure(err.message);
            break;
          case 'ID_HAS_NO_USER':
            // Redirect to registration page if no user
            history.push(path.resolve(match.url, '..', 'register'));
            break;
          default:
            console.error(err);
            break;
        }
      });
  }, []);

  if (failure) {
    switch (failure) {
      case 'USER_ALREADY_HAS_ACCESS':
        return (
          <Message>
            {t('invitation_message_already_access')}
            {' '}
            <a href="/">{t('invitation_button_text_go_to_portal')}</a>
          </Message>
        );
      case 'APP_MAX_USERS_REACHED':
        return (
          <Message>
            {t('invitation_message_max_users_reached', {
              appName: invitation.resourceDisplayName,
            })}
          </Message>
        );
      case 'INVALID_IDP':
        return (
          <Message>
            {t('invitation_message_invalid_idp', {
              appName: invitation.resourceDisplayName,
            })}
          </Message>
        );
      default:
        break;
    }
  }

  if (isDone) {
    return (
      <Empty
        emoji="🎉"
        heading={t('invitation_accept_success_heading')}
        message={t('invitation_accept_success_message')}
        action={t('invitation_accept_success_action')}
        handleActionClick={() => { history.push('/'); }}
      />
    );
  }

  return <Spinner fullscreen />;
};

export default Accept;
