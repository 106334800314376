import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`
  #signiapp {
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }
`;
