import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {Button} from '@signicat/designi/components/buttons';
import {Segment} from '@signicat/designi/components/containers';
import {Div, Flex, Row, Column} from '@signicat/designi/components/layout';
import {Grid} from '@signicat/designi/components/layout';
import {TextField} from '@signicat/designi/components/inputs';
import {Status} from '@signicat/designi/components/loaders';

import {userLanguageSelector} from 'core/store/selectors/user';
import {user as userActions, session as sessionActions} from 'core/store/actions';
import {t} from 'core/helpers/i18n';
import validator from 'validator';

const Profile = () => {
  const user = useSelector((state) => state.session?.user);
  const u = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const language = useSelector(userLanguageSelector);

  const [profile, setProfile] = useState(user.profile);

  const handleProfileChange = (field) => (e) => {
    setProfile({...profile, [field]: e.target.value});
  };

  return (
    <Row>
      <Column>
        <Segment direction="row" outlined padding="24px" heading={t('profile_heading_new')}>
          <Div>
            <Grid container spacing={2} alignItems="flex-start" direction="row">
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('profile_field_firstname_label')}
                    name="firstName"
                    value={profile.firstName}
                    onChange={handleProfileChange('firstName')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('profile_field_lastname_label')}
                    name="lastName"
                    value={profile.lastName}
                    onChange={handleProfileChange('lastName')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('profile_field_company_label')}
                    name="companyName"
                    value={profile.companyName}
                    onChange={handleProfileChange('companyName')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('profile_field_role_label')}
                    name="companyRole"
                    value={profile.companyRole}
                    onChange={handleProfileChange('companyRole')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2} direction="row">
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('profile_field_email_label')}
                    name="email"
                    type="email"
                    value={profile.email}
                    onChange={handleProfileChange('email')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label={t('profile_field_mobilenumber_label')}
                    name="mobile"
                    type="tel"
                    value={profile.mobile}
                    onChange={handleProfileChange('mobile')}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={false} sm={6} md={9}>
              {/* Empty container to move next field to next line */}
            </Grid>
            <Grid item xs={12} md={6} lg={4} style={{marginTop: '24px'}}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm={6}>
                  <Button
                    id="profile_save_button"
                    color="primary"
                    onClick={() => {
                      dispatch(userActions.save({...user, profile}));
                      dispatch(sessionActions.updateCurrentUser({...user, profile}));
                    }}
                    size="sm"
                  >
                    {t('button_text_save')}
                  </Button>
                  {u.isSaving ? <Status isSaving={u.isSaving} error={u.error} /> : null}
                </Grid>
              </Grid>
            </Grid>
          </Div>
        </Segment>
      </Column>
    </Row>
  );
};

export default Profile;
