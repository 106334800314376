import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import reducers from 'core/store/reducers';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
