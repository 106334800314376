import React from 'react';
import styled from 'styled-components';
import {Column, Flex, Row} from '@signicat/designi/components/layout';
import {t} from 'core/helpers/i18n';
import {Button} from '@signicat/designi/components/buttons';
import {media} from '@signicat/designi/helpers/responsive';

const CreateButton = styled(Button)`
  background: #7d51da;
  border-color: #7d51da;
  color: #fff;
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  :hover {
    background: #5f36bc;
    border-color: #5f36bc;
  }
`;

const Header = styled(Row)`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  justify-content: center;
  color: #2a0062;
`;

const Paragraph = styled(Row)`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2a0062;
`;

const StyledImg = styled('img')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFragment = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDokobitBanner = styled(Flex)`
  padding: 24px 48px;
  background-color: #f2f0f6;
  color: ${(props) => props.theme.styles.colors.secondary};
  margin-bottom: 100px;
`;

const DokobitBanner = (props) => {
  return (
    <StyledDokobitBanner>
      {
        <StyledFragment>
          <Column spacing="lg" padding="40px">
            <Header>
              {t('dokobit_login_banner_header')}
            </Header>
            <Paragraph>{t('login_dokobit_banner_underline_paragraph')}</Paragraph>
            <Paragraph>{t('login_dokobit_banner_paragraph')}</Paragraph>
            <Row style={{justifyContent: 'center'}}>
              <CreateButton size="lg" color="primary" onClick={() => window.open('https://www.dokobit.com/features?gad=1&gclid=EAIaIQobChMIhY7rysmc_wIVFKnVCh29vQwTEAAYASADEgJmzPD_BwE')}>
                {t('login_dokobit_banner_create_button')}
              </CreateButton>
            </Row>
          </Column>
          {!media.matches.tablet.and('down') && (
            <Column>
              <Row style={{justifyContent: 'center'}}>
                <StyledImg src="https://static.signicat.app/img/e-signing.svg" />
              </Row>
            </Column>
          )}
        </StyledFragment>
      }
    </StyledDokobitBanner>
  );
};

export default DokobitBanner;