import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Route, Switch, useLocation, useHistory} from 'react-router-dom';
import path from 'path';

import {AccessDenied} from '@signiapp/helpers/errors';
import {Button} from '@signicat/designi/components/buttons';
import {Heading} from '@signicat/designi/components/text';
import {Flex, Column, Row} from '@signicat/designi/components/layout';
import {Tabs, Tab} from '@signicat/designi/components/tabs';
import {Message} from '@signicat/designi/components/messages';

import {ROUTES} from 'core/constants';
import {isGroupAdminSelector, accessLevelSelector} from 'core/store/selectors/session';
import {Feature} from 'core/components';

import Groups from './Groups.js';
import Users from './Users.js';
import GroupCreate from './GroupCreate.js';
import GroupEdit from './GroupEdit.js';
import UserDetails from './UserDetails.js';
import UserInvite from './UserInvite.js';
import Filters from '../../../components/Filters';
import {t} from 'core/helpers/i18n';
import {usersWithErrorSelector} from 'core/store/selectors/app';
import Invitations from './Invitations';

export const Titlebar = styled(Flex)`
  border: 2px solid #f6f6f6;
  padding: 2px 0px;
  border-radius: 4px;
  margin-bottom: 16px;
  flex: 0 0 auto;
  > ${Flex} {
    flex: 1 0 0;
  }
  & * {
    color: #fff;
  }
  background-color: #293240;
  border-color: #293240;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
`;
const HiddenFeature = styled(Feature)`
  display: none;
`;
const UserAccessManagement = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isGroupAdmin = useSelector(isGroupAdminSelector);
  const accessLevel = useSelector(accessLevelSelector);
  const isPortalAdmin = accessLevel === 'Admin';
  const isLoaded = useSelector((state) => state.app?.groups?.isLoaded && state.app?.users?.isLoaded);
  const [tab, setTab] = useState(0);
  const [showGroupAddButton, setShowGroupAddButton] = useState(true);
  const [groupRoutesEnabled, setGroupRoutesEnabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const usersError = useSelector(usersWithErrorSelector);
  const currentRoute = useLocation().pathname;

  if (!isLoaded) {
    return null;
  }
  if (!isPortalAdmin && !isGroupAdmin) {
    throw new AccessDenied();
  }

  const reloadPage = () => {
    window.location.reload();
  };

  const onTabChange = (e, newValue) => {
    setSearchTerm('');
    setTab(newValue);
  };

  const handleNewGroup = () => {
    const groupCreatePath = path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'groups', 'create');

    if (currentRoute === groupCreatePath) dispatch({type: 'GROUP_CREATE_UNMOUNT'});
    else history.push(groupCreatePath);
  };

  if (usersError) {
    return (
      <Row>
        <Column>
          <Row style={{marginBottom: '24px'}}>
            <Column vertical="center">
              <Heading level={3} style={{marginBottom: 0}} marginBottom="0">
                {t('uam_title')}
              </Heading>
            </Column>
          </Row>
          <Message variant="error" icon="error">
            <Flex flex="1 0 auto">{t(usersError)}</Flex>
            <Flex flex="0 0 auto">
              <Button color="negative" size="sm" onClick={reloadPage}>
                {t('core_button_refresh_page')}
              </Button>
            </Flex>
          </Message>
        </Column>
      </Row>
    );
  }

  return (
    <Row>
      <Column>
        <Row style={{marginBottom: '48px'}}>
          <Column vertical="center">
            <Heading
              level={2}
              style={{
                marginBottom: 0,
                display: 'flex',
                alignItems: 'flex-start',
              }}
              marginBottom="0"
            >
              {t('uam_title')}
            </Heading>
          </Column>
          <Column>
            {isPortalAdmin && (
              <Row horizontal="end">
                <HiddenFeature
                  onFeatureToggle={(e) => setShowGroupAddButton(e)}
                  featureId="core_userandgroupmanagement"
                ></HiddenFeature>
                {showGroupAddButton && (
                  <Button icon="create" color="secondary" variant="outlined" size="sm" onClick={handleNewGroup}>
                    {t('uam_button_text_create_group')}
                  </Button>
                )}
                <Button
                  icon="userAdd"
                  color="secondary"
                  size="sm"
                  forwardedAs={Link}
                  to={path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'users', 'invite')}
                >
                  {t('uam_button_text_invite_user')}
                </Button>
              </Row>
            )}
          </Column>
        </Row>
        <Row>
          <Column>
            <HiddenFeature featureId="core_userandgroupmanagement" onFeatureToggle={(e) => setGroupRoutesEnabled(e)} />
            <Switch>
              <Route path={path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'users', 'invite')}>
                <UserInvite />
              </Route>
              <Route path={path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'groups', 'create')}>
                <GroupCreate featureEnabled={groupRoutesEnabled} />
              </Route>
              <Route path={path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'groups', ':id')}>
                <GroupEdit featureEnabled={groupRoutesEnabled} />
              </Route>
              <Route path={path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'users', ':id')}>
                <UserDetails />
              </Route>
              <Route>
                {isPortalAdmin ? (
                  <Tabs
                    onChange={onTabChange}
                    value={tab}
                    renderTabBar={(children) => (
                      <Flex direction="row" justifyContent="space-between" alignItems="center">
                        {children}
                        <Filters handleSearchTermChange={setSearchTerm} searchTerm={searchTerm} />
                      </Flex>
                    )}
                  >
                    <Tab label={t('uam_users_heading')}>
                      <Users searchTerm={searchTerm} />
                    </Tab>
                    <Tab label={t('uam_groups_heading')}>
                      <Feature featureId="core_userandgroupmanagement">
                        <Groups searchTerm={searchTerm} />
                      </Feature>
                    </Tab>
                    <Tab label={t('uam_invitations_heading')}>
                      <Invitations searchTerm={searchTerm} />
                    </Tab>
                  </Tabs>
                ) : (
                  <Fragment>
                    <Heading level={3}>{t('uam_groups_heading')}</Heading>
                    <Groups searchTerm={searchTerm} />
                  </Fragment>
                )}
              </Route>
            </Switch>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

export default UserAccessManagement;
