import {session} from 'helpers';

import {ApiError, NetworkError, SessionError} from 'helpers/errors';

const BASE_URL = window.SIGNIAPP_API_BASE_URL;

const makeRequest = async (url, options = {}) => {
  const apiBaseUrl = `${BASE_URL}`;

  if (session.auth) {
    const {tokenType, accessToken} = session.auth;

    options.headers = {
      ...options.headers || {},
      Authorization: `${tokenType} ${accessToken}`,
    };
  }

  const reqUrl = `${/http/.test(url) ? '' : apiBaseUrl}${url}`;

  return fetch(reqUrl, options).catch((err) => {
    throw NetworkError({url: reqUrl});
  });
};

export const handleResponse = async (response, options) => {
  const contentType = response.headers.get('content-type');
  let body;

  if (options?.method === 'HEAD') {
    // Don't parse response for HEAD
  } else if (/json/.test(contentType)) {
    body = await response.json();
  } else if (/text/.test(contentType)) {
    body = await response.text();
  } else if (/application\/ocet-stream/.test(contentType)) {
    body = await response.blob();
  } else if (/form-data/.test(contentType)) {
    body = await response.formData();
  }

  if (response.status >= 400) {
    throw ApiError({statusCode: response.status, body: body});
  }

  return body || response;
};

export const req = async (url, options = {}) => {
  const mergedOptions = {
    method: 'GET',
    ...options,
  };

  let response = await makeRequest(url, mergedOptions);

  if (response.status === 401) {
    if (session.isAuthenticated) {
      await session.renew();
    }

    response = await makeRequest(url, mergedOptions)
  }

  const result = await handleResponse(response, mergedOptions);

  return result;
};

export const download = async (url, options) => {
  if (session.isAuthenticated === false) {
    throw SessionError({message: 'Download failed because user was not authenticated'});
  }


  const {tokenType, accessToken} = session.auth;
  try {
    const result = await fetch(url, {
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    })
    if (result.status >= 400) {
      throw ApiError({statusCode: result.status});
    }
    const blob = await result.blob()
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = objectUrl;
    a.download = options?.filename;

    document.body.appendChild(a);

    a.click();
    a.remove();
  }
  catch (err) {
    throw err
  }
};

export default {
  download,
  req,
};
