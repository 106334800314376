export const coreSelector = (state) => state.core;

export const accessApiAppId = (state) => (
  state.core.app?.config?.accessApi?.appId
);

export default {
  accessApiAppId,
  coreSelector,
};
