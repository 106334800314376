import {combineReducers} from 'redux';

import accountReducer from 'core/store/reducers/account.js';
import appReducer from 'core/store/reducers/app.js';
import authReducer from 'core/store/reducers/auth.js';
import coreReducer from 'core/store/reducers/core.js';
import invitationReducer from 'core/store/reducers/invitation.js';
import userReducer from 'core/store/reducers/user.js';
import sessionReducer from 'core/store/reducers/session.js';
import billingReducer from './billing.js';
import upgradeReducer from './upgrade.js';

export {default as app} from './app.js';
export {default as account} from './account.js';
export {default as core} from './core.js';
export {default as invitation} from './invitation.js';
export {default as session} from './session.js';
export {default as user} from './user.js';
export {default as billing} from './billing.js';
export {default as upgrade} from './upgrade.js';

export default combineReducers({
  account: accountReducer,
  app: appReducer,
  core: coreReducer,
  invitation: invitationReducer,
  auth: authReducer,
  user: userReducer,
  session: sessionReducer,
  billing: billingReducer,
  upgrade: upgradeReducer
});
