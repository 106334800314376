import {Column, Flex, Grid, Row} from '@signicat/designi/components/layout';
import {Icon} from '@signicat/designi/components/icons';
import {Label} from '@signicat/designi/components/forms';
import {Heading, HelpText} from '@signicat/designi/components/text';
import {Button} from '@signicat/designi/components/buttons';
import {Segment} from '@signicat/designi/components/containers';
import React, {useEffect, useState} from 'react';
import {t} from 'core/helpers/i18n';
import {useSelector, useDispatch} from 'react-redux';
import {Message, useNotification} from '@signicat/designi/components/messages';
import {userSelector} from '../../../store/selectors/user';
import {getUserLoginMethods, removeUserLoginMethod} from '../../../store/actions/user';
import {useConfirmation} from '@signicat/designi/components/overlays/ConfirmationService';
import {Spinner} from '@signicat/designi/components/loaders';
import AddLoginMethod from './AddLoginMethod';

const getIdentityProviderImageUrl = (identityProvider) => {
  switch (identityProvider) {
    case 'sms_otp':
      return 'https://static.signicat.app/img/sms_otp.svg';
    case 'no_bankid_netcentric':
      return 'https://static.signicat.app/img/no_bankid_rgb_blue.svg';
    case 'no_bankid_mobile':
      return 'https://static.signicat.app/img/no_bankid_mobile_rgb_blue.svg';
    case 'no_buypass':
      return 'https://static.signicat.app/img/buypass_rgb.svg';
    case 'se_bankid':
      return 'https://static.signicat.app/img/swedish_bankid_logo.svg';
    case 'dk_nemid':
      return 'https://static.signicat.app/img/nemid.svg';
    case 'fi_tupas':
      return 'https://static.signicat.app/img/fi_flag.svg';
    case 'facebook':
      return 'https://static.signicat.app/img/facebook-40x40.png';
    case 'google':
      return 'https://static.signicat.app/img/google.svg';
    case 'microsoft':
      return 'https://static.signicat.app/img/microsoft.svg';
    case 'github':
      return 'https://static.signicat.app/img/github.svg';
    case 'linkedin':
      return 'https://static.signicat.app/img/linkedin.svg';
    case 'freja':
      return 'https://static.signicat.app/img/freja.svg';

  }
};

const reloadPage = () => {
  window.location.reload();
};

const LoginMethods = () => {
  const dispatch = useDispatch();
  const {enqueueNotification} = useNotification();
  const confirm = useConfirmation();

  useEffect(() => {
    dispatch(getUserLoginMethods(user.userId));
  }, []);

  const user = useSelector(userSelector);

  const title = t('uam_user_delete_prompt_title');
  const message = t('login_method_remove_prompt_message');
  const buttonConfirm = t('button_text_yes');
  const buttonCancel = t('button_text_no');

  const failedToRemoveLoginMethod = t('login_method_remove_notification_error');
  const successfullyRemovedLoginMethod = t('login_method_remove_notification_success');

  const removeLoginMethodForUser = async (userId, identityProvider, subjectId) => {
    await confirm({
      title: title,
      message: message,
      variant: 'confirm',
      buttonConfirm: buttonConfirm,
      buttonCancel: buttonCancel,
    });

    let wasSuccessful = await dispatch(removeUserLoginMethod(userId, identityProvider, subjectId));

    if (wasSuccessful) {
      enqueueNotification(successfullyRemovedLoginMethod, {
        variant: 'success',
      });

      dispatch(getUserLoginMethods(user.userId));
    } else {
      enqueueNotification(failedToRemoveLoginMethod, {
        variant: 'error',
      });
    }
  };

  if (user.isLoading) {
    return <Spinner fullscreen />;
  }
  
  if (user.errorForLoginMethods) {
    return (
      <Row>
        <Column>
          <Row style={{marginBottom: '24px'}}>
            <Column vertical="center">
              <Heading
                level={3}
                style={{marginBottom: 0}}
                marginBottom="0"
              >
                {t('login_methods_title')}
              </Heading>
            </Column>
          </Row>
          <Message variant="error" icon="error">
            <Flex flex="1 0 auto">
              {t(user.errorForLoginMethods)}
            </Flex>
            <Flex flex="0 0 auto">
              <Button color="negative" size="sm" onClick={reloadPage}>
                {t('core_button_refresh_page')}
              </Button>
            </Flex>
          </Message>
        </Column>
      </Row>
    );
  }
  
  return (
    <Segment
      divided
      outlined
      direction="column"
      marginBottom="32px"
    >
      <React.Fragment><Grid item xs={12} sm={6}>
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item style={{flex: '0 0 auto', alignItems: 'flex-start'}}>
            <Icon name='forward'></Icon>
          </Grid>
          <Grid item style={{flex: '1 0 0'}}>
            <Grid container direction="column" justifyContent="center">
              <Label>{t('login_method_view_title')}</Label>
              <HelpText style={{marginTop: '2px'}}>
                {t('login_method_view_help_text')}
              </HelpText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={9}>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <AddLoginMethod userId={user.userId}/>
          </Grid>
        </Grid>
      </Grid></React.Fragment>
      <Grid container spacing={2} direction="row">
        {user.loginMethods.map((loginMethod, i) => (
          <React.Fragment key={i}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} direction="row" alignItems="stretch">
                <Grid item style={{flex: '0 0 auto', alignItems: 'flex-start', width: '85px', height: 'auto'}}>
                  <img src={getIdentityProviderImageUrl(loginMethod.identityProvider)} style={{paddingLeft: '4px', paddingTop: '12px'}}/>
                </Grid>
                <Grid item style={{flex: '1 0 0'}}>
                  <Grid container direction="column" justifyContent="center">
                    <Label>{t(`login_method_${loginMethod.identityProvider}`)}</Label>
                    <HelpText style={{marginTop: '2px'}}>
                      {`${t('personal_settings_login_method_unique_id')} ${loginMethod.subjectId}`}
                    </HelpText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={9}>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Button
                    onClick={() => removeLoginMethodForUser(user.userId, loginMethod.identityProvider, loginMethod.subjectId)}
                    icon={'remove'}
                    color="negative"
                    variant="outlined"
                    style={{flexGrow: 1}}
                  >
                    {t('personal_settings_login_method_remove')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Segment>
  );
};

export default LoginMethods;
