import dotProp from 'dot-prop-immutable';

import {access, core} from 'core/services';
import {getDictionary} from 'core/store/actions/app';

const saveUser = (user) => async (dispatch) => {
  // console.log('store:actions:saveUser', user);
  dispatch({type: 'PROFILE_SAVE_REQUEST'});

  try {
    await access.saveUser(user);

    dispatch({type: 'PROFILE_SAVE_SUCCESS'});
  } catch (err) {
    console.error(err);
    dispatch({type: 'PROFILE_SAVE_FAILURE'});
  } finally {
    // console.log('store:actions:saveUser:finally');
  }
};

export const getUserSettings = () => async (dispatch, getState) => {
  return core.getUserSettings()
    .catch((err) => {
      // User has not yet set any settings, no worries
    })
    .then((settings) => (
      settings
        ? dispatch({
          type: 'USER_SET_SETTINGS',
          settings: settings,
        })
        : null
    ));
};

export const saveUserLanguage = (language) => async (dispatch, getState) => {
  const {user} = getState();
  if (user.isChangingLanguage) {
    return
  }
  dispatch({type: 'USER_SET_CHANGING_LANGUAGE', isChangingLanguage: true})
  const updatedSettings = dotProp.set(user.settings, 'i18n.language', language);

  return core.saveUserSettings(updatedSettings)
    .then(() => dispatch(getDictionary(language)))
    .then(() => dispatch({
      type: 'USER_SET_LANGUAGE',
      language: language,
    }))
    .then(() => {
      dispatch({type: 'USER_SET_CHANGING_LANGUAGE', isChangingLanguage: false})
    })
    .catch(() => {
      dispatch({type: 'USER_SET_CHANGING_LANGUAGE', isChangingLanguage: false})
    });
};

export const initializeUserSettings = (userId, loginMethods) => async (dispatch) => {
  if (!userId || !loginMethods) {
    dispatch({
      type: 'USER_INITIALIZE_SETTINGS_FAILURE',
      errorForLoginMethods: 'UserId or login methods was not available',
    });
  } else {
    dispatch({
      type: 'USER_INITIALIZE_SETTINGS_SUCCESS',
      userId: userId,
      loginMethods: loginMethods,
    });
  }
};

export const getUserLoginMethods = (userId) => async (dispatch) => {
  try {
    const response = await access.getUserLoginMethods(userId);

    dispatch({
      type: 'GET_USER_LOGIN_METHODS_SUCCESS',
      loginMethods: response,
    });
  } catch (e) {
    console.error(e);
  }
};

export const removeUserLoginMethod = (userId, identityProvider, subjectId) => async (dispatch) => {
  try {
    await access.removeLoginMethod(userId, identityProvider, subjectId);

    return true;
  } catch (e) {
    console.error(e);

    return false;
  }
};

export const getLoginRegistrationToken = () => async (dispatch) => {
  return await access.getRegistrationToken();
};

export const useRegistrationLoginToken = (regToken) => async (dispatch) => {
  return await access.useRegistrationToken(regToken);
}

export default {
  save: saveUser,
  getUserSettings: getUserSettings,
  saveUserLanguage: saveUserLanguage,
  initializeUserSettings: initializeUserSettings,
  getUserLoginMethods: getUserLoginMethods,
  removeUserLoginMethod: removeUserLoginMethod,
  getLoginRegistrationToken: getLoginRegistrationToken,
  useRegistrationLoginToken: useRegistrationLoginToken,
};
