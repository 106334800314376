import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {Flex} from '@signicat/designi/components/layout';
import {Button} from '@signicat/designi/components/buttons';
import {List, ListItem} from '@signicat/designi/components/lists';
// import {Text} from '@signicat/designi/components/text';

import {planByIdSelector} from 'core/store/selectors/billing';

import {t} from 'core/helpers/i18n';


export const Text = styled('span')`
  color: ${(props) => props.isInDetailsView ? 'white' : props.theme.styles.colors.bodySecondaryTextColor};
  font-weight: 300;
`;

const FeaturesHeading = styled(Flex)`
  font-size: 19px;
  font-weight:400;
  color: ${(props) => props.isInDetailsView ? 'white' : props.theme.styles.colors.bodySecondaryTextColor};
`;


const Features = styled((props) => {
  const {planId, isInDetailsView, list = []} = props;
  const plan = useSelector(planByIdSelector(planId));

  return (
    <div className={props.className}>
      {isInDetailsView ? null :
        <FeaturesHeading isInDetailsView={isInDetailsView} css="margin-bottom: 28px;">
          {t(`upgrade_plan_${planId}_features_heading`)}
        </FeaturesHeading>
      }
      <List css="padding-left: 16px;">
        {plan?.view?.features?.map((feature) => (
          <ListItem key={feature.key}>
            <Text isInDetailsView={isInDetailsView}>
              {t(`upgrade_plan_feature_${feature.key}`)}
            </Text>
          </ListItem>
        ))}
      </List>
    </div>
  );
})`
  & ${ListItem} {
    list-style-type: disc;
    margin-bottom: 8px;
    ::marker {
      color: ${(props) => props.isInDetailsView ? 'white' : props.theme.styles.colors.bodySecondaryTextColor};
    }
    padding:5px 0;
    font-size:16px;
    font-weight:300;
    color: ${(props) => props.isInDetailsView ? 'white' : props.theme.styles.colors.bodySecondaryTextColor};
  }
`;

export default Features;
