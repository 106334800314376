import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {Empty} from '@signicat/designi/components/containers';

import {invitation as invitationActions} from 'core/store/actions';
import {t} from 'core/helpers/i18n';

const Decline = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {invitationToken} = useParams();

  const [isDone, setIsDone] = useState(false);

  const handleDeclineClick = () => {
    dispatch(invitationActions.declineInvitation(invitationToken)).then(() => {
      setIsDone(true);
    });
  };

  if (isDone) {
    return (
      <Empty
        emoji="🗑"
        heading={t('invitation_decline_success_heading')}
        message={t('invitation_decline_success_message')}
      />
    );
  }

  return (
    <Empty
      emoji="🤔"
      heading={t('invitation_decline_prompt_heading')}
      message={t('invitation_decline_prompt_message')}
      handleActionClick={handleDeclineClick}
      action={t('invitation_decline_prompt_action_confirm')}
      handleSecondaryActionClick={() => history.goBack()}
      secondaryAction={t('invitation_decline_prompt_action_cancel')}
    />
  );
};

export default Decline;
