import {session as sessionService} from '@signiapp/helpers';

import accessService from 'core/services/access.js';

export const checkSession = () => async (dispatch, getState) => {
  const {userManager} = sessionService;

  return userManager.getUser().then((session) => {
    if (session && session.expired === true) {
      return userManager.removeUser().then(() => null);
    }

    if (session) {
      const tokens = {
        accessToken: session.access_token,
        tokenType: session.token_type,
        scope: session.scope,
      };
      dispatch({type: 'SESSION_SET_TOKENS', tokens: tokens});
      return tokens;
    }

    return null;
  });
};

export const updateCurrentUser = (user) => {
  return {type: 'SESSION_GET_USER_SUCCESS', user};
};

export const getCurrentUser = () => async (dispatch, getState) => {
  const state = getState();
  const {tokens} = state.session;
  const {accessToken} = tokens;
  const payloadEncoded = accessToken.split('.')[1];
  const payload = JSON.parse(atob(payloadEncoded));

  dispatch({type: 'SESSION_GET_USER_REQUEST'});

  if (typeof payload.user_id !== 'string') {
    dispatch({type: 'SESSION_GET_USER_SUCCESS', user: null});

    return null;
  }

  return accessService
    .getCurrentUser(tokens.accessToken)
    .then((user) => {
      dispatch({type: 'SESSION_GET_USER_SUCCESS', user: user});
      return user;
    })
    .catch((err) => {
      console.error(err);
      dispatch({type: 'SESSION_GET_USER_FAILURE', error: err});
      throw err;
    });
};

export const getCurrentAccess = () => async (dispatch, getState) => {
  const state = getState();

  return accessService.getCurrentAccess().then((access) => {
    const accessApiAppId = state.core.app.config.accessApi.appId;
    const currentAppAccess = access.find((a) => a.resourceId === accessApiAppId);

    dispatch({type: 'SESSION_SET_ACCESS', access: currentAppAccess});

    return currentAppAccess;
  });
};

export const checkUserAndAccess = () => async (dispatch) =>
  Promise.all([dispatch(getCurrentUser()), dispatch(getCurrentAccess())]);

export const auth = (options) => () => sessionService.signinRedirect(options);

export const login = (options) => (dispatch, getState) =>
  dispatch(auth(options)).catch((err) => {
    dispatch({type: err.message});
  });

export const silentRenew = () => (dispatch) => {
  const {userManager} = sessionService;

  return userManager
    .signinSilent()
    .then((session) => {
      const tokens = {
        accessToken: session.access_token,
        tokenType: session.token_type,
        scope: session.scope,
      };

      dispatch({type: 'OIDC_RENEW_COMPLETE', tokens: tokens});

      return tokens;
    })
    .catch((err) => {
      dispatch({type: 'OIDC_RENEW_FAILURE'});

      throw new Error('SILENT_RENEW_ERROR');
    });
};

export const clearSession = () => async (dispatch) => {
  dispatch({type: 'SESSION_LOGOUT'});
  dispatch({type: 'SESSION_UNSET_USER'});
  dispatch({type: 'SESSION_UNSET_TOKENS'});
};

export const logout = (options) => async (dispatch, getState) => {
  dispatch({type: 'SESSION_LOGOUT_REQUEST'});
  const state = getState();
  if (options) {
    options.ui_locales = state.app.settings.i18n.language || 'en';
  }
  return sessionService.signoutRedirect(options || {ui_locales: state.app.settings.i18n.language || 'en'});
};

export const addOpenIdLoginMethod = (language) => async (dispatch) => {
  return sessionService.addOpenIdMethod(language);
};

export const renewAfterAddedLoginMethod = () => async (dispatch) => {
  return sessionService.renew();
};

export default {
  auth,
  login,
  logout,
  checkSession,
  updateCurrentUser,
  checkUserAndAccess,
  silentRenew,
  clearSession,
  addOpenIdLoginMethod,
  renewAfterAddedLoginMethod,
};
