import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Flex} from '@signicat/designi/components/layout';
import Checkbox from '@signicat/designi/components/inputs/Checkbox';
import RadioGroup from '@signicat/designi/components/inputs/RadioGroup';
import {t} from 'core/helpers/i18n';

const Content = styled(Flex)`
  flex-direction: row;
  justify-content:space-between;
  padding: 10px 20px;
  width: 100%;
  border-radius: 4px;
`;

const EIDLogo = styled('img')`
max-width:125px;
height:40px;
`;

const EID = styled((props) => {
    const {
        eid,
        multiselect,
        onChange,
        selectedEIDs,
        ...rest
    } = props;
    const [value, setValue] = useState(null)
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        const isSelected = selectedEIDs.includes(eid.id)
        if (!multiselect) {
            setValue(isSelected ? eid.id : null)
        }
        else {
            setDisabled(selectedEIDs.length >= 4 && !isSelected)
        }

    }, [selectedEIDs]);
    const handleOnChange = (e) => {
        e.stopPropagation()
        if (multiselect) {
            setValue(value ? null : eid.id)
        }
        onChange(eid.id)
    }
    const getComponent = () => {
        if (multiselect) {
            return eid.tags.find(t => t === 'default')
                ? <Checkbox color="primary" label={getLabel()} disabled={true} checked={true}></Checkbox>
                : <Checkbox color="primary" label={getLabel()} disabled={disabled || !!eid.tags.find(t => ['disabled', 'default'].includes(t))} onChange={handleOnChange}></Checkbox>
        }
        return eid.tags.find(t => t === 'default') 
        ? <RadioGroup color="primary" options={[{value: eid.id, text: getLabel(), disabled: true}]} value={eid.id} ></RadioGroup>
        : <RadioGroup options={[{value: eid.id, text: getLabel(), disabled: !!eid.tags.find(t => ['disabled', 'default'].includes(t))}]} color="primary" value={value} onChange={handleOnChange}></RadioGroup>
    }
    const getTagValue = (key) => {
        const tag = eid.tags.find(t => t.includes(key))
        if (tag) {
            const result = tag.split(":")
            result.shift()
            return result.join(":")
        }
        return ""

    }
    const getLabel = () => {
        return t(getTagValue("label"))
    }
    return (
        <Fragment>
            <Flex {...rest}>
                <Content>
                    {getComponent()}
                    <EIDLogo src={getTagValue("icon")}></EIDLogo>
                </Content>
            </Flex>
        </Fragment>
    );
})`
border: 2px solid ${(props) => (
        props.selectedEIDs.includes(props.eid.id)
            ? props.theme.styles.colors.primaryColor
            : props.theme.styles.colors.primaryFadedColor
    )};
`
export default EID;