import {access} from 'core/services';

const updateUserAccessLevel = (userId, accessLevel) => async (dispatch, getState) => {
  const {account} = getState();
  const {users} = account;
  const user = users.data.find((u) => (u.userId === userId));

  const {resourceType, resourceId, entityType, entityId} = user.resourceAccess;
  const changes = {
    accessLevel: accessLevel,
  };

  access.updatePermissionRecord(resourceType, resourceId, entityType, entityId, changes)
    .then((json) => {
      dispatch({type: 'USER_PERMISSION_UPDATE_SUCCESS', record: json});
    });
};

const getUsers = () => async (dispatch) => {
  // console.log('store:actions:getUsers');
  dispatch({type: 'USERS_GET_REQUEST'});

  try {
    const users = await access.getUsers();
    dispatch({
      type: 'USERS_GET_SUCCESS',
      users: users,
    });
  } catch (err) {
    console.error(err);
    dispatch({type: 'USERS_GET_FAILURE'});
  } finally {
    // console.log('store:actions:getUsers:finally');
  }
};

const removeUser = (userId) => async (dispatch) => (
  access.removeUser(userId)
);

const sendInvitation = (invitation) => async (dispatch, getState) => {
  dispatch({type: 'INVITATION_SEND_REQUEST'});
  const state = getState();
  const {resourceId, resourceType} = state.session.access;

  const body = {
    expirationInMinutes: 1440,
    receiverEmail: invitation.receiverEmail,
    receiverMobile: invitation.receiverMobile,
    invitationLanguage: invitation.invitationLanguage,
    oneTimeUse: false,
    adminGroups: invitation.adminGroups || null,
    grantedAccess: {
      accessLevel: invitation.accessLevel,
      groups: invitation.groups || null,
      permissions: {},
      resourceType: resourceType,
      resourceId: resourceId,
    },
  };

  try {
    await access.sendInvitation(body);
    dispatch({type: 'INVITATION_SEND_SUCCESS'});
  } catch (err) {
    console.error(err);
    throw err;
    dispatch({type: 'INVITATION_SEND_FAILURE'});
  } finally {
    // console.log('store:actions:getUsers:finally');
  }
};

export default {
  getUsers,
  sendInvitation,
  removeUser,
  updateUserAccessLevel,
};
