import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import path from 'path';
import styled from 'styled-components';

import {Button} from '@signicat/designi/components/buttons';
import {Flex} from '@signicat/designi/components/layout';
import {Heading, Paragraph} from '@signicat/designi/components/text';
import {Empty} from '@signicat/designi/components/containers';
import {Segment} from '@signicat/designi/components/containers';
import {useConfirmation} from '@signicat/designi/components/overlays/ConfirmationService';

import {userByIdSelector, groupsByUserSelector} from 'core/store/selectors/app';
import {deleteUser} from 'core/store/actions/app';
import {t} from 'core/helpers/i18n';
import {ROUTES} from 'core/constants';

import Feature from '../../../components/Feature.js';
import {Titlebar} from './UserAccessManagement.js';
import UserEdit from './UserEdit.js';

const HiddenFeature = styled(Feature)`
  display: none;
`

const UserDetails = () => {

  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const {id: userId} = useParams();
  const user = useSelector(userByIdSelector(userId));
  const history = useHistory();
  const [userGroupsEnabled, setUserGroupsEnabled] = useState(true)

  if (!user) {
    return (
      <Empty
        emoji="😱"
        heading={'404'}
        message={t('user_edit_not_found_message')}
        secondaryAction={t('user_edit_not_found_secondary_message')}
        handleSecondaryActionClick={() => history.replace(ROUTES.USER_ACCESS_MANAGEMENT)}
      />
    )
  }

  const handleEditClick = () => {
    dispatch({
      type: 'USER_EDIT_INIT',
      userId: userId,
    });
  };

  const handleDeleteClick = async () => {
    await confirm({
      title: t('uam_user_delete_prompt_title'),
      message: t('uam_user_delete_prompt_message'),
      variant: 'confirm',
      buttonConfirm: t('button_text_yes'),
      buttonCancel: t('button_text_no'),
    });

    dispatch(deleteUser(userId));
  };

  const groups = useSelector(groupsByUserSelector(userId));

  if (user.isDeleted) {
    return (
      <Empty
        emoji="✅"
        heading={t('uam_user_delete_success_heading')}
        message={t('uam_user_delete_success_message')}
        actionComponent={
          <Button
            color="primary"
            forwardedAs={Link}
            to={ROUTES.USER_ACCESS_MANAGEMENT}
          >
            {t('uam_link_overview')}
          </Button>
        }
      />
    );
  }

  return (
    <div style={{marginTop: '36px'}}>
      <Titlebar>
        <Flex>
          <Button
            variant="transparent"
            color="secondary"
            icon="back"
            forwardedAs={Link}
            to={ROUTES.USER_ACCESS_MANAGEMENT}
          >
            {t('uam_link_overview')}
          </Button>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Heading
            level={4}
            style={{paddingBottom: 0, marginBottom: 0}}
          >
            {user.data.profile.name}
          </Heading>
        </Flex>
        <Flex></Flex>
      </Titlebar>
      {user.form
        ? <UserEdit id={user.id} />
        : (
          <Segment outlined>
            <Flex direction="column" style={{marginBottom: '12px'}}>
              <strong style={{fontWeight: 500}}>{t('profile_field_fullname_label')}</strong>
              <Paragraph>
                {user.data.profile.name}
              </Paragraph>
            </Flex>
            <Flex direction="column" style={{marginBottom: '24px'}}>
              <strong style={{fontWeight: 500}}>{t('access_level')}</strong>
              <Paragraph>
                {t(`access_level_${user.data.resourceAccess.accessLevel.toLowerCase()}`)}
              </Paragraph>
            </Flex>
            <HiddenFeature featureId="core_userandgroupmanagement" onFeatureToggle={(e) => setUserGroupsEnabled(e)} />
            {userGroupsEnabled &&
              <Flex direction="column" style={{marginBottom: '24px'}}>
                <strong style={{fontWeight: 500}}>{t('uam_user_field_groups_label')}</strong>
                <Paragraph>
                  {groups.length
                    ? groups.map((group) => (
                      <Fragment key={group.id}>
                        <Link to={path.join(ROUTES.USER_ACCESS_MANAGEMENT, 'groups', group.id)}>
                          {group.data.displayName}
                        </Link>
                        <br />
                      </Fragment>
                    ))
                    : t('uam_user_no_groups')
                  }
                </Paragraph>
              </Flex>
            }
            <Flex>
              <Button
                icon="edit"
                color="secondary"
                variant="outlined"
                size="sm"
                onClick={handleEditClick}
              >
                {t('button_text_edit')}
              </Button>
              <Button
                icon="delete"
                color="negative"
                variant="outlined"
                size="sm"
                onClick={handleDeleteClick}
              >
                {t('button_text_delete')}
              </Button>
            </Flex>
          </Segment>
        )}
    </div>
  );
};

export default UserDetails;
