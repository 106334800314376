/* eslint-disable */
// import packageVersions from 'core/package-versions.js';

export const logPackageVersions = (packageVersions) => {
  const consoleStyle = 'background: #fff; color: #293440';
  console.info('');
  console.info('%c Signicat Portal Packages', `${consoleStyle}; font-weight: bold;`);
  Object.keys(packageVersions).forEach((packageName) => {
    console.info(`%c - ${packageName}@${packageVersions[packageName]}`, consoleStyle);
  });
  console.info('');
};

export default {
  logPackageVersions,
};
