import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Route as ReactRouterRoute} from 'react-router-dom';

import {Empty} from '@signicat/designi/components/containers';

import {t} from 'core/helpers/i18n';
import {logout} from 'core/store/actions/session';

// import {ConditionalWrapper, Header, Footer, Main} from 'core/components';
import ConditionalWrapper from 'core/components/ConditionalWrapper.js';
import Header from 'core/components/Header.js';
import Footer from 'core/components/Footer.js';
import Main from 'core/components/Main.js';

/**
  * Drop-in replacement for react-router-dom Route component that
  * will render Header, Footer, and wrap content area in Main,
  * based on props `showHeader`, `showFooter`, and `showMainWrap`.
  * If `component` or `render` props is set, then this will not work.
  */
const Route = (props) => {
  const dispatch = useDispatch();
  const {
    showHeader,
    showFooter,
    showMainWrap,
    requireAuthorization,
    requireAuthentication,
    children,
    ...rest
  } = props;

  const {session} = useSelector((state) => state);

  if (requireAuthentication) {
    if (session.isAuthenticated === true && session.user === null) {
      return (
        <Empty
          emoji="🐼"
          heading={t('user_missing_error_heading')}
          message={t('user_missing_error_message')}
          action={t('logout')}
          handleActionClick={() => {
            dispatch(logout());
          }}
        />
      );
    }
  }

  if (requireAuthorization) {
    // if (session.isLoaded && session.isAuthorized === false) {
    if (session.user && session.isAuthorized === false) {
      return (
        <Fragment>
          {showHeader ? <Header /> : null}
          <Empty
            emoji="🚫"
            heading={t('user_error_heading_forbidden')}
            message={t('user_error_message_forbidden')}
            action={t('logout')}
            handleActionClick={() => {
              dispatch(logout());
            }}
          />
          {showFooter ? <Footer /> : null}
        </Fragment>
      );
    }
  }

  return (
    <ReactRouterRoute {...rest}>
      {props.component || props.render ? null : (
        <Fragment>
          {showHeader ? <Header /> : null}
          <ConditionalWrapper
            condition={showMainWrap}
            wrapper={(c) => <Main>{c}</Main>}
          >
            {children}
          </ConditionalWrapper>
          {showFooter ? <Footer /> : null}
        </Fragment>
      )}
    </ReactRouterRoute>
  );
};

Route.propTypes = {
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showMainWrap: PropTypes.bool,
  requireAuthorization: PropTypes.bool,
  requireAuthentication: PropTypes.bool,
};

Route.defaultProps = {
  showHeader: false,
  showFooter: false,
  showMainWrap: false,
  requireAuthorization: false,
  requireAuthentication: false,
};

export default Route;
