import React, {Component} from 'react';
import Empty from '@signicat/designi/components/containers/Empty';
import {t} from 'core/helpers/i18n';

class SettingsErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {hasError: false};
  }

  static getDerivedStateFromError(err) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Empty emoji="😱" heading={t('core_module_settings_error_heading')} message={t('core_module_settings_error_message')}/>
      );
    }

    return this.props.children;
  }
}

export default SettingsErrorBoundary;
