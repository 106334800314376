import React, {Fragment, useState, useEffect, Suspense} from 'react';
import {useSelector} from 'react-redux';

import {Spinner} from '@signicat/designi/components/loaders';
// import {useBars} from '@signicat/designi/components/messages/Bars.js';
// import {differenceInCalendarDays} from 'date-fns';

import ConditionalWrapper from 'core/components/ConditionalWrapper.js';
import Header from 'core/components/Header.js';
import Footer from 'core/components/Footer.js';
import Main from 'core/components/Main.js';
// import TrialBanner from 'core/components/TrialBanner.js';
// import TrialExpired from 'core/components/TrialExpired.js';
import {createdGroupsSelector, languageSelector, moduleSelector} from 'core/store/selectors/app';
// import {t} from 'core/helpers/i18n';
//
// import styled from 'styled-components';
// import {Button} from '@signicat/designi/components/buttons';
// import {Flex} from '@signicat/designi/components/layout';
// import {Link} from 'react-router-dom';

// const UpgradeButton = styled(Button)`
//   background-color: #fff;
//   border-color: #fff;
//   color: ${(props) => (
//     props.theme.styles.colors.primary
//   )};
// `;

const AsyncModule = (props) => {
  const {id, modules} = props;
  const [App, setApp] = useState(null);
  const {session, app} = useSelector((state) => state);
  const language = useSelector(languageSelector);
  // const module = useSelector(moduleSelector(id));
  const groups = useSelector(createdGroupsSelector)
    .map((group) => group.data);

  // const {addBar, removeBar} = useBars();

  // const {trial} = module;

  // const daysLeft = trial?.isTrial
  //   ? differenceInCalendarDays(new Date(trial.expires), new Date())
  //   : null;
  //
  useEffect(() => {
    setApp(modules[id].module);

    // if (trial?.isTrial) {
    //   let barId = addBar('Yo in trial mode');
    //
    //   return () => {
    //     removeBar(barId);
    //   };
    // }
  }, [id]);


  if (App === null) {
    return null;
  }

  return (
    <Fragment>
      {modules[id].config.hideHeader ? null : <Header />}
      <ConditionalWrapper
        condition={! modules[id].config.hideMainWrap}
        wrapper={(children) => (
          <Main>{children}</Main>
        )}
      >
        {/* {trial?.isTrial && ! trial?.hasEnded */}
        {/*   ? ( */}
        {/*     <TrialBanner> */}
        {/*       <Flex> */}
        {/*         {t(`module_${id}_trial_bar_message`, {daysLeft: daysLeft})} */}
        {/*       </Flex> */}
        {/*       <Flex style={{marginLeft: '12px'}}> */}
        {/*         <UpgradeButton */}
        {/*           size="xs" */}
        {/*           to={`/upgrade/${id}`} */}
        {/*           forwardedAs={Link} */}
        {/*         > */}
        {/*           {t(`module_${id}_trial_bar_cta_button_text`)} */}
        {/*         </UpgradeButton> */}
        {/*       </Flex> */}
        {/*     </TrialBanner> */}
        {/*   ) */}
        {/*   : null */}
        {/* } */}
        {/* {trial?.hasEnded */}
        {/*   ? <TrialExpired expires={trial.expires} /> */}
        {/*   : null */}
        {/* } */}
        <Suspense fallback={<Spinner fullscreen />}>
          <App
            {...props}
            features={app.features}
            session={session}
            language={language}
            groups={groups}
          />
        </Suspense>
      </ConditionalWrapper>
      {modules[id].config.hideFooter ? null : <Footer />}
    </Fragment>
  );
};

export default AsyncModule;
