import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import dotProp from 'dot-prop-immutable';

import {TextField, Autocomplete} from '@signicat/designi/components/inputs';
import {Form, Field} from '@signicat/designi/components/forms';
import {Button} from '@signicat/designi/components/buttons';
import {Segment, Empty} from '@signicat/designi/components/containers';
import {Paragraph} from '@signicat/designi/components/text';
import {Message} from '@signicat/designi/components/messages';
import {Grid} from '@signicat/designi/components/layout';
import {sort} from '@signiapp/helpers';

import {COUNTRIES} from 'core/constants';
import {t} from 'core/helpers/i18n';

import UpgradeFlowHeading from './components/Heading.js';

import {userCountrySelector} from 'core/store/selectors/user';
import {contactSalesValidator} from 'core/store/validators/app';
import {sendContactSalesInquiry} from 'core/store/actions/app';
import {ROUTES} from 'core/constants';

export const StyledField = styled(Field)`
  padding:8px 4px;
`;

const Contact = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userCountry = useSelector(userCountrySelector);
  const [validateAll, setValidateAll] = useState(false);
  const [formData, setFormData] = useState({
    country: userCountry,
  });

  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);

  const handleFormChange = (field) => (e) => setFormData(dotProp.set(formData, field, e.target.value));

  const handleCountryChange = (e, value) => {
    setFormData(dotProp.set(formData, 'country', value));
  };

  const countryOptions = sort(COUNTRIES.map((country) => ({
    value: country.code,
    text: country.name || t(country.code),
  })), {
    type: 'string',
    valueSelector: (value) => value.text,
  });

  const validation = contactSalesValidator(formData);

  const handleSubmitClick = async () => {
    setFormSubmitError(false);

    if (Object.entries(validation).length > 0) {
      setValidateAll(true);

      return;
    }

    try {
      await dispatch(sendContactSalesInquiry({
        ...formData,
        country: COUNTRIES.find((c) => c.code === formData.country).name,
      }));

      setFormSubmitSuccess(true)
    } catch (err) {
      setFormSubmitError(true);
    }
  };

  const handleSuccessActionClick = () => {
    history.push(ROUTES.ROOT);
  };

  return (
    <Fragment>
      {formSubmitSuccess
        ? (
          <Empty
            emoji="📨"
            heading={t('upgrade_contactsales_success_heading')}
            message={t('upgrade_contactsales_success_message')}
            action={t('upgrade_contactsales_success_primary_action_button_text')}
            handleActionClick={handleSuccessActionClick}
          />
        )
        : (
          <Fragment>
            <UpgradeFlowHeading>
              {t('upgrade_contactsales_heading')}
            </UpgradeFlowHeading>
            <Segment
              outlined
              css="max-width: 640px; padding: 36px"
            >
              <Paragraph style={{marginBottom: '24px'}}>
                {t('upgrade_contactsales_form_lead')}
              </Paragraph>
              {formSubmitError
                ? (
                  <Message variant="error" style={{marginBottom: '24px'}}>
                    {t('upgrade_contactsales_error_unknown')}
                  </Message>
                )
                : null}
              <Form validation={validation} state={{validateAll: validateAll}}>
                <Grid container align-items="center" direction="row">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <StyledField name="firstName">
                        <TextField
                          fullWidth
                          name="firstname"
                          label={t('upgrade_contactsales_field_firstname_label')}
                          value={formData.firstName || ''}
                          onChange={handleFormChange('firstName')}
                        />
                      </StyledField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledField name="firstName">
                        <TextField
                          fullWidth
                          name="lastname"
                          label={t('upgrade_contactsales_field_lastname_label')}
                          value={formData.lastName || ''}
                          onChange={handleFormChange('lastName')}
                        />
                      </StyledField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledField name="email">
                        <TextField
                          fullWidth
                          name="email"
                          label={t('upgrade_contactsales_field_email_label')}
                          type="email"
                          value={formData.email || ''}
                          onChange={handleFormChange('email')}
                        />
                      </StyledField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledField name="mobileNumber">
                        <TextField
                          fullWidth
                          name="mobilenumber"
                          label={t('upgrade_contactsales_field_mobilenumber_label')}
                          value={formData.mobileNumber || ''}
                          onChange={handleFormChange('mobileNumber')}
                        />
                      </StyledField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledField name="company">
                        <TextField
                          fullWidth
                          name="company"
                          label={t('upgrade_contactsales_field_company_label')}
                          value={formData.company || ''}
                          onChange={handleFormChange('company')}
                        />
                      </StyledField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledField name="country">
                        <Autocomplete
                          label={t('upgrade_contactsales_field_country_label')}
                          name="country"
                          fullWidth
                          options={countryOptions}
                          value={formData.country || 'NO'}
                          onChange={handleCountryChange}
                        />
                      </StyledField>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledField helpText={t('upgrade_contactsales_field_message_helptext')}>
                        <TextField
                          fullWidth
                          multiline
                          name="message"
                          label={t('upgrade_contactsales_field_message_label')}
                          value={formData.message || ''}
                          onChange={handleFormChange('message')}
                        />
                      </StyledField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button color="primary" onClick={handleSubmitClick}>
                        {t('upgrade_contactsales_submit_button_text')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Segment>
          </Fragment>
        )
      }
    </Fragment>
  )
};

export default Contact;
