import {createSelector} from 'reselect';

export const billingSelector = (state) => state.billing;

export const paymentMethodsSelector = createSelector(
  billingSelector,
  (billing) => billing.paymentMethods.data
);

export const plansSelector = () => createSelector(
  billingSelector,
  (billing) => billing.plans.data
);

export const planByIdSelector = (planId) => createSelector(
  plansSelector(),
  (plans) => plans.find((plan) => plan.id === planId)
);

export default {
  billingSelector,
  paymentMethodsSelector,
  plansSelector,
  planByIdSelector,
};
