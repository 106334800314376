import {PhoneNumberUtil} from 'google-libphonenumber';
import validator from 'validator';
import {t} from 'core/helpers/i18n';

const phoneUtil = PhoneNumberUtil.getInstance();

const groupValidators = {
  name: (data) => {
    if (!data.displayName) {
      return {
        errors: [t('validation_group_name_is_required')],
      };
    }
  },
};

const userValidators = {
  firstName: (data) => {
    if (!data.profile.firstName) {
      return {
        errors: [t('validation_first_name_is_required')],
      };
    }
  },
  lastName: (data) => {
    if (!data.profile.lastName) {
      return {
        errors: [t('validation_last_name_is_required')],
      };
    }
  },
};

export const userValidator = (data) => {
  return Object.entries(userValidators).reduce((acc, curr) => {
    const [key, val] = curr;
    const result = val(data);

    return result ? {...acc, [key]: result} : acc;
  }, {});
};

export const groupValidator = (data) => {
  return Object.entries(groupValidators).reduce((acc, curr) => {
    const [key, val] = curr;
    const result = val(data);

    return result ? {...acc, [key]: result} : acc;
  }, {});
};

const isRequired = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return {errors: [t('validation_required_field')]};
  }
};

const isEmail = (value) => {
  if (!validator.isEmail(value)) {
    return {errors: [t('validation_invalid_email')]};
  }
};

const isMobileNumberWithCountryCode = (value) => {
  let mobileNumber;
  let mobileIsValid = validator.isMobilePhone(value);

  if (mobileIsValid) {
    try {
      mobileNumber = phoneUtil.parseAndKeepRawInput(value || '', 'NO');
      mobileIsValid = phoneUtil.isValidNumber(mobileNumber);
    } catch (err) {
      mobileIsValid = false;
    }
  }

  if (mobileIsValid === false) {
    return {
      errors: [t('validation_invalid_mobilenumber')],
    };
  }

  // Means country code was not determined from input value
  if (mobileNumber?.getCountryCodeSource() !== 1) {
    return {
      errors: [t('validation_mobilenumber_countrycode_is_required')],
    };
  }
};

const contactSalesValidators = {
  firstName: isRequired,
  lastName: isRequired,
  email: (value) => isRequired(value) || isEmail(value),
  mobileNumber: (value) => isRequired(value) || isMobileNumberWithCountryCode(value),
  company: isRequired,
  country: isRequired,
};


export const contactSalesValidator = (data) => {
  return Object.entries(contactSalesValidators).reduce((acc, curr) => {
    const [key, val] = curr;
    const result = val(data[key], data);

    return result ? {...acc, [key]: result} : acc;
  }, {});
};

const confirmValidators = {
  entityName: isRequired,
  entityRegistrationId: isRequired,
  street: isRequired,
  postalCode: isRequired,
  city: isRequired,
  country: isRequired,
};

export const confirmValidator = (data) => {
  return Object.entries(confirmValidators).reduce((acc, curr) => {
    const [key, val] = curr;
    const result = val(data[key], data);

    return result ? {...acc, [key]: result} : acc;
  }, {});
};

export default {
  group: groupValidator,
  user: userValidator,
  contactSales: contactSalesValidator,
  confirmValidator
};
