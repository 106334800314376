const defaultState = {
  isLoading: false,
  isLoaded: false,
  isAuthenticated: false,
  isAuthorized: false,
  oidcLoginInProgress: false,
  logoutInProgress: false,
  tokens: null,
  user: null,
  error: null,
  access: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'OIDC_LOGIN_INITIATED':
      return {
        ...state,
        oidcLoginInProgress: true,
      };
    case 'OIDC_LOGIN_COMPLETE':
      return {
        ...state,
        oidcLoginInProgress: false,
        isAuthenticated: true,
        tokens: action.tokens,
      };
    case 'OIDC_RENEW_COMPLETE':
      return {
        ...state,
        isAuthenticated: true,
        tokens: action.tokens,
      };
    case 'OIDC_LOGIN_ABORTED':
      return {
        ...state,
        oidcLoginInProgress: false,
      };
    case 'OIDC_NETWORK_ERROR':
      return {
        ...state,
        oidcLoginInProgress: false,
        error: action.type,
      };
    case 'SESSION_LOAD_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'SESSION_LOAD_SUCCESS':
      return {
        ...state,
        error: null,
        isLoading: false,
        isLoaded: true,
      };
    case 'SESSION_LOAD_FAILURE':
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.error,
      };
    case 'SESSION_GET_USER_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'SESSION_GET_USER_FAILURE':
      return {
        ...state,
        isLoading: false,
      };
    case 'SESSION_GET_USER_SUCCESS':
      return {
        ...state,
        user: action.user,
        isLoading: false,
      };
    case 'SESSION_SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'SESSION_UNSET_USER':
      return {
        ...state,
        user: null,
      };
    case 'SESSION_NO_USER':
      return {
        ...state,
        user: null,
        error: {
          message: action.message,
        },
        isLoading: false,
      };
    case 'SESSION_SET_TOKENS':
      return {
        ...state,
        tokens: action.tokens,
        isAuthenticated: true,
      };
    case 'SESSION_LOGOUT_REQUEST':
      return {
        ...state,
        logoutInProgress: true,
        error: null,
      };
    case 'SESSION_LOGOUT_FAILURE':
      return {
        ...state,
        logoutInProgress: false,
        error: action.error,
      };
    case 'SESSION_LOGOUT_SUCCESS':
      return {
        ...state,
        isAuthenticated: false,
        isAuthorized: false,
        logoutInProgress: false,
        user: null,
        tokens: null,
        error: null,
        access: null,
      };
    case 'SESSION_LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        error: null,
      };
    case 'SESSION_UNSET_TOKENS':
      return {
        ...state,
        tokens: null,
      };
    case 'SESSION_SET_ACCESS':
      return {
        ...state,
        user: state.user && action.access
          ? {
            ...state.user,
            resourceAccess: action.access,
          }
          : null,
        access: action.access, // TODO: use resourceAccess and remove this
        isAuthorized: Boolean(action.access),
      };
    default:
      return state;
  }
};
