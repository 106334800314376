import React, {Fragment, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Grid, Flex} from '@signicat/designi/components/layout';
import {Empty} from '@signicat/designi/components/containers';
import {Button} from '@signicat/designi/components/buttons';
import {Select, Checkbox} from '@signicat/designi/components/inputs';
import {Paragraph} from '@signicat/designi/components/text';
import styled from 'styled-components';
import {loadStripe} from '@stripe/stripe-js';
import {SigniappError} from '@signiapp/helpers/errors';
import {useNotification} from '@signicat/designi/components/messages';
import {Spinner} from '@signicat/designi/components/loaders';
import {t} from 'core/helpers/i18n';
import {initPaymentMethodSetup} from 'core/store/actions/app';
import {initOnboarding, getOnboardingFlowStatus, getOnboardingSignatureUrl, sendOnboardingInvoice} from 'core/store/actions/onboarding';
import {paymentMethodsSelector, planByIdSelector} from 'core/store/selectors/billing';
import core from 'core/services/core';
import Plan from './components/Plan.js'
import UpgradeFlowHeading from './components/Heading.js'
import {COUNTRIES} from 'core/constants';

const StyledPlan = styled(Plan)`
@media only screen and (max-width: 768px) {
  display:none;
}
`

const UnderlineText = styled('span')`
  text-decoration:underline;
  font-weight:500;
`

const CheckboxContainer = styled('div')`
  display:flex;
  flex-direction:row;
  align-items:center;
  font-size:14px;
`

const SegmentContainer = styled('div')`
  display: flex;
  align-self:center;
  flex-direction: row;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid #ccc;
  @media only screen and (min-width: 769px) {
    width:720px;
  }
`;

const FormContainer = styled('div')`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  border-left: 0;
  padding: 30px;
`;

const SignFrame = styled('iframe')`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 100;
  border: none;
`

const StyledSpinnerBackground = styled('div')`
  background-color: #fff;
  border-radius: 100px;
  padding: 2px;
`

const Details = (props) => {
  const {enqueueNotification} = useNotification();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const paymentMethods = useSelector(paymentMethodsSelector);
  const flowInstanceId = useSelector(state => state.upgrade.flowInstanceId);
  const selectedPlan = new URLSearchParams(location.search).get('plan');
  const setupId = new URLSearchParams(location.search).get('setup_id');
  const plan = useSelector(planByIdSelector(selectedPlan));
  const currency = useSelector(state => state.app.currency);
  const billingInfo = useSelector(state => state.app.billingInfo)
  const selectedEids = useSelector(state => state.upgrade.selectedEids)
  const [terms, setTerms] = useState(false)
  const [startedInvoiceProcess, setStartedInvoiceProcess] = useState(false)
  const [signUrl, setSignUrl] = useState(null)
  const [urlPoll, setUrlPoll] = useState(null)
  const [done, setDone] = useState(false)
  if (!selectedPlan) {
    return (
      <Empty
        emoji="❓"
        heading={t('upgrade_no_plan_selected_heading')}
        message={t('upgrade_no_plan_selected_message')}
      />
    );
  }

  useEffect(() => {
    const handler = async event => {
      let signSuccess = false
      if (event && event.data && typeof event.data === "string") {
        try {
          const data = JSON.parse(event.data)
          if (data && data.type && data.type === "sign_success") {
            signSuccess = true
            await core.subscribeToPlan(selectedPlan);
            await dispatch({
              type: "SET_TRIAL", trial: {
                isTrial: false
              }
            })
            setDone(true)
          }
        }
        catch (err) {
          if (signSuccess) {
            setSignUrl(false)
            setStartedInvoiceProcess(false)
            enqueueNotification(t('plan_subscription_error'), {
              variant: 'error'
            });
          }
        }
      }
    }

    window.addEventListener("message", handler)
    return () => {
      window.removeEventListener("message", handler);
    }
  }, [])

  useEffect(() => {
    //once url set, stop polling
    if (signUrl) {
      clearInterval(urlPoll)
    }
  }, [signUrl]);

  useEffect(() => {
    if (flowInstanceId) {
      let pollCount = 0, url;
      setUrlPoll(setInterval(async () => {
        if (pollCount < 19 && !url) {
          pollCount += 1
          try {
            url = await dispatch(getOnboardingSignatureUrl(flowInstanceId))
            setSignUrl(url)
          }
          catch {}
        }
        else if (polCount >= 19 && !url) {
          setStartedInvoiceProcess(false);
          enqueueNotification(t('plan_subscription_error'), {
            variant: 'error'
          });
          if (urlPoll) {
            clearInterval(urlPoll)
          }
        }
        else if (!signUrl) {
          setSignUrl(url);
        }
      }, 2000))
    }
  }, [flowInstanceId])

  useEffect(() => {
    const startInvoiceProcess = async () => {
      try {
        const countryCode = COUNTRIES.find(c => c.name.toLowerCase() == billingInfo.address.country.toLowerCase()).code.toLowerCase()
        await dispatch(initOnboarding(selectedPlan, selectedEids.map(eid => eid.id), countryCode));
      }
      catch {
        setStartedInvoiceProcess(false)
        enqueueNotification(t('plan_subscription_error'), {
          variant: 'error'
        });
      }
    }
    if (startedInvoiceProcess) {
      startInvoiceProcess()
    }
  }, [startedInvoiceProcess])

  useEffect(() => {
    if (setupId) {
      core.confirmPaymentMethodSetup(setupId).then(() => {
        // console.log('Confirmed!');
      }).catch(() => {
        throw SigniappError('Confirming Payment method failed');
      });
    }
  }, [setupId]);

  const paymentOptions = [
    // ...paymentMethods.map((p) => ({
    //   value: p.id,
    //   text: `${p.mask} (${t(`payment_card_brand_${p.brand}`)})`,
    // })),
    {
      value: -1,
      text: t('payment_invoice')
    }
  ];

  const handleToggleTerms = () => {
    setTerms(!terms)
  }

  const handlePaymentMethodChange = () => {
    // console.warn('Not implemented!');
  };

  const handleAddPaymentMethodClick = async () => {
    const redirectBaseUri = `${location.origin}${location.pathname}`;
    const result = await dispatch(initPaymentMethodSetup({
      successUrl: `${redirectBaseUri}?plan=${selectedPlan}&result=success`,
      cancelUrl: `${redirectBaseUri}?plan=${selectedPlan}&result=cancel`,
    }));
    const stripe = await loadStripe(result.publishableKey);

    stripe.redirectToCheckout({
      sessionId: result.checkoutSessionId,
    });
  };

  const handleSubmitClick = async () => {
    setStartedInvoiceProcess(true)
  };

  const getTagValue = (eid, key) => {
    const tag = eid.tags.find(t => t.includes(key))
    if (tag) {
      const result = tag.split(":")
      result.shift()
      return result.join(":")
    }
    return ""
  }

  const getEIDImgLogo = (eid) => {
    if (!eid) {
      return null
    }
    return <img style={{maxWidth: "125px", height: "40px", marginTop: "10px", marginBottom: "10px"}} src={getTagValue(eid, "icon")} />
  }

  return (
    !done ? <Fragment>
      <UpgradeFlowHeading>{t('upgrade_plans_heading')}</UpgradeFlowHeading>
      <SegmentContainer>
        <StyledPlan key={plan.id}
          planId={plan.id}
          price={plan.view.prices[currency.toLowerCase()]}
          priceTerms={plan.priceTerms}
          currency={currency}
          features={plan.view.features}
          actionText={t('upgrade_buy_plan', {plan: t(`upgrade_plans_${plan.id}_name`)})}
          highlighted={plan.view.highlighted}
          featuresHeading={plan.featuresHeading}
          featuresList={plan.view.features}
          featuresPricing={plan.view.pricingSamples}
          requireSales={plan.view.requireSales}
          isInDetailsView={true}></StyledPlan>
        <FormContainer>
          <div>
            <Paragraph css="font-weight: 500;font-size:16px;margin-top:6vh;margin-top: 12px;">{t('upgrade_selected_eids')}</Paragraph>
            <div style={{marginBottom: "12px"}}>
              <Flex container direction="row" justifyContent="space-evenly">
                {getEIDImgLogo(selectedEids[0])}
                {getEIDImgLogo(selectedEids[1])}
              </Flex>
              <Flex container direction="row" justifyContent="space-evenly">
                {getEIDImgLogo(selectedEids[2])}
                {getEIDImgLogo(selectedEids[3])}
              </Flex>
            </div>
            <Paragraph css="font-weight: 500;font-size:16px;margin-top:7vh;margin-bottom:20px">{t('upgrade_payment_subheader')}</Paragraph>
            <Paragraph css="margin-bottom: 36px;">
              {t('upgrade_payment_choose_preferred_payment')}
            </Paragraph>
            <Grid container spacing={2} direction="column">
              {paymentOptions.length
                ? (
                  <Grid item>
                    <Select
                      label={t('upgrade_paymentmethod_select_field_label')}
                      options={paymentOptions}
                      value={paymentOptions.length ? paymentOptions[0].value : ''}
                      fullWidth
                      onChange={handlePaymentMethodChange}
                    />
                  </Grid>
                )
                : null}
              {/* <Grid item>
                  <Button primary size="lg" css="font-size:16px" onClick={handleAddPaymentMethodClick} fullWidth>
                    {t('upgrade_payment_add_card_details_external')}
                  </Button>
                </Grid>
                <Grid item>
                  <Paragraph style={{marginBottom: '17vh'}}>
                    {t('upgrade_payment_redirected_to_provider_warning')}
                  </Paragraph>
                </Grid> */}
            </Grid>
          </div>
          <div>
            <Grid item>
              <CheckboxContainer>
                <Checkbox onChange={handleToggleTerms} color="primary"></Checkbox>
                <Paragraph><a href="https://developer.signicat.com/express/docs/terms-conditions/" target="_blank">{t('upgrade_payment_accept_terms')}</a></Paragraph>
              </CheckboxContainer>
            </Grid>
            <Grid>
              <Button
                color="primary"
                fullWidth
                onClick={handleSubmitClick}
                disabled={paymentOptions.length === 0 || !terms || startedInvoiceProcess}
              >
                {startedInvoiceProcess ? <StyledSpinnerBackground><Spinner fullscreen /></StyledSpinnerBackground> : t('upgrade_subscription_confirmation_submit')}
              </Button>
            </Grid>
          </div>
        </FormContainer>
      </SegmentContainer>
      {signUrl && <SignFrame src={signUrl}></SignFrame>}
    </Fragment>
      : <Empty
        emoji="⭐️"
        heading={t('upgrade_done_success_heading')}
        message={t('upgrade_done_success_message')}
        handleActionClick={() => history.push('/')}
        action={t('upgrade_done_success_action_button_text')}
      />
  );
};

export default Details;
