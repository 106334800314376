import {core} from 'core/services';

export const getPlans = () => async (dispatch) => {
  const plans = await core.getPlans();

  dispatch({
    type: 'BILLING_GET_PLANS_SUCCESS',
    plans: plans.filter((plan) => (
      ['basic', 'advanced', 'enterprise'].includes(plan.id)
    )),
  });

  return plans;
};

export const getFeatures = () => async (dispatch) => {
  const features = await core.getFeatures();
  dispatch({
    type: 'BILLING_GET_FEATURES_SUCCESS',
    features,
  });

  return features;
};


export default {
  getPlans,
  getFeatures
};
