import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {Button} from '@signicat/designi/components/buttons';
import {Grid} from '@signicat/designi/components/layout';
import {Icon} from '@signicat/designi/components/icons';
import {Label} from '@signicat/designi/components/forms';
import {Segment} from '@signicat/designi/components/containers';
import {Div} from '@signicat/designi/components/layout';
import {HelpText} from '@signicat/designi/components/text';
import {Select} from '@signicat/designi/components/inputs';

import {saveAppLanguage} from 'core/store/actions/app';
import {t} from 'core/helpers/i18n';
import {LANGUAGES} from 'core/constants';
import {appLanguageSelector} from 'core/store/selectors/app';

const Language = () => {
  const dispatch = useDispatch();
  const appLanguage = useSelector(appLanguageSelector);

  const [selectedLanguage, setSelectedLanguage] = useState(appLanguage);

  const languageOptions = LANGUAGES.PORTAL_GUI.map((languageCode) => ({
    value: languageCode,
    text: t(`lang_${languageCode}`),
  })).sort((a, b) => (a.text > b.text ? 1 : a.text === b.text ? 0 : -1));

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleSaveSettingsClick = () => {
    dispatch(saveAppLanguage(selectedLanguage));
  };

  return (
    <Div>
      <Segment divided outlined direction="column" marginBottom="32px">
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} direction="row" alignItems="stretch">
              <Grid item style={{flex: '0 0 auto', alignItems: 'flex-start'}}>
                <Icon name="language" style={{paddingLeft: '4px', paddingTop: '12px'}} />
              </Grid>
              <Grid item style={{flex: '1 0 0'}}>
                <Grid container direction="column" justifyContent="center">
                  <Label>{t('app_settings_language_input_label')}</Label>
                  <HelpText style={{marginTop: '2px'}}>{t('app_settings_language_input_helptext')}</HelpText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={9}>
                <Select
                  id=""
                  testSelector="language"
                  options={languageOptions}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  id="portal_language_save"
                  onClick={handleSaveSettingsClick}
                  color="primary"
                  style={{flexGrow: 1}}
                >
                  {t('button_text_save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Segment>
    </Div>
  );
};

export default Language;
