import React from 'react';
import {useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import path from 'path';

import {Empty} from '@signicat/designi/components/containers';

import {t} from 'core/helpers/i18n';
import {ROUTES} from 'core/constants';

const {sessionStorage} = window;

const Root = (props) => {
  const {core} = useSelector((state) => (state));

  // Prevent redirect loop in case a module would redirect to /
  const lastVisit = Number(sessionStorage.getItem('signiapp.lcrv'));
  const timeSinceLastVisit = lastVisit
    ? Date.now() - lastVisit
    : null;
  const redirectCount = timeSinceLastVisit && timeSinceLastVisit < 2000
    ? Number(sessionStorage.getItem('signiapp.lcrvc') || 1)
    : 0;

  const redirectCountLimit = 2;

  if (timeSinceLastVisit && redirectCount >= redirectCountLimit) {
    sessionStorage.removeItem('signiapp.lcrv');
    sessionStorage.removeItem('signiapp.lcrvc');

    return (
      <Empty
        emoji="🎠"
        heading={t('core_error_loop_heading')}
        message={t('core_error_loop_message')}
      />
    );
  } else {
    sessionStorage.setItem('signiapp.lcrv', Date.now());
    sessionStorage.setItem('signiapp.lcrvc', redirectCount + 1);
  }
  const signature = core.modules.find(m => m.id == "signature")
  if (signature) {
    return (
      <Redirect
        to={path.join(ROUTES.MODULES, signature.meta.pathSegment)}
      />
    );
  }

  return (
    <ul>
      {core.modules.map((module) => (
        <li key={module.id}>
          <Link to={path.join(ROUTES.MODULES, module.meta.pathSegment)}>
            {module.meta.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Root;
