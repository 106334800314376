import {handleResponse} from '@signiapp/helpers/api';

export const postHubspotForm = async (portalId, formId, data) => (
  fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(handleResponse)
);

export const microsoftClientExists = async (discoveryEndpoint) => {
  return fetch(discoveryEndpoint, {
      method: 'GET'
  }).then((response) => {
      if (response.status >= 400) {
          return false;
      } else {
          return true;
      }
  })
};

export default {
  postHubspotForm,
  microsoftClientExists,
};
