import {
  compareAsc as compareDateAsc,
  compareDesc as compareDateDesc,
} from 'date-fns';

export const compareString = (options) => {
  const {valueSelector, direction} = options;

  if (direction === 'desc') {
    return (a, b) => {
      return (valueSelector(b) || '').localeCompare(valueSelector(a) || '');
    };
  }

  return (a, b) => {
    return (valueSelector(a) || '').localeCompare(valueSelector(b) || '');
  };
};

export const compareNumber = (options) => {
  const {valueSelector, direction} = options;

  if (direction === 'desc') {
    return (b, a) => {
      const numberA = Number(valueSelector(a));
      const numberB = Number(valueSelector(b));

      if (numberA === numberB || (isNaN(numberA) && isNaN(numberB))) {
        return 0;
      } else if (isNaN(numberA)) {
        return -1;
      } else if (isNaN(numberB)) {
        return 1;
      }

      return numberA - numberB;
    };
  }

  return (a, b) => {
    const numberA = Number(valueSelector(a));
    const numberB = Number(valueSelector(b));

    if (numberA === numberB || (isNaN(numberA) && isNaN(numberB))) {
      return 0;
    } else if (isNaN(numberA)) {
      return -1;
    } else if (isNaN(numberB)) {
      return 1;
    }

    return numberA - numberB;
  };
};

export const compareDate = (options) => {
  const {valueSelector, direction} = options;

  if (direction === 'desc') {
    return (a, b) => compareDateDesc(new Date(valueSelector(a)), new Date(valueSelector(b)));
  }

  return (a, b) => compareDateAsc(new Date(valueSelector(a)), new Date(valueSelector(b)));
};

export const compareIndex = (options) => {
  const {valueSelector, direction, list} = options;

  if (direction === 'desc') {
    return (b, a) => {
      const indexOfA = list.indexOf(valueSelector(a));
      const indexOfB = list.indexOf(valueSelector(b));

      if (indexOfA === indexOfB) {
        return 0;
      } else if (indexOfA === -1) {
        return -1;
      } else if (indexOfB === -1) {
        return 1;
      }
      return indexOfA - indexOfB;
    };
  }

  return (a, b) => {
    const indexOfA = list.indexOf(valueSelector(a));
    const indexOfB = list.indexOf(valueSelector(b));

    if (indexOfA === indexOfB) {
      return 0;
    } else if (indexOfA === -1) {
      return -1;
    } else if (indexOfB === -1) {
      return 1;
    }
    return indexOfA - indexOfB;
  };
};

const sort = (items, sorting) => {
  switch (sorting.type) {
    case 'string':
      return [...items].sort(compareString(sorting));
    case 'number':
      return [...items].sort(compareNumber(sorting));
    case 'index':
      return [...items].sort(compareIndex(sorting));
    case 'date':
      return [...items].sort(compareDate(sorting));
    default:
      return items;
  }
};

export default sort;
