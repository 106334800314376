import styled from 'styled-components';
import {Heading} from '@signicat/designi/components/text';

const UpgradeFlowHeading = styled(Heading)`
  text-align: center;
  margin-bottom: 52px;
  font-size: 32px;
  font-weight:400;
  padding-top: 12px;
`;

export default UpgradeFlowHeading;
