import store from 'core/store';
import {dictionarySelector, enDictionarySelector, languageSelector} from 'core/store/selectors/app';
import {setTranslationMissing} from 'core/store/actions/app';

const translate = (key, data = null) => {
  const state = store.getState();

  const dictionary = dictionarySelector(state);

  let translation = dictionary ? dictionary[key] : null;
  if (!dictionary) {
    return `{{${key}}}`;
  } else if (!translation && !state.user.isChangingLanguage) {
    if (!['missing_translation_notification', 'user_is_offline'].includes(key) && !state.app.translationMissing) {
      store.dispatch(setTranslationMissing(true));
    }
    const enDictionary = enDictionarySelector(state);
    if (!enDictionary) {
      return `{{${key}}}`;
    }
    translation = enDictionary ? enDictionary[key] : null;
    if (!translation) {
      return `{{${key}}}`;
    }
  }

  const translateWithVariables = (string, vars) => {
    const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
    function isDefined(value) {
      return typeof value !== 'undefined' && value !== null;
    }
    function interpolateString(expr, params) {
      return expr.replace(templateMatcher, (substring, b) => {
        let r = getValue(params, b);
        return isDefined(r) ? r : substring;
      });
    }
    function getValue(target, key) {
      let keys = typeof key === 'string' ? key.split('.') : [key];
      key = '';
      do {
        key += keys.shift();
        if (isDefined(target) && isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
          target = target[key];
          key = '';
        } else if (!keys.length) {
          target = undefined;
        } else {
          key += '.';
        }
      } while (keys.length);
      return target;
    }

    return interpolateString(string, vars);
  };
  if (!data) {
    return translation;
  }
  return translateWithVariables(translation, data);
};

export {translate as t};

export default translate;
