import dotProp from 'dot-prop-immutable';

const defaultState = {
  paymentMethods: {
    isLoaded: false,
    data: [],
  },
  trial: null,
  billedPlans: null,
  isSelfServiceEligible: false,
  plans: {
    data: [],
  },
  //Currently used only to list EIDs
  features: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'BILLING_GET_PAYMENT_METHODS_SUCCESS':
      return {
        ...state,
        paymentMethods: {
          isLoaded: true,
          data: [...action.paymentMethods],
        },
      };
    case 'BILLING_ADD_PAYMENT_METHOD':
      return dotProp.set(
        state,
        'paymentMethods.data',
        [...state.paymentMethods, action.paymentMethod]
      );
    case 'BILLING_GET_PLANS_SUCCESS':
      return dotProp.set(
        state,
        'plans.data',
        action.plans
      );
    case 'BILLING_GET_FEATURES_SUCCESS':
      return dotProp.set(
        state,
        'features',
        action.features
      );
    case 'BILLING_GET_STATUS_SUCCESS':
      return {
        ...state,
        trial: {
          ...state.trial || {},
          isTrial: action.billingStatus?.isTrial,
        },
        billedPlans: action.billingStatus.plans,
        isSelfServiceEligible: action.billingStatus.isSelfServiceEligible
      };
    case 'SET_APP':
      return {
        ...state,
        trial: {
          ...state.trial || {},
          expires: action.app?.trial?.expires,
          hasEnded: new Date(action.app?.trial?.expires) < new Date(),
        },
      };
    case 'SET_TRIAL':
      return {
        ...state,
        trial: action.trial,
      };
    default:
      return state;
  }
};

