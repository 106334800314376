import {UserManager} from 'oidc-client';

import {OIDC} from 'core/constants/config.js';

class Session {
  constructor() {
    this.userManager = null;
    this.auth = null;
  }

  setAuth = (user) => {
    if (user === null) {
      return null;
    }

    const auth = {
      accessToken: user.access_token,
      tokenType: user.token_type,
      scope: user.scope,
    };

    this.auth = auth;

    return auth;
  }

  initUserManager(config) {
    this.userManager = new UserManager({
      authority: config.authority,
      client_id: config.clientId,
      scope: config.scope,
      response_type: 'code',
      redirect_uri: OIDC.LOGIN_REDIRECT_URI,
      post_logout_redirect_uri: OIDC.LOGOUT_REDIRECT_URI,
      popup_redirect_uri : OIDC.LOGIN_POPUP_URI,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: OIDC.RENEW_URI,
      silentRequestTimeout: 20000,
      revokeAccessTokenOnSignout: true,
    });

    this.userManager.getUser()
      .then(this.setAuth)
      .catch((err) => {
        console.error('session:initUserManager:getUser', err);
      });
  }

  signin() {
    if (this.userManager === null) {
      throw new Error('SESSION_NOT_AVAILABLE');
    }

    return this.userManager.signinRedirect()
      .then(this.setAuth)
      .catch((err) => {
        console.error(err);

        switch (err.message) {
          case 'client_id':
            throw new Error('OIDC_CLIENT_ID_MISSING');
          case 'Popup window closed':
            throw new Error('OIDC_POPUP_CLOSED');
          default:
            throw new Error('OIDC_UNKNOWN_ERROR');
        }
      });
  }

  addOpenIdMethod() {
    if (this.userManager === null) {
      throw new Error('SESSION_NOT_AVAILABLE');
    }

    this.userManager.signinPopup()
      .then((user) => {

        return user;
      }).catch((err) => {
        console.error(err);

        switch (err.message) {
          case 'client_id':
            throw new Error('OIDC_CLIENT_ID_MISSING');
          case 'Popup window closed':
            throw new Error('OIDC_POPUP_CLOSED');
          default:
            throw new Error('OIDC_UNKNOWN_ERROR');
        }
      });
  }
}

const session = new Session();

export default session;
