import React, {Fragment, useState, useEffect} from 'react';
import path from 'path';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, Link} from 'react-router-dom';
import {Button} from '@signicat/designi/components/buttons';
import {TextField, Autocomplete} from '@signicat/designi/components/inputs';
import {Segment} from '@signicat/designi/components/containers';
import {Table, Tr, Td} from '@signicat/designi/components/tables';
import {Heading, Paragraph} from '@signicat/designi/components/text';
import {Flex, Row, Grid} from '@signicat/designi/components/layout';
import {Form, Field} from '@signicat/designi/components/forms';
import {useConfirmation} from '@signicat/designi/components/overlays/ConfirmationService';
import {useNotification} from '@signicat/designi/components/messages';
import {Empty} from '@signicat/designi/components/containers';
import {getCoreAdvancedSettings} from '../../../store/actions/app';

import {t} from 'core/helpers/i18n';
import {app as appSelectors} from 'core/store/selectors';
import {session as sessionSelectors} from 'core/store/selectors';
import {
  addUserToGroup,
  removeUserFromGroup,
  saveGroup,
  deleteGroup,
} from 'core/store/actions/app';
import {groupValidator} from 'core/store/validators/app';
import {ROUTES} from 'core/constants';

import {Titlebar} from './UserAccessManagement.js';


const GroupEdit = (props) => {
  const {featureEnabled} = props
  if (!featureEnabled) return null;
  const confirm = useConfirmation();
  const {enqueueNotification, closeNotification} = useNotification();
  const dispatch = useDispatch();
  const accessLevel = useSelector(sessionSelectors.accessLevelSelector);
  const {id} = useParams();

  const [editMode, setEditMode] = useState(false);

  const [validateAll, setValidateAll] = useState(false);

  const coreAdvancedSettings = useSelector(appSelectors.coreAdvancedSettingsSelector);

  const group = useSelector(appSelectors.groupByIdSelector(id));
  const appUsers = useSelector(appSelectors.usersSelector);
  const users = useSelector(appSelectors.usersByGroupSelector(id));
  const usersNotInGroup = useSelector(appSelectors.usersNotInGroupSelector(id));
  const usersOptions = usersNotInGroup.map((user) => ({
    value: user.id,
    text: user.data.profile.name,
    email: user.data.profile.email,
  }));

  useEffect(() => {
    dispatch(getCoreAdvancedSettings())
  }, []);

  const handleRemoveUserClick = (userId) => (e) => {
    dispatch(removeUserFromGroup(userId, id));
  };

  const validation = groupValidator(group.data);

  const groupAdmins = useSelector(appSelectors.groupAdminsUsersSelector(id));

  const appAdmins = useSelector(appSelectors.adminsSelector);

  const adminsOptions = appUsers.map((user) => ({
    value: user.id,
    text: user.data.profile.name,
  }));
  const adminsValue = (groupAdmins || []).map((item) => (item.id));

  /* State */

  const [userAddInputValue, setUserAddInputValue] = useState('');


  /* Action handlers */

  const handleNameChange = (e) => {
    dispatch({type: 'SET_GROUP_NAME', groupId: id, name: e.target.value});
  };

  const handleAdminsChange = (e, value) => {
    dispatch({type: 'SET_GROUP_ADMINS', groupId: id, admins: value});
  };

  const handleSaveClick = (e) => {
    dispatch(saveGroup(id)).then(() => setEditMode(false));
  };

  const handleDeleteClick = () => {
    confirm({
      title: t('uam_group_delete_prompt_heading'),
      message: t('uam_group_delete_prompt_message', {groupName: group.data.displayName}),
      variant: 'confirm',
      buttonConfirm: t('button_text_yes'),
      buttonCancel: t('button_text_no'),
    })
      .then(() => dispatch(deleteGroup(id)))
      .catch(() => enqueueNotification(t('uam_group_delete_error_message'), {
        variant: 'error',
      }));
  };

  const filterOnFullEmail = (value, options) => {
    let result = [];

    if (!options) {
      return result;
    }

    options.map(i => {
      if (i.email &&
          typeof value === 'string' &&
          typeof i.email === 'string' &&
          value.localeCompare(i.email, undefined, {sensitivity: 'accent'}) === 0) {

       result.push(i);
       return;
      }
    });

    return result;
  };

  /* Render */

  if (group.isDeleted) {
    return (
      <Empty
        emoji="✅"
        heading={t('uam_group_delete_success_heading')}
        message={t('uam_group_delete_success_message')}
        actionComponent={
          <Button
            color="primary"
            forwardedAs={Link}
            to={ROUTES.USER_ACCESS_MANAGEMENT}
          >
            {t('uam_link_overview')}
          </Button>
        }
      />
    );
  }

  return (
    <div style={{marginTop: '36px'}}>
      <Titlebar>
        <Flex>
          <Button
            variant="transparent"
            color="secondary"
            icon="back"
            forwardedAs={Link}
            to={ROUTES.USER_ACCESS_MANAGEMENT}
          >
            {t('uam_link_overview')}
          </Button>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Heading
            level={4}
            style={{paddingBottom: 0, marginBottom: 0}}
          >
            {group.data.displayName || group.data.group}
          </Heading>
        </Flex>
        <Flex></Flex>
      </Titlebar>
      <Segment outlined space="xl">
        {editMode
          ? (
            <Form validation={validation} state={{validateAll: validateAll}}>
              <Grid container direction="row" spacing={2} justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Field helpText={t('uam_group_create_name_helptext')}>
                        <TextField
                          label={t('uam_group_name_label')}
                          value={group?.data?.displayName || ''}
                          onChange={handleNameChange}
                        />
                      </Field>
                    </Grid>
                    <Grid item>
                      <Field helpText={t('uam_group_create_admins_helptext')}>
                        <Autocomplete
                          multiple
                          options={adminsOptions}
                          value={adminsValue}
                          label={t('uam_group_admins_label')}
                          onChange={handleAdminsChange}
                        />
                      </Field>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          {accessLevel === 'Admin' && (
                            <Button
                              color="negative"
                              size="sm"
                              icon="delete"
                              variant="outlined"
                              onClick={handleDeleteClick}
                            >
                              {t('uam_group_delete_button_text')}
                            </Button>
                          )}
                        </Grid>
                        <Grid item>
                          <Button
                            color="secondary"
                            variant="transparent"
                            size="sm"
                            onClick={() => setEditMode(false)}
                          >
                            {t('button_text_cancel')}
                          </Button>
                          <Button onClick={handleSaveClick} size="sm" color="primary">
                            {t('button_text_save')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
          : (
            <Flex direction="column">
              <Flex direction="column" style={{marginBottom: '12px'}}>
                <strong style={{fontWeight: 500}}>{t('uam_group_name_label')}</strong>
                <Paragraph>
                  {group?.data?.displayName}
                </Paragraph>
              </Flex>
              <Flex direction="column" style={{marginBottom: '24px'}}>
                <strong style={{fontWeight: 500}}>{t('uam_group_admins_label')}</strong>
                <Paragraph>
                  {groupAdmins.length
                    ? `${groupAdmins.map((groupAdmin) => (
                      groupAdmin.data.profile.name
                    )).join(', ')}. `
                    : t('uam_group_no_admins_long')
                  }{' '}
                  {groupAdmins.length
                    ? t('uam_group_can_also_be_managed_by_portal_admins')
                    : t('uam_group_can_instead_be_managed_by_portal_admins')
                  }{' '}
                  {appAdmins.map((admin) => (admin.data.profile.name)).join(', ')}.
                </Paragraph>
              </Flex>
              <Row horizontal="start">
                <Button
                  color="secondary"
                  onClick={() => setEditMode(true)}
                  size="sm"
                  variant="outlined"
                  icon="settings"
                >
                  {t('uam_group_manage_button_text')}
                </Button>
              </Row>
            </Flex>
          )
        }
      </Segment>
      <Flex direction="column">
        <Heading level={3}>
          {t('uam_group_manage_members_heading')}
        </Heading>
        <Paragraph>
          {t('uam_group_manage_members_helptext')}
        </Paragraph>
        <Table style={{marginBottom: '12px'}}>
          <thead>
            <tr>
              <th>{t('first_name')}</th>
              <th>{t('last_name')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {users.length
              ? users.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.data.profile.firstName}</Td>
                  <Td>{user.data.profile.lastName}</Td>
                  <Td>
                    <Button
                      size="xs"
                      color="negative"
                      variant="outlined"
                      onClick={handleRemoveUserClick(user.id)}
                      title={t('uam_group_user_remove_prompt')}
                    >
                      {t('button_text_remove')}
                    </Button>
                  </Td>
                </Tr>
              ))
              : (
                <tr>
                  <td colSpan={3}>
                    {t('uam_group_users_table_empty')}
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
        {accessLevel !== 'Admin' && coreAdvancedSettings?.settingsSet?.groupAdminInviteRestriction ?
            <Autocomplete
                options={usersOptions}
                label={t('uam_group_adduser_input_label')}
                onInputChange={(e, value) => setUserAddInputValue(value)}
                inputValue={userAddInputValue}
                onChange={(e, val) => {
                  dispatch(addUserToGroup(val, id));
                  setUserAddInputValue('');
                }}
                value={''}
                noOptionsText={t('uam_group_adduser_input_no_options_by_email')}
                filterOptions={(options, {inputValue}) => filterOnFullEmail(inputValue, options)}
            /> :
            <Autocomplete
                options={usersOptions}
                label={t('uam_group_adduser_input_label')}
                onInputChange={(e, value) => setUserAddInputValue(value)}
                inputValue={userAddInputValue}
                onChange={(e, val) => {
                  dispatch(addUserToGroup(val, id));
                  setUserAddInputValue('');
                }}
                value={''}
                noOptionsText={t('uam_group_adduser_input_no_options')}
            />
        }
      </Flex>
    </div>
  );
};

export default GroupEdit;
