import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Icon} from '@signicat/designi/components/icons';
import {Tooltip} from '@signicat/designi/components/overlays';
import {t} from 'core/helpers/i18n';
import {getAppProtected} from 'core/store/actions/app';

const Badge = styled('div')`
  color: #565656;
  font-size: 1.1rem;
  font-weight: 300;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items:center;
  cursor: default;
  
  svg {
    position: relative;
    margin-bottom:4px;
    margin-right: 4px;
  }
`;

const Wrapper = styled('div')`
  display: inherit;
  position: relative;

  ${(props) =>
    props.isEnabled
      ? ''
      : `
      & > *:nth-child(1){
        padding: 10px;
      }
      & > *:nth-child(2){
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        pointer-events: none;
        padding: 10px;
        opacity: 0.4;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.05);
        z-index: 10;
        content: '';
      }
    `}
`;

const Feature = (props) => {
  const {
    children,
    featureId,
    environments: enabledEnvs,
    includeLabel,
    onFeatureToggle,
    ...rest
  } = props;

  const dispatch = useDispatch();
  const app = useSelector(state => state.app);
  const [enabled, setEnabled] = useState(null);

  useEffect(() => {
    if (!app || !app.protected || !app.protected.features) {
      dispatch(getAppProtected())
    }
  }, [])

  useEffect(() => {
    if (onFeatureToggle && enabled !== null) {
      onFeatureToggle(enabled);
    }
  }, [enabled])

  useEffect(() => {
    toggleFeature()
  }, [app?.protected])

  const toggleFeature = () => {
    //features length 0 means it is a trial portal
    const isEnabled = app && app.protected && app.protected.features && (app.protected.features.includes(featureId) || app.protected.features.length === 0)
    setEnabled(isEnabled)
  }

  return (
    <Wrapper isEnabled={enabled} {...rest}>
      {!enabled &&
        <Tooltip
          anchor={
            <Badge>
              <Icon name="lock" size="m" />
              {includeLabel && <span>{t('feature_locked_label')}</span>}
            </Badge>
          }
          arrow
        >
          {t('feature_not_available_generic_message')}
        </Tooltip>
      }
      {children}
    </Wrapper>
  );
};

Feature.propTypes = {
  featureId: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.oneOf(['dev', 'test', 'prod'])),
  includeLabel: PropTypes.bool,
};

Feature.defaultProps = {
  featureId: null,
  environments: [],
  includeLabel: true,
};

export default Feature;
