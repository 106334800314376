import {UserManager} from 'oidc-client';

import {LoginRequired} from 'helpers/errors';
import {EventEmitter} from 'helpers/classes';

class Session extends EventEmitter {
  constructor() {
    super(arguments);

    this.userManager = null;
    this.auth = null;
  }

  get isAuthenticated() {
    return Boolean(this.auth);
  }

  setAuth = (user) => {
    if (user === null) {
      return null;
    }

    const auth = {
      accessToken: user.access_token,
      tokenType: user.token_type,
      scope: user.scope,
    };

    this.auth = auth;

    return auth;
  };

  unsetAuth = () => {
    this.auth = null;
  };

  initUserManager(config) {
    this.userManager = new UserManager({
      authority: config.authority,
      client_id: config.clientId,
      scope: config.scope,
      response_type: 'code',
      redirect_uri: config.redirectUri,
      post_logout_redirect_uri: config.logoutRedirectUri,
      popup_redirect_uri: config.popupLoginUri,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri: config.silentRedirectUri,
      silentRequestTimeout: 20000,
    });

    this.userManager.events.addUserLoaded(this.setAuth);
    this.userManager.events.addUserUnloaded(this.unsetAuth);
    this.userManager.events.addAccessTokenExpired(this.handleAccessTokenExpired);

    this.userManager.getUser()
      .then(this.setAuth)
      .catch((err) => {
        console.error('session:initUserManager:getUser', err);
      });
  }

  signin(options = {}) {
    console.warn('signin() is being deprecated! Please use signinRedirect() instead.');
    const {redirectUri} = options;

    if (this.userManager === null) {
      throw new Error('SESSION_NOT_AVAILABLE');
    }

    return this.userManager.signinRedirect(
      redirectUri
        ? {state: {redirectUri: redirectUri}}
        : {}
    )
      .then(this.setAuth)
      .catch((err) => {
        console.error(err);

        switch (err.message) {
          case 'client_id':
            throw new Error('OIDC_CLIENT_ID_MISSING');
          case 'Popup window closed':
          case 'PopupWindow.navigate: Error opening popup window':
            throw new Error('OIDC_POPUP_CLOSED');
          default:
            throw new Error('OIDC_UNKNOWN_ERROR');
        }
      });
  }

  signinRedirect(options = {}) {
    const {redirectUri, ui_locales} = options;

    if (this.userManager === null) {
      throw new Error('SESSION_NOT_AVAILABLE');
    }

    const signinParams = {
      ui_locales: ui_locales || 'en'
    };
    if (redirectUri) {
      signinParams.state = {redirectUri: redirectUri};
    }

    return this.userManager.signinRedirect(signinParams)
      .catch((err) => {
        console.error(err);

        switch (err.message) {
          case 'client_id':
            throw new Error('OIDC_CLIENT_ID_MISSING');
          case 'redirect_uri':
            throw new Error('OIDC_REDIRECT_URI_MISSING');
          case 'Network Error':
            throw new Error('OIDC_NETWORK_ERROR');
          default:
            throw new Error('OIDC_UNKNOWN_ERROR');
        }
      });
  }

  renew() {
    return this.userManager.signinSilent()
      .then(this.setAuth)
      .catch((err) => {
        switch (err.message) {
          case 'login_required':
            throw LoginRequired();
            break;
          default:
            throw new Error('OIDC_RENEW_ERROR');
            break;
        }
      });
  }

  signoutRedirect(options = {}) {
    const {redirectUri, ui_locales} = options;

    if (this.userManager === null) {
      throw new Error('SESSION_NOT_AVAILABLE');
    }

    const signoutParams = {
      ui_locales: ui_locales || 'en'
    };
    if (redirectUri) {
      signoutParams.state = {redirectUri: redirectUri};
    }

    return this.userManager.signoutRedirect(signoutParams).catch((err) => {
      console.error(err);

      throw new Error('OIDC_SIGNOUT_UNKNOWN_ERROR');
    });
  }

  handleAccessTokenExpired = () => {
    this.renew()
      .catch((err) => {
        this.unsetAuth();
        this.emit('accesstokenexpired');
      })
  }

  signoutPopup() {
    return this.userManager.signoutPopup();
  }

  addOpenIdMethod(language) {
    return this.userManager.signinPopup({prompt: 'login', ui_locales: language ? language : 'en'})
      .catch((err) => {
        switch (err.message) {
          case 'client_id':
            throw new Error('OIDC_CLIENT_ID_MISSING');
          case 'Popup window closed':
            throw new Error('POPUP_CLOSED');
          default:
            throw new Error('OIDC_UNKNOWN_ERROR');
        }
      });
  }
}

const session = new Session();

export {UserManager as UserManager, Log as Log} from 'oidc-client';

export default session;
