import {Modal} from '@signicat/designi/components/overlays';
import React from 'react';
import styled from 'styled-components';
import {Column, Flex, Row} from '@signicat/designi/components/layout';
import {t} from 'core/helpers/i18n';
import {Button} from '@signicat/designi/components/buttons';
import {media} from '@signicat/designi/helpers/responsive';
import './CustomCloseButton.css';
import Cookies from 'universal-cookie'

const CreateButton = styled(Button)`
  background: #7D51DA;
  border-color: #7D51DA;
  color: #fff;
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  :hover {
    background: #5F36BC;
    border-color: #5F36BC;
  }
`;

const Header = styled(Row)`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  justify-content: center;
  color: #2A0062;
  text-align: center;
`;

const Paragraph = styled(Row)`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2a0062;
  justify-content: center;
  text-align: center;
`;

const StyledImg = styled('img')`
  width: 50%;
  height: 50%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFragment = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DokobitModal = (props) => {
  const {close} = props;

  const handleCancelClick = () => {
    const cookies = new Cookies();
    var midnight = new Date();
    midnight.setHours(23,59,59,0);
    cookies.set('showDokobitModal', 'false', {path: '/', expires: midnight});
    close();
  };

  return (
    <Modal size='md'>
      {
        <StyledFragment>
          <a className='close' onClick={() => handleCancelClick()}></a>
          <Column spacing="lg" padding="40px">
            <Header>
              {t('dokobit_login_banner_header')}
            </Header>
            <Paragraph>
              {t('login_dokobit_banner_underline_paragraph')}
            </Paragraph>
            {!media.matches.tablet.and('down') && 
            (
              <Row style={{justifyContent: 'center'}}>
                <StyledImg src='https://static.signicat.app/img/e-signing.svg'/>
              </Row>
            )}
            <Paragraph>
              {t('login_dokobit_banner_paragraph')}
            </Paragraph>
            <Row style={{justifyContent: 'center'}}>
              <CreateButton
                size="lg"
                color="primary"
                onClick={() => window.open('https://www.dokobit.com/features?gad=1&gclid=EAIaIQobChMIhY7rysmc_wIVFKnVCh29vQwTEAAYASADEgJmzPD_BwE')}
              >
                {t('login_dokobit_banner_create_button')}
              </CreateButton>
            </Row>
          </Column>

        </StyledFragment>
      }
    </Modal>
  );
};
  
export default DokobitModal;