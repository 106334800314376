
import {core} from 'core/services';

export const initOnboarding = (planId, eids, country) => async (dispatch, getState) => {
    const state = getState()
    const result = await core.initOnboarding(planId, eids, state.core.app.id, country);
    dispatch({
        type: 'SET_FLOWINSTANCEID',
        flowInstanceId: result.flowInstanceId,
    });
};
export const getOnboardingFlowStatus = (flowInstanceId) => async () => {
    const result = await core.getOnboardingFlowStatus(flowInstanceId);
    return result;
};
export const getOnboardingSignatureUrl = (flowInstanceId) => async () => {
    const result = await core.getOnboardingSignatureUrl(flowInstanceId);
    return result.internalDetails;
};
export const sendOnboardingInvoice = (flowInstanceId) => async () => {
    const result = await core.sendOnboardingInvoice(flowInstanceId);
    return result;
};


export default {
    initOnboarding,
    getOnboardingFlowStatus,
    getOnboardingSignatureUrl,
    sendOnboardingInvoice
};