import {createSelector} from 'reselect';
import * as Sentry from '@sentry/browser';

import {userLanguageSelector} from 'core/store/selectors/user';

export const appSelector = (state) => state.app;
export const translationMissingSelector = createSelector(
  appSelector,
  (state) => state.translationMissing
);
export const usersSelector = createSelector(
  appSelector,
  (state) => Object.values(state.users?.items || {}).filter((user) => (
    !user.isDeleted
  ))
);

export const usersWithErrorSelector = createSelector(
  appSelector, (state) => state.users?.error?.message
);

export const invitationsSelector = createSelector(
  appSelector, (state) => state.invitations
);

export const revokedInvitationsSelector = createSelector(
  appSelector, (state) => state.revokedInvitations
);

export const microsoftSettingsSelector = createSelector(
  appSelector, (state) => state.settings.microsoftOpenId
);

export const coreAdvancedSettingsSelector = createSelector(
  appSelector, (state) => state.settings.coreAdvanced
);

export const adminsSelector = createSelector(
  usersSelector,
  (users) => (users.filter((user) => (
    user.data.resourceAccess.accessLevel === 'Admin'
  )))
);

export const groupsStateSelector = createSelector(
  appSelector,
  (state) => state.groups
);

export const groupsSelector = createSelector(
  appSelector,
  (state) => Object.values(state.groups?.items || {})
);

export const createdGroupsSelector = createSelector(
  groupsSelector,
  (state) => state.filter((item) => item.isCreated === true && item.isDeleted !== true)
);

export const groupsCreateSelector = createSelector(
  groupsSelector,
  (groups) => groups.filter((group) => group.isCreating)
);

export const initedGroupsSelector = createSelector(
  groupsSelector,
  (state) => state.filter((item) => item.isCreating === true)
);

export const groupByIdSelector = (id) => createSelector(
  groupsStateSelector,
  (state) => state?.items[id]
);

export const groupSelector = (id) => createSelector(
  groupsStateSelector,
  (state) => state?.items[id]
);

export const usersByGroupSelector = (id) => createSelector(
  usersSelector,
  (users) => (
    users.filter((user) => (user.data.resourceAccess.groups.includes(id)))
  )
);

export const groupMembersSelector = usersByGroupSelector;

export const userByIdSelector = (id) => createSelector(
  appSelector,
  (state) => (
    state.users?.items[id]
  )
);

export const groupsByAdminSelector = (userId) => createSelector(
  groupsSelector,
  (groups) => groups.filter((group) => (
    group.data.admins
      .filter((admin) => admin.type === 'User')
      .map((admin) => admin.id)
      .includes(userId)
  ))
);

export const groupAdminsSelector = (id) => createSelector(
  groupSelector(id),
  (group) => (
    group.data.admins
      .filter((item) => (item.type === 'User'))
      .map((item) => (item.id))
  )
);

export const groupAdminsUsersSelector = (id) => createSelector(
  (state) => (state),
  groupAdminsSelector(id),
  usersSelector,
  (state, groupAdmins, users) => (
    groupAdmins
      .map((groupAdmin) => (
        state.app.users.items[groupAdmin]
      ))
      .filter((groupAdmin) => (
        groupAdmin ? true : false
      ))
  )
);

export const usersNotInGroupSelector = (id) => createSelector(
  usersSelector,
  (users) => (
    users.filter((user) => (
      user.data.resourceAccess.groups.includes(id) === false
    ))
  )
);

export const groupsByUserSelector = (userId) => createSelector(
  userByIdSelector(userId),
  groupsSelector,
  (user, groups) => user.data.resourceAccess.groups
    .map((groupId) => (
      groups.find((group) => group.id === groupId)
    ))
    .filter((group) => group)
);

export const appLanguageSelector = createSelector(
  appSelector,
  (app) => (
    app?.settings?.i18n?.language || "en"
  )
);

export const languageSelector = createSelector(
  userLanguageSelector,
  appLanguageSelector,
  (userLanguage, appLanguage) => (
    userLanguage || appLanguage
  )
);


export const dictionariesSelector = createSelector(
  appSelector,
  (app) => app.dictionaries
);

export const enDictionarySelector = createSelector(
  dictionariesSelector,
  (dictionaries) => {
    if (!dictionaries) {
      Sentry.captureException(new Error('No dictionaries found'), {
        level: 'warning',
      });
    }
    return dictionaries['en'];
  }
);

export const dictionarySelector = createSelector(
  dictionariesSelector,
  languageSelector,
  (dictionaries, language) => {
    if (!dictionaries) {
      Sentry.captureException(new Error('No dictionaries found'), {
        level: 'warning',
      });
    }
    return dictionaries[language || "en"];
  }
);

export const dependencyRequestsSelector = (state) => createSelector(
  appSelector,
  (app) => app.requests.filter((r) => r.state === state)
);

export const featureSelector = (featureId) => createSelector(
  appSelector,
  (app) => (
    app.features[featureId]
  )
);


export const moduleSelector = (moduleId) => (state) => (
  state.app.modules.find((module) => module.id === moduleId)
);

// export const subscriptionSelector = (moduleId) => createSelector(
//   moduleSelector(moduleId),
//   plansSelector(moduleId),
//   (module, plans) => ({trial: module.trial, plans: plans})
// );

export const plansSelector = (moduleId) => createSelector(
  moduleSelector(moduleId),
  (module) => module.plans
);

export const billingPlansSelector = (state) => {
  return state.billing?.plans;
};

// eslint-disable-next-line object-shorthand
export default {
  translationMissingSelector,
  usersSelector,
  groupsSelector,
  initedGroupsSelector,
  createdGroupsSelector,
  groupByIdSelector,
  groupSelector,
  groupAdminsSelector,
  usersByGroupSelector,
  usersNotInGroupSelector,
  userByIdSelector,
  groupsByUserSelector,
  groupAdminsUsersSelector,
  adminsSelector,
  groupMembersSelector,
  groupsByAdminSelector,
  languageSelector,
  dictionariesSelector,
  dictionarySelector,
  enDictionarySelector,
  dependencyRequestsSelector,
  featureSelector,
  invitationsSelector,
  billingPlansSelector,
  plansSelector,
  revokedInvitationsSelector,
  microsoftSettingsSelector,
  coreAdvancedSettingsSelector,
};
