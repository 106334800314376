import {PhoneNumberUtil} from 'google-libphonenumber';
import validator from 'validator';
import {t} from 'core/helpers/i18n';

const phoneUtil = PhoneNumberUtil.getInstance();

const validators = {
  receiverEmail: (data) => {
    const email = data.receiverEmail;
    const mobile = data.receiverMobile;

    if (!email && !mobile) {
      return {
        errors: [t('validation_email_mobilenumber_is_required')],
      };
    }

    if (!email) {
      return;
    }

    if (validator.isEmail(email) === false) {
      return {
        errors: [t('validation_invalid_email')],
      };
    }
  },
  receiverMobile: (data) => {
    const email = data.receiverEmail;
    const mobile = data.receiverMobile;

    if (!email && !mobile) {
      return {
        errors: [t('validation_mobilenumber_email_is_required')],
      };
    }

    if (!mobile) {
      return;
    }

    let mobileNumber;
    let mobileIsValid = validator.isMobilePhone(mobile);

    if (mobileIsValid) {
      try {
        mobileNumber = phoneUtil.parseAndKeepRawInput(mobile || '', 'NO');
        mobileIsValid = phoneUtil.isValidNumber(mobileNumber);
      } catch (err) {
        mobileIsValid = false;
      }
    }

    if (mobileIsValid === false) {
      return {
        errors: [t('validation_invalid_mobilenumber')],
      };
    }

    // Means country code was not determined from input value
    if (mobileNumber?.getCountryCodeSource() !== 1) {
      return {
        errors: [t('validation_mobilenumber_countrycode_is_required')],
      };
    }
  },
};

export const invitation = (data) => {
  return Object.entries(validators).reduce((acc, curr) => {
    const [key, val] = curr;
    const result = val(data);

    return result ? {...acc, [key]: result} : acc;
  }, {})
};

export default {
  invitation,
};
