import React, {useState, useEffect} from 'react';

import {Toggle} from '@signicat/designi/components/inputs';
import {Div, Flex} from '@signicat/designi/components/layout';
import {Segment} from '@signicat/designi/components/containers';
import {HelpText} from '@signicat/designi/components/text';
import {Grid, Row, Column} from '@signicat/designi/components/layout';
import {Label} from '@signicat/designi/components/forms';
import MicrosoftSettingsForm from './MicrosoftSettingsForm';
import {useSelector, useDispatch} from 'react-redux';
import {
  checkMicrosoftClientExists,
  getSettingsSet,
  updateSettings,
  getAvailableLoginIdps,
} from '../../../store/actions/app';
import {Message, useNotification} from '@signicat/designi/components/messages';
import {t} from 'core/helpers/i18n';
import {app as appSelectors} from 'core/store/selectors';
import {useConfirmation} from '@signicat/designi/components/overlays/ConfirmationService';
import {Button} from '@signicat/designi/components/buttons';
import {Feature} from 'core/components';

const Microsoft = () => {
  const [showView, setShowView] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();
  const {enqueueNotification} = useNotification();
  const confirm = useConfirmation();
  const [microsoftSettings, setMicrosoftSettings] = useState({});
  const microsoftOpenIdSettings = useSelector(appSelectors.microsoftSettingsSelector);
  const [settingsHasError, setSettingsHasError] = useState(false);
  const [configurationValidationError, setConfigurationValidationError] = useState([]);
  const [hover, setHover] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [microsoftSettingsEnabled, setMicrosoftSettingsEnabled] = useState(null);

  let useSignicatClientInitial = false;
  let accountOptionInitial = 'common';

  if (
    !microsoftOpenIdSettings?.clientId &&
    microsoftSettings?.issuerValidationMode &&
    (microsoftSettings?.tenantId === 'common' || microsoftSettings?.tenantId === 'organizations')
  ) {
    accountOptionInitial = microsoftSettings.tenantId;
    useSignicatClientInitial = true;
  }

  if (
    !microsoftOpenIdSettings?.clientId &&
    microsoftSettings.enabled &&
    microsoftSettings?.issuerValidationMode &&
    !microsoftSettings?.tenantId
  ) {
    useSignicatClientInitial = true;
  }

  const [useSignicatClient, setUseSignicatClient] = useState(useSignicatClientInitial);
  const [accountOption, setAccountOption] = useState(accountOptionInitial);

  useEffect(() => {
    setUseSignicatClient(useSignicatClientInitial);
  }, [useSignicatClientInitial]);

  useEffect(() => {
    setAccountOption(accountOptionInitial);
  }, [accountOptionInitial]);

  useEffect(() => {
    dispatch(getSettingsSet('oidc_microsoft'));
  }, []);

  useEffect(() => {
    if (microsoftSettingsEnabled == null) return;
    const settings = microsoftOpenIdSettings?.settingsSet;
    if (microsoftSettingsEnabled == false && settings?.enabled === 'true') {
      const settingsToUpdate = {
        clientId: null,
        clientSecret: null,
        discoveryEndpoint: getDiscoveryEndpoint(microsoftSettings?.customTenantId),
        enabled: 'false',
        issuerValidationMode: getIssuerValidationMode(microsoftSettings?.customTenantId),
      };
      dispatch(updateSettings('oidc_microsoft', settingsToUpdate));
    }
  }, [microsoftSettingsEnabled, microsoftOpenIdSettings?.settingsSet]);

  useEffect(() => {
    let tempMicrosoftSettings = {...microsoftOpenIdSettings?.settingsSet};
    if (tempMicrosoftSettings && tempMicrosoftSettings?.discoveryEndpoint) {
      const splitDiscoveryEndpoint = tempMicrosoftSettings.discoveryEndpoint.split('/');
      const tenantId = splitDiscoveryEndpoint[3];

      tempMicrosoftSettings = tempMicrosoftSettings?.clientId
        ? {...tempMicrosoftSettings, tenantId: tenantId, customTenantId: tenantId}
        : {...tempMicrosoftSettings, tenantId: tenantId};
    }
    setMicrosoftSettings({...tempMicrosoftSettings});
  }, [microsoftOpenIdSettings]);

  useEffect(() => {
    if (!microsoftSettingsEnabled) return;
    if (microsoftSettings?.enabled) {
      const tempEnabled = microsoftSettings?.enabled === 'true';
      setEnabled(tempEnabled);
    }

    setAccountOption(accountOptionInitial);
  }, [microsoftSettingsEnabled, microsoftSettings]);

  const handleSettingsVisibilityClick = () => {
    setShowView(!showView);
  };

  const getIssuerValidationMode = (tenantId) => {
    if (tenantId?.toLowerCase() === 'common' || tenantId?.toLowerCase() === 'organizations') {
      return 'baseUrl';
    } else {
      return 'exact';
    }
  };

  const getDiscoveryEndpoint = (tenantId) => {
    return `https://login.microsoftonline.com/${tenantId}/v2.0/.well-known/openid-configuration`;
  };

  const handleUseSignicatClientClick = () => {
    setUseSignicatClient(!useSignicatClient);
    setSettingsChanged(true);
  };

  const handleHoverChange = () => {
    setHover(!hover);
  };

  const handleAccountOptionChange = (e) => {
    setAccountOption(e.target.value);
    setSettingsChanged(true);
  };

  const enableMicrosoftSettingsChange = async () => {
    if (
      !useSignicatClient &&
      (!microsoftSettings?.clientId || !microsoftSettings?.clientSecret || !microsoftSettings?.customTenantId)
    ) {
      setSettingsHasError(true);
    } else {
      await confirm({
        title: !enabled
          ? t('microsoft_view_enable_confirm_title_enable')
          : t('microsoft_view_enable_confirm_title_disable'),
        message: !enabled ? t('microsoft_view_confirm_message_enable') : t('microsoft_view_confirm_message_disable'),
        variant: 'confirm',
        buttonConfirm: t('button_text_yes'),
        buttonCancel: t('button_text_no'),
      });

      if (enabled) {
        const availableIdps = await dispatch(getAvailableLoginIdps());

        if (availableIdps && availableIdps.length === 1) {
          enqueueNotification(t('microsoft_view_disable_only_method_error'), {
            variant: 'error',
          });

          return;
        }
      }

      if (useSignicatClient) {
        const settingsToUpdate = {
          issuerValidationMode: getIssuerValidationMode(accountOption),
          discoveryEndpoint: getDiscoveryEndpoint(accountOption),
          enabled: enabled ? 'false' : 'true',
          clientId: null,
          clientSecret: null,
        };

        dispatch(updateSettings('oidc_microsoft', settingsToUpdate));

        setEnabled(!enabled);

        enqueueNotification(
          enabled ? t('microsoft_view_disabled_notification') : t('microsoft_view_enabled_notification'),
          {
            variant: 'success',
          }
        );

        setConfigurationValidationError([]);
        setSettingsChanged(false);
      } else {
        if (!(await isValidConfiguration(microsoftSettings))) {
          enqueueNotification(t('microsoft_view_enable_invalid_configuration'), {
            variant: 'error',
          });
        } else {
          setSettingsHasError(false);

          const settingsToUpdate = {
            clientId: microsoftSettings?.clientId,
            clientSecret: microsoftSettings?.clientSecret,
            issuerValidationMode: getIssuerValidationMode(microsoftSettings?.customTenantId),
            discoveryEndpoint: getDiscoveryEndpoint(microsoftSettings?.customTenantId),
            enabled: enabled ? 'false' : 'true',
          };

          dispatch(updateSettings('oidc_microsoft', settingsToUpdate));

          setEnabled(!enabled);

          enqueueNotification(
            enabled ? t('microsoft_view_disabled_notification') : t('microsoft_view_enabled_notification'),
            {
              variant: 'success',
            }
          );

          setSettingsChanged(false);
        }
      }
    }
  };

  const isValidConfiguration = async (microsoftSettings) => {
    let invalidConfigurationItems = [];

    if (!isValidGuid(microsoftSettings.clientId)) {
      invalidConfigurationItems.push('clientId_invalid_guid');
    }

    if (!isValidGuid(microsoftSettings.customTenantId)) {
      invalidConfigurationItems.push('tenantId_invalid_guid');
    }

    if (
      !invalidConfigurationItems.includes('tenantId_invalid_guid') &&
      !(await dispatch(checkMicrosoftClientExists(getDiscoveryEndpoint(microsoftSettings.customTenantId))))
    ) {
      invalidConfigurationItems.push('tenantId_invalid_disovery_endpoint');
    }

    setConfigurationValidationError(invalidConfigurationItems);

    return invalidConfigurationItems.length === 0;
  };

  const isValidGuid = (input) => {
    let tempInput = '' + input;

    const isGuid = tempInput.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');

    return !!isGuid;
  };

  const getAccountOptions = () => {
    const commonOption = t('microsoft_view_default_any_account_option');
    const organizationsOption = t('microsoft_view_default_organizations_option');

    const accountOptions = [
      {
        value: 'common',
        text: commonOption,
      },
      {
        value: 'organizations',
        text: organizationsOption,
      },
    ];

    return accountOptions;
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const onFeatureToggle = (e) => {
    if (!e) {
      setEnabled(false);
    }
    setMicrosoftSettingsEnabled(e);
  };

  if (microsoftOpenIdSettings?.error) {
    return (
      <Message variant="error" icon="error" style={{marginTop: '24px'}}>
        <Flex flex="1 0 auto">{t('microsoft_view_error_loading_message')}</Flex>
        <Flex flex={'0 0 auto'}>
          <Button color="negative" size="sm" onClick={reloadPage}>
            {t('core_button_refresh_page')}
          </Button>
        </Flex>
      </Message>
    );
  }

  return (
    <Div>
      <Feature featureId="core_loginmethod_azure_ad" onFeatureToggle={onFeatureToggle}>
        <Segment divided outlined direction={'column'} marginBottom={'32px'}>
          <Flex style={{cursor: 'pointer', justifyContent: 'space-between', width: '100%'}}>
            <Grid
              item
              onClick={() => handleSettingsVisibilityClick()}
              onMouseEnter={handleHoverChange}
              onMouseLeave={handleHoverChange}
              style={{background: 'white', opacity: hover ? '0.7' : '1', paddingRight: '2rem'}}
            >
              <Grid container spacing={2} direction="row" alignItems="stretch">
                <Grid item style={{flex: '0 0 auto', alignItems: 'flex-start', height: 'auto', width: '45px'}}>
                  <img
                    src={'https://static.signicat.app/img/microsoft.svg'}
                    alt={t('microsoft_logo')}
                    style={{paddingLeft: '4px', paddingTop: '12px'}}
                  />
                </Grid>
                <Grid item style={{flex: '1 0 0'}}>
                  <Grid container direction="column" justifyContent="center">
                    <Label>{t('microsoft_view_label')}</Label>
                    <HelpText style={{marginTop: '2px'}}>{t('microsoft_view_help_text')}</HelpText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{alignItems: 'center'}}>
              <Toggle
                id="azure_toggle"
                disabled={!microsoftSettingsEnabled}
                // label={t('microsoft_view_toggle_label')}
                onChange={enableMicrosoftSettingsChange}
                checked={enabled}
              />
            </Grid>
          </Flex>
          {showView && (
            <MicrosoftSettingsForm
              microsoftOpenId={microsoftOpenIdSettings}
              microsoftSettings={microsoftSettings}
              setMicrosoftSettings={setMicrosoftSettings}
              getIssuerValidationMode={getIssuerValidationMode}
              getDiscoveryEndpoint={getDiscoveryEndpoint}
              settingsHasError={settingsHasError}
              setSettingsHasError={setSettingsHasError}
              configurationValidationError={configurationValidationError}
              setConfigurationValidationError={setConfigurationValidationError}
              isValidConfiguration={isValidConfiguration}
              useSignicatClient={useSignicatClient}
              setUseSignicatClient={setUseSignicatClient}
              accountOption={accountOption}
              setAccountOption={setAccountOption}
              handleUseSignicatClientClick={handleUseSignicatClientClick}
              getAccountOptions={getAccountOptions}
              handleAccountOptionChange={handleAccountOptionChange}
              setShowView={setShowView}
              settingsChanged={settingsChanged}
              setSettingsChanged={setSettingsChanged}
            />
          )}
        </Segment>
      </Feature>
    </Div>
  );
};

export default Microsoft;
