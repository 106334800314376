import ReactGA from 'react-ga';
import {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';

export default () => {
  const location = useLocation();
  const mounted = useRef();
  const [oldPath, setOldPath] = useState('');
  useEffect(() => {
    if (!mounted.current) {
      ReactGA.initialize('UA-25615732-11');
      mounted.current = true;
    } else if (location.pathname !== oldPath) {
      ReactGA.ga('send', 'pageview', location.pathname);
      setOldPath(location.pathname);
    }
  }, [location]);
  return null;
};
