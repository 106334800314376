export const PORTAL_GUI = [
  'en', // English
  'no', // Norwegian
  'sv', // Swedish
  'da', // Danish
  'fi', // Finnish
  'de', // German
  // 'fr', // French
  'nl', // Dutch
];

export default {
  PORTAL_GUI,
};
