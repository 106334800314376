import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';

import {Button} from '@signicat/designi/components/buttons';
import {Flex, Row, Grid} from '@signicat/designi/components/layout';
import {TextField, Select} from '@signicat/designi/components/inputs';
import {Segment} from '@signicat/designi/components/containers';
import {Form, Field} from '@signicat/designi/components/forms';

import {userByIdSelector} from 'core/store/selectors/app';
import {userValidator} from 'core/store/validators/app';
import {saveUser} from 'core/store/actions/app';
import {t} from 'core/helpers/i18n';

const UserEdit = (props) => {
  const {id} = props;
  const dispatch = useDispatch();
  const user = useSelector(userByIdSelector(id));
  const validation = userValidator(user.form);

  useState(() => {
    return () => dispatch({type: 'USER_EDIT_CANCEL', userId: id});
  }, []);

  const accessLevelOptions = [
    {value: 'User', text: t('access_level_user')},
    {value: 'Admin', text: t('access_level_admin')},
  ];

  const handleAccessLevelChange = (e) => {
    dispatch({
      type: 'USER_SET_ACCESS_LEVEL',
      userId: id,
      accessLevel: e.target.value,
    });
  };

  const handleSaveClick = (e) => {
    dispatch(saveUser(id));
  };

  const handleCancelClick = (e) => {
    dispatch({type: 'USER_EDIT_CANCEL', userId: id});
  };

  const handleUserProfileChange = (field) => (e) => {
    dispatch({
      type: 'USER_FORM_SET_FIELD',
      userId: id,
      field: field,
      value: e.target.value,
    });
  };

  return (
    <div style={{marginTop: '36px'}}>
      <Segment outlined space="xl">
        <Form validation={validation}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Field name="firstName">
                    <TextField
                      label={t('profile_field_firstname_label')}
                      value={user.form.profile.firstName}
                      onChange={handleUserProfileChange('profile.firstName')}
                    />
                  </Field>
                </Grid>
                <Grid item>
                  <Field name="lastName">
                    <TextField
                      label={t('profile_field_lastname_label')}
                      value={user.form.profile.lastName}
                      onChange={handleUserProfileChange('profile.lastName')}
                    />
                  </Field>
                </Grid>
                <Grid item>
                  <Field name="accessLevel">
                    <Select
                      label={t('access_level')}
                      options={accessLevelOptions}
                      value={user.form.resourceAccess.accessLevel}
                      onChange={handleAccessLevelChange}
                    />
                  </Field>
                </Grid>
                <Grid item>
                  <Flex flex="1 0 auto" justifyContent="flex-end">
                    <Button
                      size="sm"
                      color="secondary"
                      variant="transparent"
                      onClick={handleCancelClick}
                    >
                      {t('button_text_cancel')}
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={handleSaveClick}
                    >
                      {t('button_text_save')}
                    </Button>
                  </Flex>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Segment>
    </div>
  );
};

export default UserEdit;
