import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {Button} from '@signicat/designi/components/buttons';
import {Grid, Row, Column, Flex, Div} from '@signicat/designi/components/layout';
import {Select, TextField, Autocomplete} from '@signicat/designi/components/inputs';
import {Segment} from '@signicat/designi/components/containers';
import {Form, Field} from '@signicat/designi/components/forms';
import {Message} from '@signicat/designi/components/messages';
import {Heading, Paragraph} from '@signicat/designi/components/text';

import {account as accountActions} from 'core/store/actions';
import {invitation as invitationValidator} from 'core/store/validators/account';
import {createdGroupsSelector} from 'core/store/selectors/app';
import {appLanguageSelector} from 'core/store/selectors/app';
import {appSelector} from 'core/store/selectors/app';

import {t} from 'core/helpers/i18n';
import {ROUTES} from 'core/constants';

import {Titlebar} from './UserAccessManagement.js';
import Feature from '../../../components/Feature.js';

const HiddenFeature = styled(Feature)`
  display: none;
`;

const Invitation = () => {
  const dispatch = useDispatch();
  const appLanguage = useSelector(appLanguageSelector);
  const app = useSelector(appSelector);
  const accessLevelOptions = app.security.accessLevels.map((al) => ({
    value: al.accessLevel,
    text: t(`access_level_${al.accessLevel.toLowerCase()}`),
  }));

  const defaultState = {
    invitationLanguage: appLanguage,
    accessLevel: accessLevelOptions[0].value,
    receiverEmail: '',
    receiverMobile: '',
    groups: [],
    adminGroups: [],
  };

  const [state, setState] = useState(defaultState);
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sendError, setSendError] = useState(null);
  const [inviteToGroupsEnabled, setInviteToGroupsEnabled] = useState(true);
  const handleChange = (name) => (e) => {
    setState({
      ...state,
      [name]: e.target.value,
    });
  };

  const handleGroupsChange = (name) => (e, value) => {
    const newState = {
      ...state,
      [name]: value,
    };
    if (name === 'groups' && value.length < state.groups.length) {
      const removedGroupId = state.groups.find((x) => !value.includes(x));
      if (state.adminGroups.includes(removedGroupId))
        newState.adminGroups = state.adminGroups.filter((x) => x !== removedGroupId);
    }
    setState(newState);
  };

  const [validateAll, setValidateAll] = useState(false);
  const handleNewInvitationClick = () => {
    setValidateAll(false);
    setState(defaultState);
    setIsSent(false);
  };

  const validation = invitationValidator(state);

  const groups = useSelector(createdGroupsSelector);
  const groupsOptions = groups.map((g) => ({
    value: g.data.group,
    text: g.data.displayName || g.data.group,
  }));

  const adminGroupsOptions = groupsOptions.filter((group) => state.groups.includes(group.value));

  const handleSendInvitationClick = () => {
    setValidateAll(true);
    setSendError(null);

    if (Object.entries(validation).length === 0) {
      setIsSending(true);
      dispatch(accountActions.sendInvitation(state))
        .then(() => {
          setIsSent(true);
        })
        .catch((err) => {
          setSendError(
            err.message === 'APP_MAX_USERS_REACHED'
              ? t('uam_invite_error_maxusersreached')
              : t('uam_invite_error_unknown')
          );
        })
        .finally(() => {
          setIsSending(false);
        });
    }
  };

  return (
    <Div>
      <Titlebar>
        <Flex>
          <Button
            variant="transparent"
            color="secondary"
            icon="back"
            forwardedAs={Link}
            to={ROUTES.USER_ACCESS_MANAGEMENT}
          >
            {t('uam_link_overview')}
          </Button>
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Heading level={4} style={{paddingBottom: 0, marginBottom: 0}}>
            {t('uam_invite_heading')}
          </Heading>
        </Flex>
        <Flex></Flex>
      </Titlebar>
      <Segment outlined style={{marginBottom: '24px'}} padding="24px">
        {sendError ? <Message category="error">{sendError}</Message> : null}
        {isSent ? (
          <div>
            {t('uam_invite_send_success_heading')}
            <Button onClick={handleNewInvitationClick}>{t('uam_invite_action_send_another_button_text')}</Button>
          </div>
        ) : (
          <Form validation={validation} state={{validateAll: validateAll}}>
            <Column>
              <Row>
                <Segment direction="row" divided space="xl">
                  <Column>
                    <Grid container spacing={2}>
                      <Grid container item direction="column" xs={12}>
                        <Heading level={5}>{t('uam_invite_contactinfo_heading')}</Heading>
                        <Paragraph>{t('uam_invite_contactinfo_paragraph')}</Paragraph>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="receiverEmail">
                          <TextField
                            type="email"
                            label={t('form_label_email')}
                            value={state.receiverEmail}
                            onChange={handleChange('receiverEmail')}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field name="receiverMobile">
                          <TextField
                            type="tel"
                            label={t('form_label_mobile_number')}
                            value={state.receiverMobile}
                            onChange={handleChange('receiverMobile')}
                          />
                        </Field>
                      </Grid>
                    </Grid>
                  </Column>
                  <Column>
                    <Grid container spacing={2}>
                      <Grid container item direction="column" xs={12}>
                        <Heading level={5}>{t('uam_invite_access_heading')}</Heading>
                        <Paragraph>{t('uam_invite_access_paragraph')}</Paragraph>
                      </Grid>
                      <Grid item xs={12}>
                        <Field helpText={t('uam_invite_accesslevel_helptext')}>
                          <Select
                            label={t('form_label_app_access_level')}
                            options={accessLevelOptions}
                            value={state.accessLevel}
                            onChange={handleChange('accessLevel')}
                          />
                        </Field>
                      </Grid>

                      <HiddenFeature
                        featureId="core_userandgroupmanagement"
                        onFeatureToggle={(e) => setInviteToGroupsEnabled(e)}
                      />
                      {inviteToGroupsEnabled && (
                        <Fragment>
                          <Grid item xs={12}>
                            <Field helpText={t('uam_invite_groups_helptext')}>
                              <Autocomplete
                                label={t('form_label_user_groups')}
                                options={groupsOptions}
                                value={state.groups}
                                multiple
                                onChange={handleGroupsChange('groups')}
                              />
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field helpText={t('uam_invite_admingroups_helptext')}>
                              <Autocomplete
                                label={t('form_label_admin_groups')}
                                options={adminGroupsOptions}
                                value={state.adminGroups}
                                multiple
                                onChange={handleGroupsChange('adminGroups')}
                              />
                            </Field>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </Column>
                </Segment>
              </Row>
              <Row>
                <Grid item container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      size="sm"
                      color="primary"
                      icon="send"
                      onClick={handleSendInvitationClick}
                      disabled={isSending}
                      style={{cursor: isSending ? 'wait' : 'pointer'}}
                    >
                      {t('button_action_send_invitation')}
                    </Button>
                  </Grid>
                </Grid>
              </Row>
            </Column>
          </Form>
        )}
      </Segment>
    </Div>
  );
};

export default Invitation;
