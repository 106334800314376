import React, {Fragment, useState} from 'react';
import {Flex, Grid} from '@signicat/designi/components/layout';
import {Checkbox, Select, TextField} from '@signicat/designi/components/inputs';
import {Button} from '@signicat/designi/components/buttons';
import {Heading, Paragraph} from '@signicat/designi/components/text';
import {t} from 'core/helpers/i18n';
import {styled} from '@material-ui/styles';
import {Message, useNotification} from '@signicat/designi/components/messages';
import {getSettingsSet, updateSettings} from '../../../store/actions/app';
import {useDispatch} from 'react-redux';

const OpenIdHeading = styled(Heading)`
  font-weight: 500;
  text-transform: uppercase;
  flex-direction: horizontal;
  text-align: center;
  letter-spacing: 0.04em;
  color: #565656;
  margin-bottom: 25px;
`;

const MicrosoftSettingsForm = (props) => {
  const dispatch = useDispatch();
  const {enqueueNotification} = useNotification();

  const handleMicrosoftSettingsChange = (e) => {
    props.setMicrosoftSettings({...props.microsoftSettings, [e.target.name]: e.target.value});

    props.setSettingsChanged(true);
  };

  const handleSaveConfigurationClick = async () => {
    try {
      if (!props.useSignicatClient) {
        if (
          !props.microsoftSettings?.clientId ||
          !props.microsoftSettings?.clientSecret ||
          !props.microsoftSettings?.customTenantId
        ) {
          props.setSettingsHasError(true);
        } else {
          if (!(await props.isValidConfiguration(props.microsoftSettings))) {
            props.setSettingsHasError(true);

            return;
          }

          props.setSettingsHasError(false);

          const settingsToUpdate = {
            clientId: props.microsoftSettings.clientId,
            clientSecret: props.microsoftSettings.clientSecret,
            issuerValidationMode: props.getIssuerValidationMode(props.microsoftSettings.customTenantId),
            discoveryEndpoint: props.getDiscoveryEndpoint(props.microsoftSettings.customTenantId),
            enabled: getEnabled(props.microsoftSettings?.enabled),
          };

          dispatch(updateSettings('oidc_microsoft', settingsToUpdate));

          enqueueNotification(t('microsoft_config_save_success_notification'), {
            variant: 'success',
          });

          props.setSettingsChanged(false);
        }
      } else {
        const settingsToUpdate = {
          issuerValidationMode: props.getIssuerValidationMode(props.accountOption),
          discoveryEndpoint: props.getDiscoveryEndpoint(props.accountOption),
          enabled: getEnabled(props.microsoftSettings?.enabled),
          clientId: null,
          clientSecret: null,
        };

        dispatch(updateSettings('oidc_microsoft', settingsToUpdate));

        props.setConfigurationValidationError([]);

        enqueueNotification(t('microsoft_config_save_success_notification'), {
          variant: 'success',
        });

        props.setSettingsChanged(false);
      }
    } catch (err) {
      console.log(err);

      enqueueNotification(t('microsoft_config_save_failure_notification'), {
        variant: 'error',
      });
    }
  };

  const handleCancelClick = async () => {
    dispatch(getSettingsSet('oidc_microsoft'));

    props.setUseSignicatClient(false);
    props.setMicrosoftSettings({});
    props.setConfigurationValidationError([]);
    props.setSettingsHasError(false);
    props.setShowView(false);
    props.setSettingsChanged(false);
  };

  const getEnabled = (enabled) => {
    if (enabled && enabled === 'true') return 'true';

    return 'false';
  };

  return (
    <div style={{marginTop: '20px'}}>
      <OpenIdHeading>{t('microsoft_view_main_title')}</OpenIdHeading>

      <Paragraph style={{fontWeight: 300, color: '#565656', marginBottom: '25px'}}>
        {t('microsoft_view_main_text')}
      </Paragraph>

      <Grid container spacing={2} alignItems="flex-start" direction="row">
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Checkbox
              id="signicat_azure_client_checkbox"
              label={t('microsoft_view_use_signicat_client_label')}
              name="useCustomClient"
              color={'primary'}
              checked={props.useSignicatClient}
              fullWidth
              onChange={props.handleUseSignicatClientClick}
            />
          </Grid>
        </Grid>
        {!props.useSignicatClient ? (
          <Fragment>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={false} sm={6} md={12}></Grid>
              <Grid item xs={false} sm={3} md={8}>
                <Paragraph style={{fontWeight: 300, color: '#565656', marginBottom: '25px'}}>
                  {t('microsoft_view_custom_client_link_text')}{' '}
                  <a
                    href={
                      'https://developer.signicat.com/express/docs/sign-portal/advanced-config.html#microsoft-as-an-identity-provider'
                    }
                    rel={'noreferrer noopener'}
                    target="_blank"
                    hrefLang={'en'}
                    lang={'en'}
                  >
                    {t('microsoft_view_custom_client_link')}
                  </a>
                </Paragraph>
              </Grid>
              <Grid item xs={false} sm={6} md={12}>
                {/* Empty container to move next field to next line */}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label={t('microsoft_view_custom_clientid_label')}
                  name="clientId"
                  fullWidth
                  onChange={handleMicrosoftSettingsChange}
                  required={true}
                  defaultValue={props.microsoftSettings.clientId}
                  error={props.settingsHasError && !props.microsoftSettings?.clientId ? true : false}
                  helperText={t('microsoft_view_custom_clientid_help_text')}
                />
              </Grid>
              <Grid item xs={false} sm={6} md={12}>
                {/* Empty container to move next field to next line */}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label={t('microsoft_view_custom_clientsecret_label')}
                  name="clientSecret"
                  type={'password'}
                  fullWidth
                  onChange={handleMicrosoftSettingsChange}
                  required={true}
                  defaultValue={props.microsoftSettings.clientSecret}
                  error={props.settingsHasError && !props.microsoftSettings?.clientSecret ? true : false}
                  helperText={t('microsoft_view_custom_clientsecret_help_text')}
                />
              </Grid>
              <Grid item xs={false} sm={12}>
                {/* Empty container to move next field to next line */}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label={t('microsoft_view_custom_tenantid_label')}
                  name="customTenantId"
                  fullWidth
                  onChange={handleMicrosoftSettingsChange}
                  required={true}
                  defaultValue={props.microsoftSettings.customTenantId}
                  error={props.settingsHasError && !props.microsoftSettings?.customTenantId ? true : false}
                  helperText={t('microsoft_view_custom_tenantid_help_text')}
                />
              </Grid>
            </Grid>
            <Grid item xs={false} sm={6} md={9}>
              {/* Empty container to move next field to next line */}
            </Grid>
          </Fragment>
        ) : (
          <Grid item xs={12} sm={6} md={5}>
            <Select
              options={props.getAccountOptions()}
              name="accountOptions"
              label={t('microsoft_view_default_allowed_accounts')}
              fullWidth
              onChange={props.handleAccountOptionChange}
              value={props.accountOption}
            />
          </Grid>
        )}
      </Grid>
      {props.configurationValidationError.length > 0
        ? props.configurationValidationError.map((i, key) => {
            return (
              <Message key={key} variant="error" icon="error" style={{marginTop: '24px'}}>
                <Flex flex="1 0 auto">{t(i)}</Flex>
              </Message>
            );
          })
        : null}
      <Grid container spacing={2} alignItems="flex-start" direction="row" style={{marginTop: '25px'}}>
        <Grid item xs={3} sm={2} md={2}>
          <Button id="azure_cancel_button" color="secondary" size="sm" variant={'outlined'} onClick={handleCancelClick}>
            {t('button_text_cancel')}
          </Button>
          <Button
            id="azure_save_button"
            size="sm"
            color="primary"
            onClick={handleSaveConfigurationClick}
            disabled={!props.settingsChanged}
          >
            {t('button_text_save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MicrosoftSettingsForm;
