import React from 'react';
import styled from 'styled-components';

import {Footer} from '@signicat/designi/components/layout';
import {Signicat} from '@signicat/designi/assets/logos';

const StyledSignicat = styled(Signicat)`
  width: 128px;

  path {
    fill: #fff;
  }
`;

export default () => (
  <footer></footer>
);
